import React, { useEffect, useState } from 'react';
import DescriptionTitle from '../../Shared/DescriptionTitle';
import { Grid } from '@mui/material';
import useQuery from '../../Shared/UseQuery';
import { dataService } from '../../../Services/data/data.service';
import MeasurementBlocks from './MeasurementBlocks';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Squares from './squares.svg';

export const PerformMeasurementPage = (props) => {

    let query = useQuery();
    let qr = query.get("qr");
    const [measurementBlocks, setMeasurementBlocks] = useState([]);
    const [projectName, setProjectName] = useState([]);
    const [resourceName, setResourceName] = useState([]);
    const [projectId, setProjectId] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [viewSize, setViewSize] = useState(6)
    const [displaySize, setDisplaySize] = useState(3);
    const [activeProject, setActiveProject] = useState(true);
    const [autosize, setAutosize] = useState(true);
    const [toggleRefresh, setToggleRefresh] = useState(false);
    let fromDate;
    let toDate;

    useEffect(() => {
        dataService.getProjectDetailWithComments(qr).then(res => {
            fromDate = res.timeRangeBegin;
            toDate = res.timeRangeEnd;
            let currentDate = new Date();
            let active = dateRange(new Date(res.timeRangeBegin), new Date(res.timeRangeEnd), currentDate, currentDate);
            if (active) {
                setActiveProject(true);
            }
            else {
                setActiveProject(false);
            }
            console.log('res ', res)
            setProjectId(res.id);
            setProjectName(res.projectName);
            setResourceName(res.resourceDescription);

            let tempDetails = [...res.details];
            tempDetails.map(detail => {
                detail.running = true;
                if (detail.measurements.length > 0 && detail.measurements[0] !== null && !refresh && detail.measurements[detail.measurements.length - 1].running == true) {
                    if (detail.controlTypeID === 1) {
                        //Counter
                        detail.value = detail.measurements[detail.measurements.length - 1].integerValue;
                        console.log(detail)
                        let comments = detail.measurements.filter(measurement => measurement.running).map((measurement, index) => ({comment:measurement.commentText? measurement.commentText: "", image:measurement.commentImage?"data:image/png;base64," + measurement.commentImage: "", index:index + 1, insertedOn: measurement.commentInsertedOn}));
                        detail.comments = comments;
                    } else if (detail.controlTypeID === 2) {
                        //Timer         
                        console.log(detail.measurements[detail.measurements.length - 1])                  
                        if (detail.measurements[detail.measurements.length - 1].stopValue === null) {
                            detail.value = "0";
                            detail.stopValue = new Date(Date.now());
                            detail.startValue = new Date(Date.now());
                        }else{  
                            detail.value = detail.measurements[detail.measurements.length - 1].integerValue;
                            detail.stopValue = new Date(detail.measurements[detail.measurements.length - 1].stopValue);
                            detail.startValue = new Date(detail.measurements[detail.measurements.length - 1].startValue);
                        }
                    } else if (detail.controlTypeID === 3) {
                        //Free text
                        detail.value = detail.measurements[detail.measurements.length - 1].textValue;
                    }
                    else if (detail.controlTypeID === 5) {
                        // Scanner
                        detail.value = detail.measurements[detail.measurements.length - 1].textValue;
                        let comments = detail.measurements.filter(measurement => measurement.running).map((measurement, index) => ({comment:measurement.commentText? measurement.commentText: "", image:measurement.commentImage?"data:image/png;base64," + measurement.commentImage: "", index:index + 1, insertedOn: measurement.commentInsertedOn}));
                        detail.comments = comments;
                    }
                    else if (detail.controlTypeID === 6) {
                        // Camera
                        detail.value = detail.measurements[detail.measurements.length - 1].textValue;
                        let comments = detail.measurements.filter(measurement => measurement.running).map((measurement, index) => ({comment:measurement.commentText? measurement.commentText: "", image:measurement.commentImage?"data:image/png;base64," + measurement.commentImage: "", index:index + 1, insertedOn: measurement.commentInsertedOn}));
                        detail.comments = comments;
                    }
                }
                else{                    
                    if (detail.controlTypeID === 1) {
                        //Counter
                        detail.value = "0";
                        let comments = [];
                        detail.comments = comments;
                    } else if (detail.controlTypeID === 2) {
                        //Timer                             
                        detail.value = "0";
                        detail.stopValue = new Date(Date.now());
                        detail.startValue = new Date(Date.now());
                    } else if (detail.controlTypeID === 3) {
                        //Free text
                        detail.value = "";
                    }
                    else if (detail.controlTypeID === 5) {
                        // Scanner
                        detail.value = "";
                        let comments = [];
                        detail.comments = comments;
                    }
                    else if (detail.controlTypeID === 6) {
                        // Camera
                        detail.value = "";
                        let comments = [];
                        detail.comments = comments;
                    }
                }
            });
            setMeasurementBlocks(tempDetails);
        });

        function dateRange(a_start, a_end, b_start, b_end) {
            if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
            if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
            if (b_start < a_start && a_end < b_end) return true; // a in b
            if (datesAreOnSameDay(a_start, b_start)) return true;
            if (datesAreOnSameDay(b_end, a_end)) return true;
            return false;
        }
        const datesAreOnSameDay = (first, second) =>
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate();

        const display1Min = window.matchMedia('(min-width: 720px)');
        const display2Min = window.matchMedia('(min-width: 1000px)');
        const display3Min = window.matchMedia('(min-width: 1300px)');

        const listener = () => {
            if (!display1Min.matches) {
                setDisplaySize(0)
                setAutosize(true)
            }
            if (display1Min.matches && !display2Min.matches) {
                setDisplaySize(1)
                setAutosize(true)
            }
            if (display2Min.matches && !display3Min.matches) {
                setDisplaySize(2)
                setAutosize(true)
            }
            if (display3Min.matches) {
                setDisplaySize(3)
                setAutosize(true)
            }
            if (autosize) {
                if (!display1Min.matches) { setViewSize(12) }
                if (display1Min.matches) { setViewSize(6) }
                if (display2Min.matches) { setViewSize(4) }
                if (display3Min.matches) { setViewSize(3) }
            }
        }

        listener();
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [qr, toggleRefresh]);

    const viewSizeFunction = (blockWidth) => {
        setViewSize(blockWidth)
    }

    const TitleStyle = {
        background: "#FFFFFF",
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
    }
    const Style = {
        textAlign: "center",
        background: "#FFFFFF",
        borderRadius: "8px",
        margin: "1.5rem",
        padding: "1.5rem"
    }

    const TitleTextStyle = {
        fontSize: "1.5rem",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: "#1D3557",
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: "1.5rem",
    }

    const refreshMeasurements = () => {
        setRefresh(true);
        setToggleRefresh(!toggleRefresh);
    }

    return (
        <div style={{ position: "absolute", top: props.loggedInState ? "0" : "0", width: "100%" }}>
            <div style={TitleStyle}>
                <div style={TitleTextStyle} >
                    <h1>{resourceName}</h1>
                </div>
                {displaySize === 1 ?
                    <div style={{ float: 'right', width: '50%', marginRight: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                            <div style={{ fontSize: "10px", width: '100%', textAlign: 'center' }}>
                                Choose your preferred block width:
                            </div>
                            <div style={{}}>
                                <IconButton onClick={() => { viewSizeFunction(12); setAutosize(false); }} style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }}>
                                    <TableRowsIcon color='#8D8D8D' />
                                </IconButton>
                                <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(6); setAutosize(false); }}>
                                    <img src={Squares} style={{ padding: '2px' }} alt="Square Icon" />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    :
                    displaySize === 2 ?
                        <div style={{ float: 'right', width: '200px', marginRight: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                                <div style={{ fontSize: "10px", width: '100%', textAlign: 'center' }}>
                                    Choose your preferred block width:
                                </div>
                                <div style={{}}>
                                    <IconButton onClick={() => { viewSizeFunction(12); setAutosize(false); }} style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }}>
                                        <TableRowsIcon color='#8D8D8D' />
                                    </IconButton>
                                    <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(6); setAutosize(false); }}>
                                        <img src={Squares} style={{ padding: '2px' }} alt="Square Icon" />
                                    </IconButton>
                                    <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(4); setAutosize(false); }}>
                                        <SvgIcon>
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                <path fill="currentcolor" d="M16 5v6h5V5m-11 6h5V5h-5m6 13h5v-6h-5m-6 6h5v-6h-5m-6 6h5v-6H4m0-1h5V5H4v6Z" />
                                            </svg>
                                        </SvgIcon>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        :
                        displaySize === 3 ?
                            <div style={{ float: 'right', width: '200px', marginRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                                    <div style={{ fontSize: "10px", width: '100%', textAlign: 'center' }}>
                                        Choose your preferred block width:
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <IconButton onClick={() => { viewSizeFunction(12); setAutosize(false); }} style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }}>
                                            <TableRowsIcon color='#8D8D8D' />
                                        </IconButton>
                                        <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(6); setAutosize(false); }}>
                                            <img src={Squares} style={{ padding: '2px' }} alt="Square Icon" />
                                        </IconButton>
                                        <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(4); setAutosize(false); }}>
                                            <SvgIcon>
                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                    <path fill="currentcolor" d="M16 5v6h5V5m-11 6h5V5h-5m6 13h5v-6h-5m-6 6h5v-6h-5m-6 6h5v-6H4m0-1h5V5H4v6Z" />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                        <IconButton style={{ borderRadius: 0, border: '1px solid rgb(224, 224, 224)', marginRight: '5px' }} onClick={() => { viewSizeFunction(3); setAutosize(false); }}>
                                            <SvgIcon>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24s">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 9h4V5H3v4zm0 5h4v-4H3v4zm5 0h4v-4H8v4zm5 0h4v-4h-4v4zM8 9h4V5H8v4zm5-4v4h4V5h-4zm5 9h4v-4h-4v4zM3 19h4v-4H3v4zm5 0h4v-4H8v4zm5 0h4v-4h-4v4zm5 0h4v-4h-4v4zm0-14v4h4V5h-4z" />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                }
            </div>
            {
                activeProject === true ?
                    <div style={Style}>
                        <Grid container>
                            <Grid item xs={12}>
                                {measurementBlocks.length >= 1 && <MeasurementBlocks {...props} projectId={projectId} data={measurementBlocks} refreshMeasurements={refreshMeasurements} view={viewSize} />}
                            </Grid>
                        </Grid>
                    </div>
                    :
                    <div style={Style}>
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{ color: "red" }}>{projectName + " is not active anymore."} </h1>
                                <DescriptionTitle Text="Contact the project owner to change the date when this project takes places." />
                            </Grid>
                        </Grid>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    settings: state.settings
})

export default connect(
    mapStateToProps
)(PerformMeasurementPage);





