import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { parseNewDateToDDMMYYYnoTime } from "../../../../Utils/dateHelper";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NoResults from '../../../../../src/Images/NoResults.png'




export default function PriceTable(props) {
  return (
    <div component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell align="right">First occurence</TableCell>
            <TableCell align="right">Last occurence</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Price per hour/event</TableCell>
            <TableCell align="right">Total price</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {props.priceData.length === 0 ?
            <tr>
              <td colspan="9" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <span style={{ fontSize: '16px', fontWeight: '500', color: '#1D3557', textAlign: 'center' }}>No results available for <br />these filter criteria</span>

                  <img style={{ marginTop: '15px', height: '100px', width: '100px'}} src={NoResults} alt="" />
                </div>

              </td>
            </tr>
            : <React.Fragment></React.Fragment>}
          {props.priceData.map((measurement) => (
            <TableRow key={measurement.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">{measurement.detailDescription}</TableCell>
              <TableCell align="right">{parseNewDateToDDMMYYYnoTime(new Date(measurement.first))}</TableCell>
              <TableCell align="right">{parseNewDateToDDMMYYYnoTime(new Date(measurement.last))}</TableCell>
              <TableCell align="right">{measurement.type !== 2 ? measurement.amount : measurement.minuteamount}</TableCell>
              <TableCell align="right">{"€ " + (measurement.price).toLocaleString('nl-BE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
              <TableCell style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} align="right">
                {"€ " + measurement.totalPrice.toLocaleString('nl-BE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {measurement.isProfit ? <AddIcon style={{ color: 'rgb(40, 167, 69)' }}></AddIcon> : <RemoveIcon style={{ color: 'rgb(220, 53, 69)' }}></RemoveIcon>}
              </TableCell>

            </TableRow>
          ))}

          <TableRow>
            <TableCell colSpan={5} style={{ fontWeight: "bold" }}><span style={{ float: 'right' }}>Total</span></TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">{"€ " + props.totalPrice.toLocaleString('nl-BE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
