import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
import TitlePage from '../../Shared/TitlePage';
import { dataService } from '../../../Services/data/data.service';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import TableHighLow from './Components/TableHighLow';
import EventChart from './Components/EventChart';
import FinancialChart from './Components/FinancialChart.jsx';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PriceTable from './Components/PriceTable';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TimeRangeChart from '../Results/Components/TimeRangeChart'
import TimeRangeChartCopy from '../Results/Components/TimeRangeChart copy'

import { controlTypes } from '../../../Config/constants';
import { parseNewDateToDDMMYYY } from '../../../Utils/dateHelper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

import Counter from '../../../../src/Images/counterStyle.svg';
import Stopwatch from '../../../../src/Images/timerStyle.svg';
import FreeText from '../../../../src/Images/Freetext.svg';
import DataList from '../../../../src/Images/DataList.svg';
import Scanner from '../../../../src/Images/barcodeScanner.svg';
import Image from '../../../../src/Images/camera.svg';
import API from '../../../../src/Images/api.svg';
import NoResults from '../../../../src/Images/NoResults.png'


import { Spin } from "react-cssfx-loading";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import chartImg from '../../../../src/Images/chart.svg'
//import pieChart from '../../../../src/Images/PieChart.png'

import pieChart from '../../../../src/Images/Pie.svg'

import ErrorIcon from '@mui/icons-material/Error';
import Pdf from "react-to-pdf";

import ReactDOM from "react-dom";
import html2canvas from 'html2canvas';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    "&:hover": { backgroundColor: "#C3996B" },
    padding: 0,
    color: 'white',
    transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function ProjectReportPage(props) {
    const classes = useStyles();
    const GetUserID = props.Login.userid;

    const [project, setProject] = useState(null);
    const [data, setData] = useState([]);
    const [timeData, setTimeData] = useState([]);
    const [timeLineData, setChartData] = useState({});
    const [timeRangeData, setTimeRangeData] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);
    const [measurementPrices, setMeasurementPrices] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [allPriceData, setAllPriceData] = useState([]);

    const [totalPrice, setTotalPrice] = useState(0);
    const [financialData, setFinancialData] = useState([]);
    const [financialChartData, setFinancialChartData] = useState([]);
    const [projectDetailNames, setDetailsNames] = useState({});
    const [totalFinancial, setTotalFinancialData] = useState({});
    const [overviewData, setOverviewData] = useState({});
    const [measurementRows, setMeasurementRows] = useState([]);
    const [allData, setAllData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [timeRangeDates, setTimeRangeDates] = useState({ fromDate: new Date(Date.now() - 86400000), toDate: new Date(Date.now()) });
    const history = useHistory();
    const ref = React.createRef();
    const HighLowRef = React.createRef();



    useEffect(() => {
        //Get project data by ID
        dataService.getProjectByProjectIdUserId(props.projectCopy.projectId, props.Login.userid).then(res => {
            //This is the project data that was found for the given id
            setProject(res[0]);
            handleProject(res[0]);

        });
        let TempMeasurementRows;


    }, [])

    function convertHMS(value) {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }

    const handleProject = (project) => {
        let thisProject = project;
        let tempFinancialData = [];
        let tempTotalFinancialData = [];
        let tempMeasurementsCollection = [];
        let chartMeasurementCollection = [];

        //Loop through all details and just toss all measurements together, afterwards flatten and sort by date
        for (let i = 0; i < thisProject.details.length; i++) {
            tempMeasurementsCollection.push(thisProject.details[i].measurements);
        }

        tempMeasurementsCollection = tempMeasurementsCollection.flat();
        tempMeasurementsCollection = tempMeasurementsCollection.sort((a, b) => new Date(a.insertedOn) - new Date(b.insertedOn));


        setMeasurementRows(tempMeasurementsCollection);
        //Get last measurement so we can decide the last date for the rangeslider
        let fromAndToDate = constructFromAndToDate(tempMeasurementsCollection);
        let tempToDate = fromAndToDate[1];
        let tempFromDate = fromAndToDate[0];

        chartMeasurementCollection = tempMeasurementsCollection.filter(x => new Date(x.insertedOn) >= new Date(tempFromDate) && new Date(x.insertedOn) <= new Date(tempToDate));
        constructPriceData(thisProject, tempFromDate, tempToDate);
        setTimeRangeDates({ fromDate: tempFromDate, toDate: tempToDate });

        let projectDetails = thisProject.details;
        //Creates an object with all detail and their color (needed for the chart)
        let detailNames = constructDetailCollection(projectDetails);

        //Construct the data that will be displayed in the financial chart
        let tempChartData = constructChartData(projectDetails, chartMeasurementCollection);


        let orderTest = tempFinancialData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setAllData(orderTest);
        setDetailsNames(detailNames);
        setTotalFinancialData(tempTotalFinancialData);
        setFinancialData(tempChartData);
        setFinancialChartData(tempChartData);
        setLoading(false);
    };

    const constructDetailCollection = (details) => {
        let temporaryCollection = [];
        for (let i = 0; i < details.length; i++) {
            let thisProjectDetail = details[i];
            temporaryCollection.push({ name: thisProjectDetail.description, color: thisProjectDetail.color });
        }
        //Empty array for all details, also add a total to display in the graph
        temporaryCollection.push({ name: 'Total', color: '#c7c9c8' });

        return temporaryCollection;
    }

    const constructChartData = (projectDetails, chartDataCollection) => {
        let tempTotalData = [];
        let total = 0;
        let startTime = chartDataCollection.length > 0 ? chartDataCollection[0].insertedOn : new Date();
        let count = 0;

        //First we loop through all measurements to calculate the TOTAL LINE
        chartDataCollection.map(thisMeasurement => {
            let isStopwatch = false;
            let thisDetail = projectDetails.find(x => x.id === thisMeasurement.projectDetailID);
            if (thisDetail !== undefined) {


                if (thisDetail.controlTypeID === 2) {
                    isStopwatch = true;
                }
                let price = 0;
                let price_per = thisDetail.price !== null ? thisDetail.price : 0;
                let tempMeasurementData = {};
                var dif;
                var priceToAdd;
                if (isStopwatch) {
                    let startDate = new Date(thisMeasurement.startValue);
                    let stopDate = new Date(thisMeasurement.stopValue);
                    if (stopDate > startDate) {
                        dif = (stopDate.getTime() - startDate.getTime()) / 1000;

                    } else {
                        dif = 0;
                    }
                    priceToAdd = ((thisDetail.price / 3600) * dif);

                } else {
                    priceToAdd = price_per;
                }
                if (thisDetail.isProfit) {
                    price = priceToAdd;
                    total += priceToAdd;
                } else {
                    price = priceToAdd;
                    total -= priceToAdd;
                }
                if (count === 0) {
                    tempMeasurementData = { id: thisMeasurement.id, measurement: 'Total', date: startTime, price: 0, profitAdded: 0, isVisible: true, 'Total_price': 0, color: thisDetail.color, isProfit: thisDetail.isProfit, originalMeasurement: thisDetail.description };
                    tempTotalData.push(tempMeasurementData);

                }
                tempMeasurementData = { id: thisMeasurement.id, measurement: 'Total', date: thisMeasurement.insertedOn, price: priceToAdd, profitAdded: priceToAdd, isVisible: true, 'Total_price': total, color: thisDetail.color, isProfit: thisDetail.isProfit, originalMeasurement: thisDetail.description };
                tempTotalData.push(tempMeasurementData);
                count++;
            }
        });

        let tempDetailData = [];

        //Loop through the details and their measurements to get each unique detail line
        projectDetails.map(thisDetail => {
            let isStopwatch = false;
            let detailCount = 0;

            if (thisDetail.controlTypeID === 2) {
                //Stopwatch
                isStopwatch = true;
            }
            let thisMeasurements = chartDataCollection.filter(x => x.projectDetailID === thisDetail.id);
            let price = 0;
            let total = 0;
            let price_per = thisDetail.price !== null ? thisDetail.price : 0;
            let startTime = chartDataCollection.length > 0 ? chartDataCollection[0].insertedOn : new Date();

            if (thisMeasurements.length > 0) {
                let variablename = thisDetail.description + '_price';
                thisMeasurements.map(thisMeasurement => {
                    let tempMeasurementData = {};
                    var dif;
                    var priceToAdd;
                    if (isStopwatch) {
                        let startDate = new Date(thisMeasurement.startValue);
                        let stopDate = new Date(thisMeasurement.stopValue);
                        if (stopDate > startDate) {
                            dif = (stopDate.getTime() - startDate.getTime()) / 1000;
                        } else {
                            dif = 0;
                        }
                        priceToAdd = ((thisDetail.price / 3600) * dif);
                    } else {
                        priceToAdd = price_per;
                    }

                    if (thisDetail.isProfit) {
                        price = priceToAdd;
                        total += priceToAdd;
                    } else {
                        price = priceToAdd;
                        total -= priceToAdd;
                    }
                    if (detailCount === 0) {
                        tempMeasurementData = { detailCount: 0, id: thisMeasurement.id, measurement: thisDetail.description, date: startTime, price: 0, profitAdded: 0, isVisible: true, [variablename]: 0, color: thisDetail.color, isProfit: thisDetail.isProfit };
                        tempDetailData.push(tempMeasurementData);

                    }
                    tempMeasurementData = { id: thisMeasurement.id, measurement: thisDetail.description, date: thisMeasurement.insertedOn, price: priceToAdd, profitAdded: priceToAdd, isVisible: true, [variablename]: total, color: thisDetail.color, isProfit: thisDetail.isProfit };
                    tempDetailData.push(tempMeasurementData);
                    detailCount++;
                });
            }


        });
        let combinedData = tempTotalData.concat(tempDetailData);
        return combinedData;
    }

    const constructPriceData = (thisProject, thisFromDate, thisToDate) => {
        let amountOfMeasurements = [];
        let detailDescriptions = [];
        let measurementColors = [];
        let tempMeasurementPrices = [];
        let tempPriceData = [];
        let tempFinancialData = [];
        let tempTimeData = [];
        let tempOverviewData = [];
        let total = 0;
        //Loop through details
        let projectDetails = thisProject.details;

        for (let i = 0; i < projectDetails.length; i++) {
            //If measurements have stopValue they are a stopwatch
            let thisDetail = projectDetails[i];
            let filteredMeasurements = thisDetail.measurements.filter(x => new Date(x.insertedOn) >= new Date(thisFromDate) && new Date(x.insertedOn) <= new Date(thisToDate));
            if (filteredMeasurements.length > 0) {
                //events
                //Check if stopvalue than type 2 is stopwatch
                let detailType = filteredMeasurements[0].stopValue !== null ? 2 : 1;
                amountOfMeasurements.push(filteredMeasurements.length);
                detailDescriptions.push(projectDetails.description);
                measurementColors.push(projectDetails.color);
                tempMeasurementPrices.push({
                    measurementId: filteredMeasurements[0].id !== 0 ? filteredMeasurements[0].id : filteredMeasurements[0].externalApiId,
                    price: 0
                });
                setMeasurementPrices(tempMeasurementPrices);
                let lowestDate = new Date(filteredMeasurements[0].insertedOn);
                let totalMeasurementPrice = 0;
                let totalTime = 0;
                let tempTotal = 0;

                if (detailType === 2) {
                    for (let j = 0; j < filteredMeasurements.length; j++) {
                        let startDate = new Date(filteredMeasurements[j].startValue);
                        let stopDate = new Date(filteredMeasurements[j].stopValue);
                        var dif;
                        if (stopDate > startDate) {
                            dif = (stopDate.getTime() - startDate.getTime()) / 1000;

                        } else {
                            dif = 0;
                        }
                        totalTime += dif;
                        let tempTotalPrice = (thisDetail.price / 3600) * dif;
                        let tempMeasurementData = { date: filteredMeasurements[j].startValue, price: tempTotalPrice };
                        tempFinancialData.push(tempMeasurementData);

                    }
                    totalMeasurementPrice = (thisDetail.price / 3600) * totalTime;
                    tempTotal = totalMeasurementPrice;

                } else {

                    tempTotal = (filteredMeasurements.length * (thisDetail.price !== null ? thisDetail.price : 0));
                }
                for (let j = 0; j < filteredMeasurements.length; j++) {
                    if (new Date(filteredMeasurements[j].insertedOn) < lowestDate) {
                        lowestDate = new Date(filteredMeasurements[j].insertedOn);
                    }
                }
                let highestDate = new Date(filteredMeasurements[0].insertedOn);
                for (let j = 0; j < filteredMeasurements.length; j++) {

                    if (new Date(filteredMeasurements[j].insertedOn) > highestDate) {
                        highestDate = new Date(filteredMeasurements[j].insertedOn);
                    }
                    let tempMeasurementData = { date: filteredMeasurements[j].insertedOn, price: (j + 1) * thisProject.details[i].price, color: thisProject.details[i].color };
                    tempFinancialData.push(tempMeasurementData);

                }

                tempPriceData.push({
                    price: thisProject.details[i].price !== null ? thisProject.details[i].price : 0,
                    totalPrice: tempTotal,
                    isProfit: thisProject.details[i].isProfit !== null ? thisProject.details[i].isProfit : true,
                    detailDescription: thisProject.details[i].description,
                    first: lowestDate,
                    last: highestDate,
                    amount: (detailType !== 2 ? filteredMeasurements.length : totalTime),
                    color: thisProject.details[i].color,
                    type: detailType,
                    minuteamount: (detailType !== 2 ? null : convertHMS(totalTime)),
                });
                if (thisProject.details[i].isProfit) {
                    total += tempTotal;
                } else {
                    total -= tempTotal;
                }
            }
        };

        setAllPriceData(tempPriceData)
        setTotalPrice(total);
        setOverviewData(tempPriceData);
        setPriceData(tempPriceData);
    }
    const constructFromAndToDate = (measurementData) => {
        let tempToDate;
        if (measurementData.length > 0) {
            tempToDate = new Date(measurementData[measurementData.length - 1].insertedOn);
        } else {
            tempToDate = new Date();
        }
        let tempFromDate = new Date(tempToDate - 86400000);
        return [tempFromDate, tempToDate];
    }

    const handleVisualRange = (data) => {
        setTimeRangeData(data);
    }

    const handlePreviousDay = () => {
        let tempFrom = timeRangeDates.fromDate;
        let tempTo = timeRangeDates.toDate;
        let newFromDate = new Date(new Date(tempFrom) - (24 * 60 * 60 * 1000));
        let newToDate = new Date(new Date(tempTo) - (24 * 60 * 60 * 1000));
        setTimeRangeDates({ fromDate: newFromDate, toDate: newToDate });
        handleDifferentDate(newFromDate, newToDate);

    }

    const handleNextDay = () => {
        let tempFrom = timeRangeDates.fromDate;
        let tempTo = timeRangeDates.toDate;
        let newFromDate = new Date(new Date(tempFrom).getTime() + 60 * 60 * 24 * 1000);
        let newToDate = new Date(new Date(tempTo).getTime() + 60 * 60 * 24 * 1000);
        setTimeRangeDates({ fromDate: newFromDate, toDate: newToDate });
        handleDifferentDate(newFromDate, newToDate);

    }

    const navigateToModifyDetail = (id) => {
        console.log(project)
        props.actions.companyAction(project.companyName);
        props.actions.companyIdAction(project.companyId);
        props.actions.resourceAction(project.resourceDescription);
        props.actions.fromDateAction(project.timeRangeBegin)
        props.actions.toDateAction(project.timeRangeEnd);
        props.actions.detailsAction(project.details);
        props.actions.projectIdAction(project.id);
        props.actions.projectNameAction(project.projectName);
        props.actions.projectQrAction(project.qrCode);
        history.push("/modify");
    }

    const handleDifferentDate = (tempFromDate, tempToDate) => {
        let thisProject = project;
        let tempFinancialData = [];
        let tempTotalFinancialData = [];
        let tempMeasurementsCollection = [];
        let chartMeasurementCollection = [];


        //Loop through all details and just toss all measurements together, afterwards flatten and sort by date
        for (let i = 0; i < thisProject.details.length; i++) {
            tempMeasurementsCollection.push(thisProject.details[i].measurements);
        }

        tempMeasurementsCollection = tempMeasurementsCollection.flat();
        tempMeasurementsCollection = tempMeasurementsCollection.sort((a, b) => new Date(a.insertedOn) - new Date(b.insertedOn));
        chartMeasurementCollection = tempMeasurementsCollection.filter(x => (new Date(x.insertedOn) >= new Date(tempFromDate) && new Date(x.insertedOn) <= new Date(tempToDate)) || (new Date(x.endDate) >= new Date(tempFromDate) && new Date(x.endDate) <= new Date(tempToDate)));
        constructPriceData(thisProject, tempFromDate, tempToDate);

        //Creates an object with all detail and their color (needed for the chart)
        let projectDetails = thisProject.details;

        //Creates an object with all detail and their color (needed for the chart)
        let detailNames = constructDetailCollection(projectDetails);

        //Construct the data that will be displayed in the financial chart
        let tempChartData = constructChartData(projectDetails, chartMeasurementCollection);
        let orderTest = tempFinancialData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setAllData(orderTest);
        setDetailsNames(detailNames);
        setTotalFinancialData(tempTotalFinancialData);
        setFinancialData(tempChartData);
        setFinancialChartData(tempChartData);
    }
    const updateDataRange = (data) => {
        let tempFromDate = new Date(data[0]);
        let tempToDate = new Date(data[1]);
        handleDifferentDate(tempFromDate, tempToDate);

    }
    const limitString = (str, limit) => {
        if (str.length > limit) {
            return str.substring(0, limit) + "...";
        } else {
            return str;
        }
    }
    const handleDownloadImage = async (toDownload) => {
        let element;
        let fileName ;
        if(toDownload === 'HighLow'){
            element = HighLowRef.current;
            fileName = 'HighLow.jpg';
        }else{
            element = ref.current; 
            fileName = 'Summary.jpg';


        }
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };
    const getImageUrl = (detail) => {
        let source;
        switch (detail.controlTypeID) {
            case 1:
                source = Counter;
                break;
            case 2:
                source = Stopwatch;

                break;
            case 3:
                source = FreeText;
                break;
            case 4:
                source = DataList;
                break;
            case 5:
                source = Scanner;
                break;
            case 6:
                source = Image;
                break;
            case 7:
                source = API;
                break;
            default:
            // code block
        }
        return source;
    }

    return (
        <div>
            <TitlePage Text="Financial dashboard" backbutton={true} {...props} />
            {/* {overviewData.length === 0 ? <div className={classes.noDataAvailable}>There is no data to display</div> : */}
            <div>
                {project !== null &&
                    <div className={classes.header}>
                        <div className={classes.fullHeader}>
                            <Typography variant='h5' className={classes.projectTitle}>
                                {project.projectName}
                            </Typography>
                            <div>
                                <div className={classes.extraInfo}><b style={{ marginRight: '10px' }}>Location:</b> <p>{project.resourceDescription}</p></div>
                                <div className={classes.extraInfo}><b style={{ marginRight: '10px' }}>Company:</b> <p>{project.companyName}</p></div>
                            </div>
                        </div>

                    </div>}

                <div className={classes.financialDashboardStyle}>

                    <div className={classes.boxStyle} ref={HighLowRef}>
                        <div className={classes.filterTitle} variant="contained" content="Options" >
                            <span>High/Low for this project</span>
                            {/* <Pdf targetRef={HighLowRef} filename="code-example.pdf">
                                {({ toPdf }) => <Button style={{ backgroundColor: 'white', color: '#C3996B', marginRight: '10px' }} onClick={toPdf}>Generate Pdf</Button>}
                            </Pdf> */}
                            <Button style={{ backgroundColor: 'white', color: '#C3996B' }} onClick={() => handleDownloadImage('HighLow')} data-html2canvas-ignore="true">Export</Button>
                        </div>

                        {isLoading ? <div className={classes.loadingBalls}><Spin width="100px" height="100px" color="#C3996B"></Spin></div> : project ? project.details.map(detail => {
                            if (detail.measurements.length > 0 && detail.price > 0) {
                                return (
                                    <div key={'TableHighLow_' + detail.id}  className={classes.highLowItem}>
                                        <div className={classes.headerContainer}>
                                            <div className={classes.detailHeader}>
                                                <img style={{ margin: "0px", width: "30px", height: "30px", marginRight: '10px' }} src={getImageUrl(detail)}></img> {detail.description}
                                            </div>
                                            {detail.isProfit ? <AddIcon style={{ color: 'rgb(40, 167, 69)' }}></AddIcon> : <RemoveIcon style={{ color: 'rgb(220, 53, 69)' }}></RemoveIcon>}

                                        </div>
                                        <Divider></Divider>
                                        <TableHighLow {...props} detail={detail} projectName={project.projectName} location={project.resourceDescription} />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <React.Fragment></React.Fragment>
                                )
                            }
                        }
                        ) : <React.Fragment></React.Fragment>}

                        {(measurementRows.length === 0 && !isLoading) &&  <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                            <span style={{ display: 'flex', textAlign: 'center',  fontSize: '16px', fontWeight: '500', color: '#1D3557' }}>There are no events for this project. <br/> Start tapping away!</span>                         
                               <img className={classes.noHighLow}   src={NoResults}></img>
</div>}
                   
                    </div>
                    {isLoading ? <div className={classes.loadingChart}><Spin width="100px" height="100px" color="#C3996B"></Spin></div> :

                        <div className={classes.rightSideStyle}>
                            <div className={classes.rightSideTitle}>Data between {parseNewDateToDDMMYYY(timeRangeDates.fromDate)} and {parseNewDateToDDMMYYY(timeRangeDates.toDate)}</div>
                            <div style={{ backgroundColor: 'white', marginBottom: '15px', borderRadius: '8px', paddingTop: '20px', paddingBottom: '20px', margin: '15px' }}>

                                {/* {project ? <TimeRangeChartCopy updateDataRange={updateDataRange} handleNextDay={handleNextDay} handlePreviousDay={handlePreviousDay}
                                    timeRangeDates={timeRangeDates}></TimeRangeChartCopy> :
                                    <div style={{ marginBottom: "40px" }}>
                                    </div>
                                } */}
                                {project ? <TimeRangeChart updateDataRange={updateDataRange} handleNextDay={handleNextDay} handlePreviousDay={handlePreviousDay}
                                    timeRangeDates={timeRangeDates}></TimeRangeChart> :
                                    <div style={{ marginBottom: "40px" }}>
                                    </div>
                                }

                            </div>

                            {financialChartData !== undefined && financialChartData.length > 0 ? <div className={classes.financialChart}>
                                <FinancialChart financialChartData={financialChartData} projectDetailNames={projectDetailNames} project={project}></FinancialChart></div> :

                                <div style={{ backgroundColor: 'white', height: '91%', padding: '25px', borderRadius: '8px', margin: '15px' }}>
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <div className={classes.noDataIcons}>
                                                <img className={classes.imageStyle} src={pieChart} alt="" />
                                                <ErrorIcon sx={{ fontSize: 100, color: '#dc3545', position: 'absolute', right: '0', bottom: '0' }} className={classes.errorIcon}></ErrorIcon>
                                            </div>
                                            <p style={{ marginTop: '30px',  fontSize: '16px', fontWeight: '500', color: '#1D3557' }}>No data to display between {parseNewDateToDDMMYYY(timeRangeDates.fromDate)} and {parseNewDateToDDMMYYY(timeRangeDates.toDate)}</p>
                                        </div>
                                    </div>
                                </div>

                            }

                            {priceData !== undefined && financialChartData.length > 0 ?
                                <div className={classes.chartBoxStyle}>
                                    <div className={classes.pieStyle} style={{ marginRight: '10px' }}>
                                        {priceData.filter(x => x.type !== 2).length > 0 ? <EventChart data={priceData.filter(x => x.type !== 2)} title={"Event overview"} /> :
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div className={classes.noDataIcons}>
                                                        <img className={classes.imageStyle} src={pieChart} alt="" />
                                                        <ErrorIcon sx={{ fontSize: 100, color: '#dc3545', position: 'absolute', right: '0', bottom: '0' }} className={classes.errorIcon}></ErrorIcon>
                                                    </div>
                                                    <p style={{ marginTop: '30px' }}>There were no event measurements during this timespan</p>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                    <div className={classes.pieStyle}>
                                        {priceData.filter(x => x.type === 2).length > 0 ? <EventChart data={priceData.filter(x => x.type === 2)} title={"Time overview"} /> :
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div className={classes.noDataIcons}>
                                                        <img className={classes.imageStyle} src={pieChart} alt="" />
                                                        <ErrorIcon sx={{ fontSize: 100, color: '#dc3545', position: 'absolute', right: '0', bottom: '0' }} className={classes.errorIcon}></ErrorIcon>
                                                    </div>
                                                    <p style={{ marginTop: '30px' }}>There were no stopwatch measurements during this timespan</p>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </div> : ''}

                            <div className={classes.tableContainer} ref={ref}>
                                <div className={classes.calculateBoxStyle}>
                                    <div className={classes.filterTitle} variant="contained" content="Options" >
                                        <span>Summary</span>
                                        <div >
                                            <Button style={{ backgroundColor: 'white', color: '#C3996B', marginRight: '10px' }} onClick={() => handleDownloadImage('Summary')} data-html2canvas-ignore="true">Export</Button>
                                            <Button onClick={() => navigateToModifyDetail(project.id)} style={{ backgroundColor: 'white', color: '#C3996B' }}>
                                                Modify
                                            </Button>
                                        </div>
                                    </div>

                                    <div>
                                        <PriceTable totalPrice={totalPrice} priceData={priceData} ></PriceTable>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>

            </div>
            {/* } */}
        </div>
    )
}
export default ProjectReportPage;

const useStyles = makeStyles((theme) => ({
    noDataAvailable: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: '8px',
        fontSize: '20px',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: '20px',
        paddingBottom: '20px',
        color: '#dc3545',
        paddingLeft: '15px'
    },
    bar: {
        [theme.breakpoints.up('lg')]: {
            minWidth: "70%",
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: "60%",
        },
        [theme.breakpoints.down('md')]: {
            minWidth: "65%",
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: "70%",
        },
    },

    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#ede6df',
        padding: '10px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexDirection: 'row',
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        width: '100%',
        flexDirection: 'row',
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '15px'
    },
    fullHeader: {
        width: '80%',
        [theme.breakpoints.down('lg')]: {
            width: "90%",
        },
        [theme.breakpoints.down('md')]: {
            width: "95%",
        },
        backgroundColor: '#C3996B',
        padding: '15px',
        color: 'white',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between'

    },
    projectTitle: {
        fontWeight: '500 !important'

    },
    extraInfo: {
        display: 'flex',
        justifyContent: 'end'
    },

    financialDashboardStyle: {
        display: 'flex',
        width: '80%',
        [theme.breakpoints.down('lg')]: {
            width: "90%",
        },
        [theme.breakpoints.down('md')]: {
            width: "95%",
            flexDirection: 'column'
        },
        height: '100%',
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "15px"
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexFlow: 'space-around',
        borderRadius: "8px",
        gap: "20px 30px",
        width: '70%',
        // [theme.breakpoints.up('lg')]: {
        //     maxWidth: "70%",
        // },
        // [theme.breakpoints.down('lg')]: {
        //     maxWidth: "70%",
        // },
        // [theme.breakpoints.down('md')]: {
        //     maxWidth: "90%",
        // },
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px"
    },
    titleTextStyle: {
        display: "flex",
        marginLeft: "20px",
    },
    noDataIcons: {
        position: 'relative'
    },
    errorIcon: {
        position: 'absolute',
        right: '25%',
        bottom: '25%'
    },
    imageStyle: {
        height: '100%',
        width: '100%',
        filter: 'invert(87%) sepia(15%) saturate(124%) hue-rotate(341deg) brightness(108%) contrast(86%)',

    },
    noHighLow: {
        height: '100px',
        width: '100px',
        marginTop: '15px',

    },
    boxStyle: {
        // height: 'calc(100vh + 15px)',
        overflowY: 'hidden',
        width: '30%',

        [theme.breakpoints.down('md')]: {
            width: "100%",
            marginBottom: '15px'
        },
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        borderRadius: "8px",
        marginRight: '15px',
        //minHeight: '1000px'

    },
    loadingBalls: {
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingChart: {
        height: '100vh',
        width: '70%',
        position: 'relative',
        borderRadius: "8px",
        backgroundColor: 'white',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    detailHeader: {
        fontSize: '16px',
        fontWeight: '600'
    },
    rightSideStyle: {
        borderRadius: '8px',
        height: '100%',
        width: '70%',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        position: 'relative',
        background: '#ede6df'
    },
    chartBoxStyle: {
        height: '50%',
        margin: '15px',
        overflow: 'hidden',
        display: "flex",
        flexGrow: '2',
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px",

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: 'calc(100% - 30px)'
        },
    },
    tableContainer: {
        margin: '15px'
    },

    pieStyle: {

        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '15px'
        },
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "18px",

    },
    highLowItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    calculateBoxStyle: {
        overflow: 'hidden',
        display: "flex",
        flexGrow: '3',
        justifyContent: "space-between",
        flexDirection: "column",
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "18px",
        gap: "10px",

    },
    menuItem: {
        color: '#1D3557',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '100% !important',
            marginBottom: '15px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
            fontSize: '1.2rem',
        },
        display: "flex",
        alignItems: "center",
    },

    iconStyle: {
        verticalAlign: "-8px",
        marginRight: "4px"
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
        },
    },
    linkButton: {
        width: '100% !important',
        minWidth: '100% !important',
        [theme.breakpoints.down('sm')]: {
            minHeight: "46.8px",
            height: "90%",
            padding: "10px",
            fontSize: "1rem",
        },
        [theme.breakpoints.up('lg')]: {
            height: "40px",
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1.2rem",
        },

        background: "#C3996B !important",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hstyle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white',
        whiteSpace: 'nowrap'
        // color: "#1D3557",
    },
    titleStyle: {
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        color: 'white'

    },
    headerLeft: {
        alignItems: 'center',
        display: 'flex',
        width: '33%'
    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center'

    },
    headerRight: {
        width: '33%',
        display: 'flex',
        justifyContent: 'end',
        color: 'white'
    },
    arrowBack: {
        padding: '15px',
        cursor: 'pointer'
    },
    taplrLogo: {
        width: '150px',
        cursor: 'pointer'
    },
    filterTitle: {
        backgroundColor: "#C3996B !important",
        color: 'white',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'

    },
    rightSideTitle: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',

        backgroundColor: "#C3996B !important",
        color: 'white',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'

    },
    stylingBlock: {
        backgroundColor: '#f0f0f0',
        borderRadius: '6px',
        boxShadow: 'inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692)',
        padding: "25px"
    },
    filterBlock: {
        display: 'flex',
        marginBottom: '15px',
        flexWrap: 'wrap',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-around',
            gap: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-evenly',
        }
    },
}));