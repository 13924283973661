import React from 'react';
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import { LightenDarkenColor } from 'lighten-darken-color';
import Tally from './counterStyle.svg';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import PhotoIcon from '@mui/icons-material/Photo';
import Webcam from "react-webcam";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import InputAdornment from '@mui/material/InputAdornment';
import { parseNewDateToDDMMYYY } from '../../../Utils/dateHelper';

function Counter(props) {
    const classes = useStyles();
    const [captured, setCaptured] = useState(false);
    const [extraContent, setExtraContent] = useState(false)
    const [openComment, setOpenComment] = useState(false);
    const [openCamera, setOpenCamera] = useState(false);
    const [openFullscreenImage, setOpenFullscreenImage] = useState(false);
    const [image, setImage] = useState("");
    const [comment, setComment] = useState("");
    const [cameraSide, setCameraSide] = useState(true)
    const [expanded, setExpanded] = useState(false);
    const webcamRef = React.useRef(null);
    const [value, setValue] = useState(0);
    const [operator, setOperator] = useState(false);
    const [listOfComments, setListOfComments] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setListOfComments(props.comments)
        // eslint-disable-next-line no-undef
    }, [props.refresh]);

    const cameraFace = {
        facingMode: "user"
    }

    const cameraBack = {
        facingMode: { exact: "environment" }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDialogOpenComment = () => {
        setOpenComment(true);
    }

    const handleDialogCloseComment = () => {
        setOpenComment(false);
    }

    const handleDialogOpenCamera = () => {
        setOpenCamera(true);
        setOpenComment(false);
    }

    const handleDialogCloseCamera = () => {
        setOpenCamera(false);
        setOpenComment(true);
    }

    const handleDialogOpenFullscreenImage = () => {
        setOpenFullscreenImage(true);
    }

    const handleDialogCloseFullscreenImage = () => {
        setOpenFullscreenImage(false);
    }

    const Cameremode = () => {
        setCameraSide(!cameraSide)
    }

    const handleExtraContentClick = () => {
        setExtraContent(!extraContent);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handlePage = (event, value) => {
        setPage(value);
    };

    const Increment = () => {
        if (props.comment) {
            setComment("");
            setImage("");
            handleDialogOpenComment();
            setOperator(true);
        }
        else {
            props.action(parseInt(props.counter) + 1, props.id);
        }
    }

    const Decrement = () => {
        if (props.comment) {
            setComment("");
            setImage("");
            handleDialogOpenComment();
            setOperator(false);
        }
        else {
            props.action(parseInt(props.counter) - 1, props.id);
        }
    }

    const handleSendWithComment = () => {
        if (operator) {
            props.actionWithComment(parseInt(props.counter) + 1, props.id, image.slice(23), comment);
        }
        else {
            props.actionWithComment(parseInt(props.counter) - 1, props.id, image.slice(23), comment);
        }
        handleDialogCloseComment();
        let tempListOfComments = [...listOfComments];
        let index = tempListOfComments.length + 1;
        let newListItem = { image: image, comment: comment, index: index++, insertedOn: new Date(Date.now()) };
        tempListOfComments.push(newListItem)
        setListOfComments(tempListOfComments)
        setPage(index - 1)
    }

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc !== null) {
            setCaptured(true);
            setImage(imageSrc);
        }
        else {
            alert("No Camera Found");
        }
    }

    const CameraStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        objectFit: "cover",
        top: "0",
        left: "0",
    }

    return (
        <div>
            <Card>
                <div className={classes.StyleCounter} style={{
                    color: props.textColor,
                    backgroundColor: `${props.color}`,
                }}>
                    <div className={classes.headerDiv} >
                        {props.comment && (listOfComments.length > 0 || listOfComments != "") ?
                            <Button style={{ zIndex: "4", position: "absolute", left: "3%" }} size={'large'} onClick={handleExtraContentClick}>
                                <ReportProblemIcon style={{ color: props.textColor }} />
                            </Button>
                            : <React.Fragment></React.Fragment>
                        }
                        <h2 className={classes.h2style}>{props.description}</h2>
                        <img className={classes.imageStyle} src={Tally} alt="" />
                    </div>
                    <Divider ></Divider>
                    <div className={classes.counterDiv} style={{ backgroundColor: LightenDarkenColor(props.color, 20) }}>
                        <p style={{ fontSize: "50px" }}>{props.counter}</p>
                    </div>
                    <Divider ></Divider>
                    <div className={classes.StyleDiv}>
                        <Button style={{ color: props.textColor, width: "100%" }} >
                            <RemoveIcon fontSize="large" onClick={Decrement} style={{ color: props.textColor, width: "100%" }} />
                        </Button>
                        <Divider orientation="vertical"></Divider>
                        <Button style={{ color: props.textColor, width: "100%" }} >
                            <AddIcon fontSize="large" onClick={Increment} style={{ color: props.textColor, width: "100%" }} />
                        </Button>
                    </div>
                </div>

                <Dialog
                    fullScreen
                    open={openCamera}
                    onClose={handleDialogCloseCamera}
                    maxWidth={"lg"}
                >
                    <DialogTitle style={{ background: "rgba(255, 255, 255, .0)", zIndex: "1" }}>
                        {"Camera"}
                        <IconButton style={{ position: "absolute", right: "10px", top: "5px" }} onClick={handleDialogCloseCamera} size="large">
                            <CloseIcon size="large" fontSize="inherit" style={{ color: "black" }} />
                        </IconButton>
                    </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        {
                            cameraSide === true ?
                                <Webcam
                                    style={CameraStyle}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={cameraBack}
                                ></Webcam> :
                                <Webcam
                                    style={CameraStyle}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={cameraFace}
                                ></Webcam>
                        }
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions style={{ justifyContent: "center", background: "rgba(255, 255, 255, .25)", zIndex: "1" }}>
                        <IconButton onClick={handleExpandClick} size="large">
                            {captured ?
                                <img style={{ margin: "0px", width: "2rem", height: "2rem", borderRadius: "50%" }} src={image}></img>
                                :
                                <PhotoIcon size="large" fontSize="inherit" style={{ color: "black" }} />
                            }
                        </IconButton>
                        <IconButton onClick={capture} size="large">
                            <CircleIcon size="large" fontSize="inherit" style={{ color: "black" }} />
                        </IconButton>
                        <IconButton onClick={Cameremode} size="large">
                            <CameraswitchIcon size="large" fontSize="inherit" style={{ color: "black" }} />
                        </IconButton>
                    </DialogActions>
                    <Divider style={{ background: "rgb(0, 0, 0)", zIndex: "2" }}></Divider>
                    <Collapse style={{ background: "rgb(255, 255, 255)", zIndex: "2" }} in={expanded} timeout="auto" unmountOnExit>
                        <DialogContent style={{ background: "rgb(255, 255, 255)", zIndex: "2" }}>
                            <img
                                style={{ background: "rgb(255, 255, 255)", zIndex: "2", maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                                src={image}></img>
                        </DialogContent>
                    </Collapse>
                </Dialog>

                <Dialog
                    fullWidth
                    open={openComment}
                    onClose={handleDialogCloseComment}>
                    <DialogTitle>
                        {"Comment"}
                        <IconButton style={{ position: "absolute", right: "10px", top: "5px" }} onClick={handleDialogCloseComment} size="large">
                            <CloseIcon fontSize="inherit" color='blue' />
                        </IconButton>
                    </DialogTitle>
                    <Divider ></Divider>
                    <div className={classes.StyleDivComment}>
                        <TextField multiline
                            rows={8}
                            style={{ marginBottom: "10px", margin: "10px", width: "87%" }}
                            label={comment ? " " : "Make a comment."}
                            InputLabelProps={{ shrink: false }} variant="outlined"
                            onChange={(event) => setComment(event.target.value)}
                            value={comment}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment style={{ marginTop: "160px" }}>
                                        {image !== "" ?
                                            <div>
                                                <IconButton onClick={handleDialogOpenFullscreenImage}>
                                                    <img style={{ margin: "0px", width: "2rem", height: "2rem", borderRadius: "50%" }} src={image}></img>
                                                </IconButton>
                                                <IconButton onClick={handleDialogOpenCamera} >
                                                    <PhotoCameraIcon />
                                                </IconButton>
                                            </div>
                                            :
                                            <div>
                                                <IconButton onClick={handleDialogOpenCamera} >
                                                    <PhotoCameraIcon />
                                                </IconButton>
                                            </div>
                                        }
                                    </InputAdornment>)
                            }} />
                        <IconButton style={{ marginBottom: "12px" }} onClick={handleSendWithComment} size="large">
                            <SendIcon fontSize="inherit" color='blue' />
                        </IconButton>
                    </div>
                </Dialog>


                <Dialog
                    fullScreen
                    open={openFullscreenImage}
                    onClose={handleDialogCloseFullscreenImage}>
                    <DialogTitle style={{ background: "rgba(0, 0, 0, .0)", zIndex: "1" }}>
                        <IconButton style={{ position: "absolute", right: "10px", top: "5px" }} onClick={handleDialogCloseFullscreenImage} size="large">
                            <CloseIcon fontSize="inherit" color='blue' />
                        </IconButton>
                    </DialogTitle>
                    <Divider ></Divider>
                    <DialogContent>
                        <img style={CameraStyle} src={image}></img>
                    </DialogContent>
                </Dialog>

            </Card>
            {extraContent ? <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: "1", width: "100%", zIndex: "10" }}>
                    <Collapse in={extraContent} timeout="auto" unmountOnExit >
                        <Card >
                            {listOfComments.filter(list => list.index === page).map(x =>
                                <List sx={{ width: '100%', overflow: "hidden" }}>
                                    <ListItem>
                                        <Typography style={{ width: "70%" }} >{props.description + " Comment: " + x.index}</Typography>
                                        <Typography style={{ width: "30%", fontWeight: "bold" }}>{parseNewDateToDDMMYYY(new Date(x.insertedOn))}</Typography>
                                    </ListItem>
                                    <Divider></Divider>
                                    {x.image !== "" && x.image !== null ?
                                        <ListItem style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>
                                            <img src={x.image} style={{ maxWidth: "100%", maxHeight: "calc(40vh - 16px)", borderRadius: "15px" }} alt="" />
                                        </ListItem>
                                        :
                                        <React.Fragment></React.Fragment>}
                                    {x.comment !== "" ?
                                        <ListItem>
                                            <Typography style={{ hyphens: "auto", wordWrap: "break-word", overflowWrap: "anywhere" }}>{x.comment}</Typography>
                                        </ListItem>
                                        :
                                        <React.Fragment></React.Fragment>}
                                    {x.comment === "" && (x.image === "" || x.image === null) ?
                                        <ListItem>
                                            <Typography style={{ color: "red" }} >No recorded comment.</Typography>
                                        </ListItem>
                                        :
                                        <React.Fragment></React.Fragment>}
                                    <Divider></Divider>
                                    <ListItem style={{ justifyContent: 'center' }}>
                                        <Pagination page={page} variant="outlined" size="small" siblingCount={0} count={listOfComments.length} onChange={handlePage} />
                                    </ListItem>
                                </List>
                            )
                            }
                        </Card>
                    </Collapse>
                </div>
            </div> :
                <React.Fragment></React.Fragment>}
        </div>
    )
}
export default Counter;

const useStyles = makeStyles({
    headerDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
        position: 'relative'
    },
    h2style: {
        margin: '0',
        zIndex: '2',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    StyleDiv: {
        height: '33%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: 'center',
    },
    StyleDivComment: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'flex-end',
    },
    counterDiv: {
        display: "flex",
        justifyContent: "center",
        height: '34%',
        alignItems: 'center',

    },
    fabStyle: {
        position: 'absolute',
        left: '3%'
    },
    imageStyle: {
        position: 'absolute',
        right: '3%',
        opacity: '50%',
        width: '50px'
    },
    StyleCounter: {
        overflow: 'hidden',
        backgroundRepeat: "no-repeat !important",
        backgroundSize: "10rem 10rem",
        backgroundPosition: "center",
        height: "12rem"
    }

});