import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import * as Actions from '../../../../Reducers/actions'
import { useHistory } from 'react-router-dom';

function EditButton(props) {
    var today = new Date();
    const [FromDate, setFromDate] = useState(today);
    const [ToDate, setToDate] = useState(today);
    const [projectQr, setProjectQR] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [ResourceName, setResourceName] = useState("")
    const [CompanyName, setCompanyName] = useState("")
    const [CompanyId, setCompanyId] = useState(null)
    const [ProjectName, setProjectName] = useState("")
    const [projectrows, setProjectRows] = useState([])
    const history = useHistory();

    useEffect(() => {
        setProjectQR(props.data.projectQr);
        setProjectName(props.data.projectName);        
        setCompanyName(props.data.company);
        setCompanyId(props.data.companyId);
        setResourceName(props.data.resource);
        setFromDate(props.data.fromDate);
        setToDate(props.data.toDate);
        setProjectId(props.data.projectId);
        setProjectRows(props.data.details);
    }, [props.data.projectQr, props.data.projectName, props.data.company, props.data.companyId, props.data.resource, props.data.fromDate, props.data.toDate, props.data.projectId, props.data.details])

    const SaveAndRedirect = () => {
        let tempCompanyName = CompanyName;
        let tempCompanyId = CompanyId;
        let tempResourceName = ResourceName;
        let tempProjectName = ProjectName;
        let tempFromDate = FromDate;
        let tempToDate = ToDate;
        let tempProjectId = projectId;
        let tempProjectQR = projectQr;
        let tempProjectRows = projectrows.map(x => ({...x}));

        props.actions.companyAction(tempCompanyName)
        props.actions.companyIdAction(tempCompanyId)
        props.actions.resourceAction(tempResourceName)
        props.actions.fromDateAction(tempFromDate)
        props.actions.toDateAction(tempToDate)
        props.actions.detailsAction(tempProjectRows)
        props.actions.projectIdAction(tempProjectId)
        props.actions.projectNameAction(tempProjectName)
        props.actions.projectQrAction(tempProjectQR)
        history.push("/modify");
    }

    return (
        <div>
            <IconButton style={{ borderRadius: 0, borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }} onClick={SaveAndRedirect} size='large'>
                <EditIcon fontSize="inherit" />
            </IconButton>
        </div>
    )
}

const mapStateToProps = state => ({
    projects: state.projects,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
))(EditButton);