import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import ApiHelper from '../../Utils/API/apiHelper'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

function CopyQrLink(props) {

    const [openToast, setOpenToast] = useState(false);
    const appSettings = ApiHelper.appSettings;

    const handleCopy = () => {
        handleOpenToast();
        navigator.clipboard.writeText(appSettings.TAPLR_BASE_URL + "perfmeasurement?qr=" + props.QrCode);
    };

    const handleOpenToast = () => {
        setOpenToast(true);
    };

    const handleCloseToast = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseToast}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const Style = {
        width: '100%',
        marginTop: "10px"
    }
    const copyUrl = {
        marginTop: "15px",
    }

    return (
        <div style={copyUrl}>
            <TextField
            style={Style}
                label="Copy link to clipboard:"
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={handleCopy}>
                                <FileCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                value={props.value}>
            </TextField>

            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message="Link copied to clipboard"
                action={action}
            />
        </div>
    )
}

export default CopyQrLink;