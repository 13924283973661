import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonAction from '../Buttons/ButtonAction';
import ButtonClose from '../Buttons/ButtonClose';

export default function MessageDialog(props) {

    const classes = useStyles();

    return (

        <BaseDialog
            dialogOpen={props.dialogOpen}
            closeDialog={props.closeDialog}
        >
            <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
                {props.messageTitle}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {props.messageContent}
            </DialogContent>
            <DialogActions>
                <div className={classes.buttons}>
                    {props.buttonCount === 1 ?
                        <ButtonAction text={props.buttonText} action={props.action}></ButtonAction>
                        :
                        <React.Fragment>
                            <ButtonAction text={props.buttonText} action={props.action}></ButtonAction>
                            <ButtonClose text={props.button2Text} closeDialog={props.closeDialog}></ButtonClose>
                        </React.Fragment>}
                </div>
            </DialogActions>
        </BaseDialog>
    );
}


const useStyles = makeStyles(() => ({

    buttons: {
        textAlign: "center",
        width: "100%",
        marginBottom: "10px"
    },
    dialogTitle: {
        color: "#000000",
        fontFamily: "Lato, sans-serif",
        fontWeight: 'medium',
        textAlign: "center",
        '& h2': {
            fontSize: "24px !important",
        },
        paddingTop: "20px",
        cursor: 'move'
    },
    dialogContent: {
        textAlign: "center",
        fontFamily: "Lato, sans-serif",
    }

}));
