import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import TitlePage from '../../Shared/TitlePage';
import { dataService } from '../../../Services/data/data.service';
import { imageListClasses, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreateIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import EuroIcon from '@mui/icons-material/Euro';
import { parseNewDateToDDMMYYY, parseNewDateToDDMMYYYnoTime } from "../../../Utils/dateHelper";


function ProjectReportOverviewPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const [projectList, setProjectList] = useState([]);
    const initialProjectList = useRef([]);
    const firstLoad = useRef(false);
    const [loadAmount, setLoadAmount] = useState(21);
    const currentDate = new Date();
    const [pageUp, setPageUp] = useState(false);

    const loadMore = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            let TempLoadAmount = loadAmount + 9;
            if (firstLoad.current) {
                dataService.getAllUserCompanyProjects(props.Login.userid, loadAmount + 9, 9).then(results => {
                    setProjectList(sortProjectsOnFinished(initialProjectList.current.concat(results)));
                });
                firstLoad.current = false;
            }
            else {
                dataService.getAllUserCompanyProjects(props.Login.userid, loadAmount + 9, 9).then(results => {
                    if (results.length > 0) {
                        setProjectList(sortProjectsOnFinished(projectList.concat(results)));
                    }
                    else {
                        setPageUp(true);
                    }
                });
            }
            setLoadAmount(TempLoadAmount);
            window.removeEventListener('scroll', loadMore);
        }
    }

    useEffect(() => {
        dataService.getAllUserCompanyProjects(props.Login.userid, 0, loadAmount).then(results => {
            setProjectList(sortProjectsOnFinished(results));
            window.addEventListener('scroll', loadMore);
            console.log(results);
            initialProjectList.current = results;
            firstLoad.current = true;
        });
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', loadMore);
    }, [loadAmount]);

    const ResultRedirect = (projectId, resourceName, fromDate, toDate) => {
        let tempProjectId = projectId;
        let tempResourceName = resourceName;
        let tempFromDate = fromDate;
        let tempToDate = toDate;
        let selectedProject = projectList.find(x => x.id === projectId);
        let dateArray = [];
        selectedProject.details.map((detail) => {
            detail.measurements.map(element => {
                dateArray.push(new Date((element.insertedOn).replace('T', ' ')));
            });

        });
        let tempMaxDate = parseNewDateToDDMMYYY(new Date(Math.max.apply(null, dateArray)));
        let tempMinDate = parseNewDateToDDMMYYY(new Date(Math.max.apply(null, dateArray) - 86400000));
        props.actions.fromDateAction(tempMinDate);
        props.actions.toDateAction(tempMaxDate);

        props.actions.projectIdAction(tempProjectId);
        props.actions.resourceAction(tempResourceName);
        props.actions.projectReportAction(true);
        props.actions.projectNameAction(selectedProject.projectName);
        history.push("/results");
    }
    const ModifyRedirect = (projectId) => {
        let thisProject = projectList.find(x => x.id === projectId);
        let fromDate = thisProject.timeRangeBegin;
        let toDate = thisProject.timeRangeEnd;
        props.actions.projectIdAction(projectId);
        props.actions.projectQrAction(thisProject.qrCode);
        props.actions.projectNameAction(thisProject.projectName);
        props.actions.companyAction(thisProject.companyName);
        props.actions.companyIdAction(thisProject.companyId);
        props.actions.resourceAction(thisProject.resourceDescription);
        props.actions.fromDateAction(fromDate);
        props.actions.toDateAction(toDate);
        history.push("/modify");
    }

    const ReportRedirect = (projectId) => {
        let tempProjectId = projectId;
        props.actions.projectIdAction(tempProjectId)
        history.push("/projectreport");
    }

    const getPercentage = (fromDate, toDate) => {
        let fromDateDate = new Date(fromDate);
        let toDateDate = new Date(toDate);
        let difference = currentDate.getTime() - fromDateDate.getTime();
        let percentage = (difference / (toDateDate.getTime() - fromDateDate.getTime())) * 100;
        return percentage;
    }

    const getDaysLeft = (fromDate, toDate) => {
        let fromDateDate = new Date(fromDate);
        let toDateDate = new Date(toDate);
        let difference = toDateDate.getTime() - currentDate.getTime();
        let daysLeft = Math.round(difference / (1000 * 60 * 60 * 24));
        return daysLeft;
    }

    const getMeasurementCount = (project) => {
        let measurementCount = 0;
        if (project.details.length > 0) {
            project.details.forEach(detail => {
                measurementCount += detail.measurements.length;
            })
        }
        if (measurementCount === 0) {
            return "No events recorded";
        }
        return measurementCount;
    }

    const handleGoTopPage = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const sortProjectsOnFinished = (projects) => {
        var results = projects.map(project => {
            let percentage = getPercentage(Date.parse(project.timeRangeBegin), Date.parse(project.timeRangeEnd))
            if (percentage < 100) {
                project.finished = false
            }
            else {
                project.finished = true
            }

            return project
        })

        results = results.sort((a, b) => (a.finished - b.finished))
        return results
    }

    return (
        <div>
            <TitlePage Text="Project overview" backbutton={true} {...props} />
            <div className={classes.container}>
                {projectList.map(project => (
                    <div className={classes.boxStyle} key={project.id}>
                        <div className={classes.headerContainer}>
                            <Typography variant='h6' onClick={() => ModifyRedirect(project.id)} className={classes.headerTitle}>
                                {project.projectName}
                            </Typography>
                            <div className={classes.buttonContainer}>
                                <IconButton onClick={() => ResultRedirect(project.id, project.resourceDescription, project.timeRangeBegin, project.timeRangeEnd)} size='small' style={{ height: "50px", width: "50px", borderRadius: "25px" }}>
                                    <BarChartIcon></BarChartIcon>
                                </IconButton>
                                <IconButton onClick={() => ReportRedirect(project.id)} size='small' style={{ height: "50px", width: "50px", borderRadius: "25px" }}>
                                    <EuroIcon></EuroIcon>
                                </IconButton>
                            </div>
                        </div>
                        <Divider></Divider>
                        <div className={classes.dateContainer}>
                            <Typography variant='h7' style={{ fontWeight: 'bold', minWidth: "10%" }}>
                                Timerange:
                            </Typography>
                            {!project.finished ?
                                <Tooltip title={
                                    getDaysLeft(Date.parse(project.timeRangeBegin), Date.parse(project.timeRangeEnd)) === 1 ?
                                        `${getDaysLeft(Date.parse(project.timeRangeBegin), Date.parse(project.timeRangeEnd))} day left`
                                        : `${getDaysLeft(Date.parse(project.timeRangeBegin), Date.parse(project.timeRangeEnd))} days left`}>
                                    <LinearProgress className={classes.bar} style={{ height: "8px", borderRadius: "15px", marginTop: "6px" }} color='success' variant="determinate" value={getPercentage(Date.parse(project.timeRangeBegin), Date.parse(project.timeRangeEnd))} />
                                </Tooltip>
                                :
                                <LinearProgress className={classes.bar} style={{ height: "8px", borderRadius: "15px", marginTop: "6px" }} color='error' variant="determinate" value={100} />
                            }
                        </div>
                        <div>
                            <Typography variant='h7' style={{ fontWeight: 'bold' }}>
                                Location:
                            </Typography>
                            <Typography variant='h7'>
                                {" " + project.resourceDescription}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant='h7' style={{ fontWeight: 'bold' }}>
                                Events:
                            </Typography>
                            <Typography variant='h7'>
                                {" " + getMeasurementCount(project)}
                            </Typography>
                        </div>
                    </div>
                ))}
                <Fab onClick={handleGoTopPage} variant="extended" style={{ background: "rgb(195, 153, 107)", color: 'white', position: "fixed", bottom: '25px', right: "17%" }}>
                    <NavigationIcon />
                </Fab>
            </div>


        </div>
    )
}

export default ProjectReportOverviewPage;

const useStyles = makeStyles((theme) => ({

    bar: {
        [theme.breakpoints.up('lg')]: {
            minWidth: "70%",
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: "60%",
        },
        [theme.breakpoints.down('md')]: {
            minWidth: "65%",
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: "70%",
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexDirection: 'row',
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        width: '100%',
        flexDirection: 'row',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        borderRadius: "8px",
        [theme.breakpoints.up('lg')]: {
            maxWidth: "50%",
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: "70%",
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
        },
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px"
    },
    titleTextStyle: {
        display: "flex",
        marginLeft: "20px",
    },
    boxStyle: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "18px",
        gap: "10px",
        marginBottom: '15px',

        [theme.breakpoints.up('sm')]: {
            width: '32%',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
        },

    },
    menuItem: {
        color: '#1D3557',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '100% !important',
            marginBottom: '15px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
            fontSize: '1.2rem',
        },
        display: "flex",
        alignItems: "center",
    },

    iconStyle: {
        verticalAlign: "-8px",
        marginRight: "4px"
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
        },
    },
    headerTitle: {
        cursor: 'pointer'
    },
    linkButton: {
        width: '100% !important',
        minWidth: '100% !important',
        [theme.breakpoints.down('sm')]: {
            minHeight: "46.8px",
            height: "90%",
            padding: "10px",
            fontSize: "1rem",
        },
        [theme.breakpoints.up('lg')]: {
            height: "40px",
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1.2rem",
        },

        background: "#C3996B !important",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hstyle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white',
        whiteSpace: 'nowrap'
        // color: "#1D3557",
    },
    titleStyle: {
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        color: 'white'

    },
    headerLeft: {
        alignItems: 'center',
        display: 'flex',
        width: '33%'
    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center'

    },
    headerRight: {
        width: '33%',
        display: 'flex',
        justifyContent: 'end',
        color: 'white'
    },
    arrowBack: {
        padding: '15px',
        cursor: 'pointer'
    },
    taplrLogo: {
        width: '150px',
        cursor: 'pointer'
    }
}));