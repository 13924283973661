import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { parseNewDateToDDMMYYY, parseNewDateToDDMMYYYnoTime } from "../../../../Utils/dateHelper";
import Divider from '@mui/material/Divider';

export default function TableHighLow(props) {
    const [mostCommonDate, setMostCommonDate] = useState('/');
    const [leastCommonDate, setLeastCommonDate] = useState('/');
    const [averagePrice, setAveragePrice] = useState({ average: '/', price: '/' });


    useEffect(() => {
        findMostCommonDate(props.detail.measurements);
        findLeastCommonDate(props.detail.measurements);
        getAllActiveDates(props.detail.measurements);

    }, [])

    const classes = useStyles();
    const history = useHistory();

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function convertMsToTime(milliseconds) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds,
        )}`;
    }

    const findMostCommonDate = (measurements) => {
        let tempMostDates = measurements.map(measurement => parseNewDateToDDMMYYYnoTime(new Date(Date.parse(measurement.insertedOn))));
        let dateMap = new Map();
        tempMostDates.forEach(date => {
            if (dateMap.has(date)) {
                dateMap.set(date, dateMap.get(date) + 1);
            }
            else {
                dateMap.set(date, 1);
            }
        });
        let mostCommonDate = "";
        let mostCommonCount = 0;
        dateMap.forEach((count, date) => {
            if (count > mostCommonCount) {
                mostCommonDate = date;
                mostCommonCount = count;
            }
        });
        setMostCommonDate(mostCommonDate);
    }

    const findLeastCommonDate = (measurements) => {
        let tempLeastDate = measurements.map(measurement => parseNewDateToDDMMYYYnoTime(new Date(Date.parse(measurement.insertedOn))));
        let dateMap = new Map();
        tempLeastDate.forEach(date => {
            if (dateMap.has(date)) {
                dateMap.set(date, dateMap.get(date) + 1);
            }
            else {
                dateMap.set(date, 1);
            }
        });
        let leastCommonDate = "";
        let leastCommonCount = 99999;
        dateMap.forEach((count, date) => {
            if (count < leastCommonCount) {
                leastCommonDate = date;
                leastCommonCount = count;
            }
        });
        setLeastCommonDate(leastCommonDate);
    }

    const getAllActiveDates = (measurements) => {
        if (measurements.length > 0) {
            let tempDates = measurements.map(measurement => parseNewDateToDDMMYYYnoTime(new Date(Date.parse(measurement.insertedOn))));
            let arrUniqueValues = getUniqueValuesFromArray(tempDates);
            let arrEventsPerDay = [];
            arrUniqueValues.forEach((strMeasurementDate) => {
                var dateParts = strMeasurementDate.split("/");
                var tempFromDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                var tempToDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).addHours(24);
                let objFilteredMeasurements = measurements.filter(x => new Date(x.insertedOn) >= tempFromDate && new Date(x.insertedOn) <= tempToDate);
                let total;
                if (objFilteredMeasurements[0].typeId === 2) {
                    //Som van de tijden
                    total = objFilteredMeasurements.reduce((a, v) => a = a + v.integerValue, 0);
                } else {
                    //Som van het aantal events
                    total = objFilteredMeasurements.length;
                }
                arrEventsPerDay.push({ date: tempFromDate, total: total });

            });
            let intSumOfTotal = arrEventsPerDay.reduce((a, b) => a + b.total, 0);
            let floatAvgOfTotal = (intSumOfTotal / arrEventsPerDay.length) || 0;

            let numberPrice = 0;
            if (measurements[0].typeId === 2) {
                let avgHour = floatAvgOfTotal / 3600;
                numberPrice = avgHour * props.detail.price;
                floatAvgOfTotal = new Date(floatAvgOfTotal * 1000).toISOString().substring(11, 19)
            } else {
                numberPrice = floatAvgOfTotal * props.detail.price;

            }
            let averageObject = { average: floatAvgOfTotal, price: numberPrice.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2}) };
            setAveragePrice({ average: floatAvgOfTotal, price: numberPrice.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2}) });
        } else {
            setAveragePrice({ average: '/', price: '/' });
        }



    }
    Date.prototype.addHours = function (h) {
        this.setHours(this.getHours() + h);
        return this;
    }

    const getUniqueValuesFromArray = (array) => {
        var newArray = [];
        var newArray = array.filter(function (elem, pos) {
            return array.indexOf(elem) == pos;
        });
        return newArray;
    }

    const findEventsWithDate = (measurements, date) => {
        if (date !== '/' && date !== undefined) {
            let events = measurements.filter(measurement => parseNewDateToDDMMYYYnoTime(new Date(Date.parse(measurement.insertedOn))) === date);
            if (events[0].typeId === 2) {
                //Stopwatch type
                let sum = 0;
                let filterEvents = events.filter(x => x.stopValue !== null);

                filterEvents.forEach(event => {
                    let start = new Date(Date.parse(event.startValue));
                    let stop = new Date(Date.parse(event.stopValue));
                    let diff = stop.getTime() - start.getTime();
                    sum += diff;
                });
                return (convertMsToTime(sum));
            }
            else {
                return (events.length);
            }
        }

    }

    const findPrice = (value, detail) => {
        if (value !== '/' && value !== undefined) {
            if (detail.controlTypeID === 2 && (typeof value) === 'string') {                
                const [hours, minutes, seconds] = value.split(':');
                // minutes are worth 60 seconds. Hours are worth 60 minutes.
                let totalSeconds = convertToSeconds(hours, minutes, seconds);
                let tempTotal = ((detail.price / 3600) * totalSeconds);
                return tempTotal.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2});
            } else {
                let tempTotal = (value * detail.price);

                return tempTotal.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2});
            }
        }

        // const events = measurements.filter(measurement => parseNewDateToDDMMYYYnoTime(new Date(Date.parse(measurement.insertedOn))) === date);
        // if (events[0].startValue !== null && events[0].stopValue !== null) {
        //     let sum = 0;
        //     events.forEach(event => {
        //         const start = new Date(Date.parse(event.startValue));
        //         const stop = new Date(Date.parse(event.stopValue));
        //         const diff = stop.getTime() - start.getTime();
        //         sum += diff;
        //     });
        //     return (convertMsToTime(sum));
        // }
        // else {
        //     return (events.length);
        // }
    }

    function convertToSeconds(hours, minutes, seconds) {
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
    }
    const handleResultsRedirect = (StartDate) => {
        var datearray = StartDate.split("/");
        var newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
        props.actions.projectNameAction(props.projectName);
        props.actions.resourceAction(props.location);
        console.log('StartDate ', StartDate)
        let fromData = Date.parse(newdate);
        let toDate = Date.parse(newdate) + 86400000;
        let toDateString = parseNewDateToDDMMYYY(new Date(toDate));
        console.log('toDateString ', toDateString)

        props.actions.fromDateAction(StartDate);
        props.actions.toDateAction(toDateString);
        props.actions.projectReportAction(true);
        history.push("/results");
    }

    return (
        <div className={classes.eventBoxStyle}>
            <div className={classes.buttonContainer} onClick={() => handleResultsRedirect(mostCommonDate)}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>Date: </small>
                    {mostCommonDate}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>{props.detail.measurements[0].stopValue !== null ? 'Time: ' : 'Events: '}</small>
                    {findEventsWithDate(props.detail.measurements, mostCommonDate)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>Effect in €: </small>
                    € {findPrice(findEventsWithDate(props.detail.measurements, mostCommonDate), props.detail)}
                </div>
                <div style={{ width: '70px', color: 'white', background: (props.detail.isProfit ? "#28a745" : "#dc3545"), paddingTop: '8px', paddingBottom: '8px', borderRadius: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold" }}>
                    High
                </div>
            </div>
            <Divider></Divider>
            <div className={classes.averageContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    {/* {console.log(averagePrice)} */}
         

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                <small>{props.detail.measurements[0].stopValue !== null ? 'Average time: ' : 'Average events: '}</small>
                    {averagePrice.average.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>Effect in €: </small>
                    € {averagePrice.price}
                </div>
                <div style={{ width: '70px', color: 'white', background: 'grey', paddingTop: '8px', paddingBottom: '8px', borderRadius: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold" }}>
                    Average
                </div>
            </div>
            <Divider></Divider>
            <div className={classes.buttonContainer} onClick={() => handleResultsRedirect(leastCommonDate)}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>Date: </small>
                    {leastCommonDate}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>{props.detail.measurements[0].stopValue !== null ? 'Time: ' : 'Events: '}</small>
                    {findEventsWithDate(props.detail.measurements, leastCommonDate)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <small>Effect in €: </small>
                    € {findPrice(findEventsWithDate(props.detail.measurements, leastCommonDate), props.detail)}
                </div>
                <div style={{ width: '70px', color: 'white', background: (props.detail.isProfit ? "#dc3545" : "#28a745"), paddingTop: '8px', paddingBottom: '8px', borderRadius: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold" }}>
                    Low
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({

    bar: {
        [theme.breakpoints.up('lg')]: {
            minWidth: "70%",
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: "60%",
        },
        [theme.breakpoints.down('md')]: {
            minWidth: "65%",
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: "70%",
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingRight: '8px',
        paddingLeft: '8px',
        '&:hover': {
            background: '#e8e6e6',
            cursor: 'pointer',
        }
    },
    averageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingRight: '8px',
        paddingLeft: '8px',

    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexFlow: 'space-around',
        borderRadius: "8px",
        gap: "20px 30px",
        [theme.breakpoints.up('lg')]: {
            maxWidth: "50%",
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: "60%",
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
        },
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px"
    },
    titleTextStyle: {
        display: "flex",
        marginLeft: "20px",
    },
    eventBoxStyle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    menuItem: {
        color: '#1D3557',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '100% !important',
            marginBottom: '15px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
            fontSize: '1.2rem',
        },
        display: "flex",
        alignItems: "center",
    },

    iconStyle: {
        verticalAlign: "-8px",
        marginRight: "4px"
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
        },
    },
    linkButton: {
        width: '100% !important',
        minWidth: '100% !important',
        [theme.breakpoints.down('sm')]: {
            minHeight: "46.8px",
            height: "90%",
            padding: "10px",
            fontSize: "1rem",
        },
        [theme.breakpoints.up('lg')]: {
            height: "40px",
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1.2rem",
        },
        background: "#C3996B !important",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hstyle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white',
        whiteSpace: 'nowrap'
    },
    titleStyle: {
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        color: 'white'
    },
    headerLeft: {
        alignItems: 'center',
        display: 'flex',
        width: '33%'
    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center'
    },
    headerRight: {
        width: '33%',
        display: 'flex',
        justifyContent: 'end',
        color: 'white'
    },
    arrowBack: {
        padding: '15px',
        cursor: 'pointer'
    },
    taplrLogo: {
        width: '150px',
        cursor: 'pointer'
    }
}));