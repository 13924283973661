import React, { useState, useEffect } from 'react';
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Tooltip,
  Legend,
} from 'devextreme-react/pie-chart';

export default function EventChart(props) {

  useEffect(() => {
  }, [])

  const customizePoint = (pointInfo) => {
    return { color: pointInfo.data.color };
  };
  const customizeText = (text) => {
    if (text.point.data.minuteamount !== undefined) {
      return text.point.data.minuteamount;
    } else {
      return text.originalValue;

    }
    // return   {color: pointInfo.data.color};
  };

  const customizeTooltip = (pointinfo) => {
    let detailName = pointinfo.argument;
    let htmlText = '';
    htmlText += '<b style="text-style: underline">' + detailName + '</b><br/>'
    // if(seriesName === 'Total'){
    if (pointinfo.point.data !== null) {
      htmlText += '<div>' + pointinfo.percentText + ' - ' + (pointinfo.point.data.type === 2 ? pointinfo.point.data.minuteamount : pointinfo.value + ' events') + '</div>';
    }
    return {
      html: htmlText,
    };
  }
  const onLegendClick = ({ points }) => {
    points[0].isVisible() ? points[0].hide() : points[0].show();

  }


  return (
    <PieChart id="pie" dataSource={props.data} palette="Bright" title={props.title} customizePoint={customizePoint} onLegendClick={onLegendClick}>
      <Tooltip enabled={true} customizeTooltip={customizeTooltip}></Tooltip>
      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top" ></Legend>
      <Series argumentField="detailDescription" valueField="amount"      >
        <Label visible={true} customizeText={customizeText}>
          <Connector visible={true} width={1} />
        </Label>
      </Series>
      <Export enabled={true} />
    </PieChart>

  );
}
