import React, { Suspense } from "react";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './app';
import store from "./Reducers/index";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const Loader = () => <div>loading...</div>;

let persistor = persistStore(store);

render(

    <Provider store={store}>
        <Suspense fallback={<Loader />}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Suspense>
    </Provider>,

    document.getElementById('root')
);

