import React, { useState, useEffect } from "react";
import TitlePage from "../../Shared/TitlePage";
import TableManage from "./Components/TableManage";
import { dataService } from "../../../Services/data/data.service";
import { fromNewDateFormatToYYYYMMDDSlash } from "../../../Utils/dateHelper";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import DateSelectionFuture from "../../Shared/DateSelection/DateSelectionFuture";
import { makeStyles } from "@material-ui/core/styles";
import { ProjectFilterTypes } from '../../../Config/constants';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function ManagePage(props) {
    var today = new Date();
    var nextMonth = new Date();
    nextMonth.setDate(nextMonth.getDate() + 30);
    const history = useHistory();
    const [firstLoad, setFirstLoad] = useState(0);
    const [FromDate, setFromDate] = useState(today);
    const [ToDate, setToDate] = useState(nextMonth);
    const GetUserID = props.Login.userid;
    const classes = useStyles();
    const [types, setTypes] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filterOn, setFilterOn] = useState(false);
    const [measurementRows, setMeasurementRows] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(8);

    const theme = createTheme({
        palette: {
            neutral: {
                main: '#C3996B',
                contrastText: '#fff',
            },
        },
    });
    useEffect(() => {
        dataService.getTypes().then(results => {
            setTypes(results.sort((a, b) => a.description.localeCompare(b.description)));
        })
    }, [])
    const setTheFromDate = (event) => {
        if (event !== FromDate) {
            setFromDate(event);
            setDates(event, ToDate);
        }
    }
    const setTheToDate = (event) => {
        if (event !== ToDate) {
            setToDate(event);
            setDates(FromDate, event);
        }
    }

    const setDates = (fromDate, toDate) => {
        setToDate(toDate);
        setFromDate(fromDate);
        sendApi(fromDate, toDate);
    }

    const getEditedDetails = (details) => {
        return details.map(x => ({ description: x.description, typeId: x.typeId, id: x.id, edit: false, sequence: x.sequence, color: x.color })).sort((a, b) => b.sequence + a.sequence);
    }

    const sendApi = (fromDate, toDate) => {
        // eslint-disable-next-line
        let TempMeasurementRows;
        dataService.getMeasurementForModify(GetUserID, fromNewDateFormatToYYYYMMDDSlash(new Date(fromDate)), fromNewDateFormatToYYYYMMDDSlash(new Date(toDate))).then(results => {
            TempMeasurementRows = (results.map(
                x => x.details.map(
                    y => ({
                        details: getEditedDetails(x.details),
                        projectId: x.projectId,
                        toDate: x.toDate,
                        fromDate: x.fromDate,
                        Id: y.id,
                        typeId: y.typeId,
                        companyId: x.companyId,
                        qrCode: x.qrCode,
                        companyName: x.companyName,
                        projectName: x.projectName,
                        resourceName: x.resourceName,
                        description: y.description,
                        sequence: y.sequence
                    }))))
            let sort = results.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.fromDate) - new Date(a.fromDate);
            });
            
            setMeasurementRows(sort)
            setProjects(sort);
        })
    }

    if (firstLoad === 0) {
        sendApi(FromDate, ToDate);
        setFirstLoad(1);
    }

    const filter = [
        { number: 1, column: "companyName", sortOrder: "ASC" },
        { number: 2, column: "companyName", sortOrder: "DESC" },
        { number: 3, column: "projectName", sortOrder: "ASC" },
        { number: 4, column: "projectName", sortOrder: "DESC" },
        { number: 5, column: "resourceName", sortOrder: "ASC" },
        { number: 6, column: "resourceName", sortOrder: "DESC" },
        { number: 7, column: "fromDate", sortOrder: "ASC" },
        { number: 8, column: "fromDate", sortOrder: "DESC" },
    ]

    const dynamicSorting = (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        let asc = false;
        let desc = false;
        /* SC: Filter now works both ways (ASC, DESC) */
        if (filter.find(x => x.number === selectedFilter).column === property) {
            if (filter.find(x => x.number === selectedFilter).sortOrder === 'ASC') {
                desc = true;
                asc = false;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'DESC').number);
            } else {
                desc = false;
                asc = true;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'ASC').number);
            }
        } else {
            desc = false;
            asc = true;
            setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'ASC').number);
        }
        if (asc === true) {
            return function (a, b) {
                var result = (a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
                return result * sortOrder;
            }
        } else {
            return function (a, b) {
                var result = (a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1);
                return result * sortOrder;
            }
        }
    }

    const handleSorting = (sortType) => {
        let tempMeasurementRows = [...projects];
        setProjects(tempMeasurementRows.sort(dynamicSorting(sortType)));
    }


    const toggleFilter = () => {
        if (filterOn) {
            setFilters([]);
        }
        setFilterOn(!filterOn);
    }

    const updateFilters = (type, event) => {
        let tempFilters = [...filters];
        if (tempFilters.some(x => x.type === type)) {
            let index = tempFilters.indexOf(tempFilters.find(x => x.type === type));
            tempFilters.splice(index, 1);
            tempFilters.unshift({ type: type, filterValue: event.target.value });
        }
        else {
            tempFilters.unshift({ type: type, filterValue: event.target.value });
        }

        setFilters(tempFilters);
        filterProjects(tempFilters);
    }

    const filterProjects = (thisFilters) => {
        let varFilteredProjects = [...measurementRows]
        var i;
        for (i = 0; i < thisFilters.length; i++) {
            let filter = thisFilters[i]
            switch (filter.type) {
                case ProjectFilterTypes.CUSTOMER:
                    varFilteredProjects = varFilteredProjects.filter(x => x.companyName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                case ProjectFilterTypes.PROJECT_NAME:
                    varFilteredProjects = varFilteredProjects.filter(x => x.projectName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                case ProjectFilterTypes.LOCATION_NAME:
                    varFilteredProjects = varFilteredProjects.filter(x => x.resourceName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                default:
                    varFilteredProjects = varFilteredProjects.filter(x => x.companyName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
            }
        }
        setProjects(varFilteredProjects)
    }

    const navigateTo = (destination) => {
        history.push(destination);
    }

    return (
        <div>
            <TitlePage Text="Manage your projects" backbutton={true} {...props} />
            <div className={classes.container}>
                <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={1}
                    alignItems="center">
                    <Grid item xs={12} >
                        <div className={classes.dateAndCreateDiv}>
                            <div className={classes.dateSelectionDiv}>
                                <p className={classes.activeFor}>Active between: </p>
                                <DateSelectionFuture setDates={setDates} FromDate={FromDate} ToDate={ToDate} setTheFromDate={setTheFromDate} setTheToDate={setTheToDate} />
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Button size='large' className={classes.linkButton} color='neutral' variant="contained" onClick={() => navigateTo('/create')}
                                    >
                                        <span className={classes.iconItem}>
                                            <AddIcon></AddIcon>
                                        </span>Create a project
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <TableManage toggleFilter={toggleFilter} filters={filters} handleSorting={handleSorting} filterOn={filterOn} filterProjects={filterProjects} updateFilters={updateFilters} types={types} rows={projects} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default ManagePage;

const useStyles = makeStyles({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    titleTextStyle: {
        marginLeft: "20px",
    },
    titleSection: {
        width: "100%",
    },
    titleStyle: {
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#FFFFFF",
        justifyContent: "space-between"
    },
    dateAndCreateDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        flexWrap: 'wrap',


    },
    activeFor: {
        color: "#696969",
        fontSize: "15px",
    },
    backIcon: {
        padding: "20px",
        cursor: "pointer"
    },
    iconItem: {
        display: 'flex !important',
        alignItems: 'center !important'
    }

});