import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import * as Actions from '../../../../Reducers/actions'
import { useHistory } from 'react-router-dom';

function EditExternalApiButton(props) {
    const history = useHistory();
    var today = new Date();
    
    const SaveAndRedirect = () => {
        props.actions.externalApiNameAction(props.data.externalApiName)
        props.actions.externalApiDescriptionAction(props.data.externalApiDescription)
        props.actions.externalApiKeyAction(props.data.externalApiKey)
        var apiFormatParsed = JSON.parse(props.data.externalApiFormat)
        var apiFormat = apiFormatParsed.map((item, index) => ({ Id: index +1 , itemDescription: item.Description, edit: false, itemName: item.Name, UserID: props.Login.userid, InsertedOn: today }))
        props.actions.externalApiItemsAction(apiFormat)
        props.actions.externalApiIdAction(props.data.externalApiId)
        history.push("/modifyapi")
    }

    return (
        <div>
            <IconButton onClick={SaveAndRedirect} size='large' style={{ borderRadius: 0 }}>
                <EditIcon fontSize="inherit" />
            </IconButton>
        </div>
    )
}

const mapStateToProps = state => ({
    lists: state.lists,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
))(EditExternalApiButton);