import React, { useState } from "react";
import { ChromePicker } from 'react-color';
import { makeStyles } from "@material-ui/core/styles";

function ColorPicker(props) {
    const classes = useStyles();
    const [openPicker, setOpenPicker] = useState(false);
    const [usedColor, setColor] = useState(props.color ? props.color : "#000");

    const openColorPicker = () => {
        setOpenPicker(true)
    }

    const closeColorPicker = () => {
        setOpenPicker(false)
    }

    const handleChange = (data) => {
        setColor(data.hex)
        props.action(data.hex, props.id)
    }

    return (
        <div>
            <div className={classes.swatch} onClick={openColorPicker}>
                <div className={classes.color} style={{ backgroundColor: usedColor }} />
            </div>
            {openPicker ? <div className={classes.popover}>
                <div className={classes.cover} onClick={closeColorPicker} />
                <ChromePicker disableAlpha color={usedColor} onChange={handleChange} />
            </div> : null}
        </div>
    )

}

export default ColorPicker

const useStyles = makeStyles((usedColor) => ({
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}));