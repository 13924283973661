import React, { useEffect, useState } from 'react';
import TextfieldInput from '../../Shared/TextField';
import TitlePage from '../../Shared/TitlePage';
import DateSelectionFuture from "../../Shared/DateSelection/DateSelectionFuture";
import TableDragResources from '../../Shared/Tables/TableDragResources';
import DescriptionItem from '../../Shared/DescriptionItem';
import DropdownCompanyAdd from './Components/DropdownCopmanyAdd';
import DropdownResources from '../../Shared/Dropdowns/DropdownResources';
import ButtonNormalText from '../../Shared/Buttons/ButtonNormalText';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';

function DuplicatePage(props) {

    const classes = useStyles();
    var today = new Date();
    const [FromDate, setFromDate] = useState(today);
    const [ToDate, setToDate] = useState(today);
    const [ResourceName, setResourceName] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState(null);
    const [ProjectName, setProjectName] = useState("");
    const [companies, setCompanies] = useState([]);
    const [resources, setResources] = useState([]);
    const [projectrows, setProjectRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [defopen, setDefOpen] = useState(false);
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [helperTextProjectName, setHelperTextProjectName] = useState(null);
    const [helperTextCompany, setHelperTextCompany] = useState(null);
    const [helperTextResourceDescription, setHelperTextResourceDescription] = useState(null);
    const [errorProjectName, setErrorProjectName] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [errorResourceDescription, setErrorResourceDescription] = useState(false);
    const [emptyDetails, setEmptyDetails] = useState(false);

    useEffect(() => {
        dataService.getCompanies(GetUserID).then(results => {
            setCompanies(results)
        })
        dataService.getResources(GetUserID).then(results => {
            setResources(results)
        });
        setProjectName(props.projectCopy.projectName);
        setCompanyName(props.projectCopy.company);
        setCompanyId(props.projectCopy.companyId);
        setResourceName(props.projectCopy.resource);

        let fDate = "";
        if (props.projectCopy.fromDate != null) {
            fDate = new Date(props.projectCopy.fromDate);
        }
        else {
            fDate = new Date(today);
        }
        fDate.setHours(12, 0, 0, 0);
        setFromDate(fDate);
        let tDate = "";
        if (props.projectCopy.toDate != null) {
            tDate = new Date(props.projectCopy.toDate);
        }
        else {
            tDate = new Date(today);
        }
        tDate.setHours(12, 0, 0, 0);
        setToDate(tDate);

        let tempProjectRows = [];
        if (props.projectCopy.details.some(x => x.id)) {
            tempProjectRows = props.projectCopy.details.map(x => ({ color: x.color, typeId: x.typeId, type: x.typeId, description: x.description, Id: x.id, edit: false, sequence: x.sequence, dataListId: x.dataListId, externalApiId: x.externalApiId }));
        } else {
            tempProjectRows = props.projectCopy.details.map(x => ({ ...x }));
        }
        tempProjectRows.sort((a, b) => a.sequence - b.sequence);
        setProjectRows(tempProjectRows);
    }, [GetUserID, props.projectCopy.company, props.projectCopy.companyId, props.projectCopy.resource, props.projectCopy.fromDate, props.projectCopy.toDate, props.projectCopy.details])

    const setProject = (event) => {
        setProjectName(event.target.value);
    }

    const setTheFromDate = (event) => {
        setFromDate(event);
    }

    const setTheToDate = (event) => {
        setToDate(event);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const handleDialogCloseDef = () => {
        setDefOpen(false);
    }

    const setNewCompanyFunction = (event) => {
        dataService.saveCompany(event.inputValue, GetUserID).then(results => {
            let newCompany = { id: results.id, name: results.name };
            let tempCompanies = [...companies];
            tempCompanies.push(newCompany);
            setCompanies(tempCompanies);
            setCompanyId(results.id);
            setCompanyName(results.name);
        })
    }

    const setCompany = (event) => {
        if (event.inputValue) {
            if (companies.some(x => x.name === event.inputValue)) {
                setCompanyId(companies.find(x => x.name === event.inputValue).id);
                setCompanyName(event.inputValue);
            }
        }
        else {
            if (event.target.textContent) {
                if (companies.some(x => x.name === event.target.textContent)) {
                    setCompanyId(companies.find(x => x.name === event.target.textContent).id);
                    setCompanyName(event.target.textContent);
                }
            }
            else {

                if (companies.some(x => x.name === event.target.value)) {
                    setCompanyId(companies.find(x => x.name === event.target.value).id);
                }
                setCompanyName(event.target.value);
            }
        }

    }

    const setResource = (event) => {
        if (event.inputValue) {
            setResourceName(event.inputValue);
        }
        else {
            if (event.target.textContent) {
                setResourceName(event.target.textContent);
            }
            else {
                setResourceName(event.target.value);
            }
        }
    }

    const sendToApi = () => {
        if (ProjectName === "" || ResourceName === "" || CompanyId === "" || CompanyName === "" || FromDate === "" || ToDate === "" || ResourceName == null || FromDate == null || ToDate == null) {
            if (ProjectName === "") {
                setHelperTextProjectName("Please enter a project name.");
                setErrorProjectName(true);
            }
            else {
                setHelperTextProjectName(null);
                setErrorProjectName(false);
            }
            if (ResourceName === "" || ResourceName === null || ResourceName === undefined) {
                setHelperTextResourceDescription("Please Select or enter a Location.");
                setErrorResourceDescription(true);
            }
            else {
                setHelperTextResourceDescription(null);
                setErrorResourceDescription(false);
            }
            if (CompanyId === "" || CompanyId === null || CompanyName === "" || CompanyName === null || CompanyName === undefined) {
                setHelperTextCompany("Please Select or Create a Company.");
                setErrorCompany(true);
            }
            else {
                setHelperTextCompany(null);
                setErrorCompany(false);
            }
        }
        else {
            setHelperTextResourceDescription(null)
            setErrorResourceDescription(false)
            setHelperTextCompany(null)
            setErrorCompany(false)
            setHelperTextProjectName(null)
            setErrorProjectName(false)
            setEmptyDetails(false)
            if (projectrows.length === 0 || projectrows.find(x => x.Id).edit === true) {
                setEmptyDetails(true)
            }
            else {
                let i = 1;
                let projectDetails = projectrows.map(x => ({ description: x.description, typeId: x.type, type: x.type, sequence: i++, color: x.color, dataListId: x.dataListId, HasComment: x.comment, externalApiId: x.externalApiId, price: x.price, isProfit: x.isProfit })).sort((a, b) => b.sequence + a.sequence);

                dataService.createNewProject(GetUserID, ProjectName, ResourceName, CompanyId, CompanyName, FromDate, ToDate, projectDetails).then(result => {
                    if (result.success === true) {
                        toast.success('Project was saved successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

                    } else {
                        toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

                    }
                    let tempProjectName = ProjectName;
                    let tempFromDate = FromDate;
                    let tempToDate = ToDate;
                    let tempCompanyName = CompanyName;
                    let tempCompanyId = CompanyId;
                    let tempResourceName = ResourceName;
                    let tempProjectRows = projectrows.map(x => ({ ...x }));

                    props.actions.projectIdAction(result.createmodel.projectID)
                    props.actions.projectQrAction(result.createmodel.qrCode)
                    props.actions.projectNameAction(tempProjectName)
                    props.actions.companyAction(tempCompanyName);
                    props.actions.companyIdAction(tempCompanyId);
                    props.actions.resourceAction(tempResourceName);
                    props.actions.fromDateAction(tempFromDate);
                    props.actions.toDateAction(tempToDate);
                    props.actions.detailsAction(tempProjectRows);
                });
                history.push("/modify");
            }

        }
    }

    const setDates = (fromDate, toDate) => {
        setTheToDate(toDate);
        setTheFromDate(fromDate);
    }

    const save = (newProjectrows) => {
        setProjectRows(newProjectrows);
    }
    return (
        <div>

            <TitlePage Text="Create project from an existing one" backbutton={true} {...props} />

            <div className={classes.container}>
                <div className={classes.projectOptions}>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Project Name" />
                        <TextfieldInput value={ProjectName} action={setProject} label={ProjectName ? " " : "Project"} InputLabelProps={{ shrink: false }} helperText={helperTextProjectName} error={errorProjectName} />
                    </div>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Company name" />
                        <DropdownCompanyAdd companies={companies} value={CompanyName} action={setCompany} action2={setNewCompanyFunction} helperText={helperTextCompany} error={errorCompany} />
                    </div>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Location" />
                        <DropdownResources resources={resources} value={ResourceName} action={setResource} helperText={helperTextResourceDescription} error={errorResourceDescription} />
                    </div>
                </div>

                <Grid item xs={12}>
                    <DescriptionItem Text="Active between:" />
                    <DateSelectionFuture setDates={setDates} FromDate={FromDate} ToDate={ToDate} setTheFromDate={setTheFromDate} setTheToDate={setTheToDate} />
                    <Divider style={{ marginBottom: "1rem", marginTop: "1rem" }}></Divider>
                </Grid>
                {emptyDetails === true ?
                    <div>
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableDragResources {...props} rows={projectrows} setRows={setProjectRows} makeSave={save} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one item.</p>
                    </div>
                    :
                    <div>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableDragResources {...props} rows={projectrows} setRows={setProjectRows} makeSave={save} />
                        </Grid>
                    </div>}
                <div className={classes.buttonContainer}>
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Some fields are empty.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonNormalText content="Close" onClick={handleDialogClose}>Close</ButtonNormalText>
                </DialogActions>
            </Dialog>


            <Dialog
                open={defopen}
                onClose={handleDialogCloseDef}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please make atleast one Description item.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonNormalText content="Close" onClick={handleDialogCloseDef}>Close</ButtonNormalText>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DuplicatePage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    buttonContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            width: '100%'
        }
    },
    projectOption: {
        [theme.breakpoints.up('md')]: {
            width: "100%",
            marginRight: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));