import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import { dataService } from '../../../Services/data/data.service';
import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';

function DropdownType(props) {
  const [select, setSelect] = useState("");
  const [list, setList] = useState([]);
  const [apis, setapis] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [openApi, setOpenApi] = useState(false);

  useEffect(() => {
    dataService.getLists(props.Login.userid).then(result => {
      setList(result.sort((a, b) => a.name.localeCompare(b.name)));
    });
    
    dataService.getApis(props.Login.userid).then(result => {
      setapis(result.sort((a, b) => a.name.localeCompare(b.name)));
    });

    if (props.row !== undefined) {
      setOpenList(true);
      setOpenApi(true);
      setSelect(props.row);
    }
    // eslint-disable-next-line 
  }, [])

  const handleSelect = (event) => {
    setSelect(event.target.value);
    props.action(event, props.id);
  };

  const handleOpenList = () => {
    setOpenList(!openList);
  };

  const handleOpenApi = () => {
    setOpenApi(!openApi);
  };

  return (
    <FormControl fullWidth >
      {select ? null : <InputLabel shrink={false}>Type</InputLabel>}
      <Select
        // eslint-disable-next-line
        style={{ float: 'left', display: 'flex', justifyContent: 'start', textAlign: 'left' }}
        value={select}
        disabled={props.disabled}
        onChange={(e) => handleSelect(e)}
        MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
      >
        <ListSubheader>Type's</ListSubheader>
        <MenuItem value={1}>Counter</MenuItem>
        <MenuItem value={3}>Free text</MenuItem>
        <MenuItem value={5}>Scanner</MenuItem>
        <MenuItem value={2}>Stopwatch</MenuItem>
        <MenuItem value={6}>Take Picture</MenuItem>

        <ListSubheader>List
          <IconButton size={'small'} onClick={handleOpenList}>
            <ArrowDropDownIcon />
          </IconButton>
        </ListSubheader>
        {openList && list.map((row, index) => (
          <MenuItem onClick={(e) => props.actionDataList(props.id, row)} value={row.name} index={index} >{"Dropdown - " + row.name}</MenuItem>
        ))}
        
        <ListSubheader>Api
          <IconButton size={'small'} onClick={handleOpenApi}>
            <ArrowDropDownIcon />
          </IconButton>
        </ListSubheader>
        {openApi && apis.map((row, index) => (
          <MenuItem onClick={(e) => props.actionExternalApi(props.id, row)} value={row.name} index={index} >{"Api - " + row.name}</MenuItem>
        ))}
      </Select >
    </FormControl>
  );
}

export default DropdownType
