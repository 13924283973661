import projectCopy from "./ProjectCopy/ProjectCopy.js";
import Login from "./Login/Login";
import listCopy from "./ListCopy/ListCopy.js";
import externalApiCopy from "./ExternalAPiCopy/ExternalApiCopy.js";
import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage
};

const rootReducers = combineReducers({
    projectCopy,
    Login,
    listCopy,
    externalApiCopy
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export default store;