import React from 'react';
import TextField from '@mui/material/TextField';

function TextfieldInput(props) {
    return (
        <TextField id="outlined-basic"
            label={props.label}
            type={props.type}
            variant="outlined"
            value={props.value}
            error={props.error}
            helperText={props.helperText}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps} 
            onClick={props.onClick}
            fullWidth={true}
            multiline={props.multiline}
            rows={props.rows}
            onChange={(event) => props.action(event)} />
            
    )
}


export default TextfieldInput;