import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DropdownType from "../Dropdowns/DropdownType";
import ButtonNormalText from "../Buttons/ButtonNormalText";
import TextField from '@mui/material/TextField';
import { TableContainer, TableRow } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './TableStyle.css';
import Radio from '@mui/material/Radio';
import { dataService } from "../../../Services/data/data.service";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ColorPicker from "../ColorPicker";
import { controlTypes } from "../../../Config/constants";
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';


function TableDragResources(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [types, setTypes] = useState([]);
    const [lists, setLists] = useState([]);
    const [apis, setApis] = useState([]);
    const [expandTable, setExpandTable] = useState(false);

    useEffect(() => {
        if (types.length === 0 || types === undefined) {
            dataService.getTypes().then(results => {
                setTypes(results);
            });
        }

        dataService.getLists(props.Login.userid).then(result => {
            setLists(result.sort((a, b) => a.name.localeCompare(b.name)));
        });

        dataService.getApis(props.Login.userid).then(result => {
            setApis(result.sort((a, b) => a.name.localeCompare(b.name)));
        });
        // eslint-disable-next-line 
    }, []);

    const handleDialogOpen = () => {
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const selectType = (row) => {
        switch (row.type) {
            case 1: return "Counter";
            case 2: return "Stopwatch";
            case 3: return "Text";
            case 4:
                if (lists !== undefined && lists.length > 0) {
                    return lists.find(x => x.id === row.dataListId).name;
                } break;
            case 5: return "Scanner";
            case 6: return "Picture";
            case 7:
                if (apis !== undefined && apis.length > 0 && row.externalApiId !== null) {
                    return apis.find(x => x.id === row.externalApiId).name;
                } break;
            // eslint-disable-next-line 
        }
    }

    const selectTypeId = (row) => {
        switch (row.type) {
            case 1: return 1;
            case 2: return 2;
            case 3: return 3;
            case 4:
                if (lists !== undefined && lists.length > 0) {
                    return lists.find(x => x.id === row.dataListId).name;
                } break;
            case 5: return 5;
            case 6: return 6;
            case 7:
                if (apis !== undefined && apis.length > 0 && row.externalApiId !== null) {
                    return apis.find(x => x.id === row.externalApiId).name;
                } break;
            // eslint-disable-next-line 
        }
    }

    const setType = (event, id) => {
        if (event.target.value < 9) {
            let tempArray = props.rows.map(x => ({ ...x }))
            if (event.target.value !== (controlTypes.CAMERA || controlTypes.COUNTER || controlTypes.SCANNER)) {
                tempArray.find(x => x.Id === id).comment = false;
            }
            if (tempArray.find(x => x.Id === id) !== undefined) {
                tempArray.find(x => x.Id === id).type = event.target.value;
                tempArray.find(x => x.Id === id).typeId = event.target.value;
                if (tempArray.find(x => x.Id === id).color === undefined) {
                    switch (event.target.value) {
                        case 1:
                            tempArray.find(x => x.Id === id).color = "#faead9";
                            tempArray.find(x => x.Id === id).name = "Counter";
                            break;
                        case 2:
                            tempArray.find(x => x.Id === id).color = "#b7cbec";
                            tempArray.find(x => x.Id === id).name = "Stopwatch";
                            break;
                        case 3:
                            tempArray.find(x => x.Id === id).color = "#e1ffe2";
                            tempArray.find(x => x.Id === id).name = "Text";
                            break;
                        case 5:
                            tempArray.find(x => x.Id === id).color = "#e1ffe2";
                            tempArray.find(x => x.Id === id).name = "Scanner";
                            break;
                        case 6:
                            tempArray.find(x => x.Id === id).color = "#84ff69";
                            tempArray.find(x => x.Id === id).name = "Picture";
                            break;
                        // eslint-disable-next-line 
                    }
                }
            } else {
                tempArray.find(x => x.edit).type = event.target.value;
                tempArray.find(x => x.edit).typeId = event.target.value;
                if (tempArray.find(x => x.Id === id).color === undefined) {
                    switch (event.target.value) {
                        case 1:
                            tempArray.find(x => x.Id === id).color = "#faead9";
                            tempArray.find(x => x.Id === id).name = "Counter";
                            break;
                        case 2:
                            tempArray.find(x => x.Id === id).color = "#b7cbec";
                            tempArray.find(x => x.Id === id).name = "Stopwatch";
                            break;
                        case 3:
                            tempArray.find(x => x.Id === id).color = "#e1ffe2";
                            tempArray.find(x => x.Id === id).name = "Text";
                            break;
                        case 5:
                            tempArray.find(x => x.Id === id).color = "#e1ffe2";
                            tempArray.find(x => x.Id === id).name = "Scanner";
                            break;
                        case 6:
                            tempArray.find(x => x.Id === id).color = "#84ff69";
                            tempArray.find(x => x.Id === id).name = "Picture";
                            break;
                        // eslint-disable-next-line 
                    }
                }
            }
            props.setRows(tempArray);
        }
    }

    const setTypeDataList = (id, DataListrow) => {
        let tempArray = props.rows.map(x => ({ ...x }))
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).type = 4;
            tempArray.find(x => x.Id === id).typeId = 4;
            tempArray.find(x => x.Id === id).color = "#fab1b1";
            tempArray.find(x => x.Id === id).dataListId = DataListrow.id;
            tempArray.find(x => x.Id === id).name = "List - " + DataListrow.name;
        } else {
            tempArray.find(x => x.edit ).type = 4;
            tempArray.find(x => x.edit ).typeId = 4;
            tempArray.find(x => x.Id === id).dataListId = "#fab1b1";
            tempArray.find(x => x.Id === id).name = "List - " + DataListrow.name;
        }
        props.setRows(tempArray);
    }

    const setTypeExternalApi = (id, ExternalApirow) => {
        let tempArray = props.rows.map(x => ({ ...x }))
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).type = 7;
            tempArray.find(x => x.Id === id).typeId = 7;
            tempArray.find(x => x.Id === id).color = "#52ed47";
            tempArray.find(x => x.Id === id).externalApiId = ExternalApirow.id;
            tempArray.find(x => x.Id === id).name = "Api - " + ExternalApirow.name;
        } else {
            tempArray.find(x => x.edit ).type = 7;
            tempArray.find(x => x.edit ).typeId = 7;
            tempArray.find(x => x.Id === id).color = "#52ed47";
            tempArray.find(x => x.Id === id).externalApiId = ExternalApirow.id;
            tempArray.find(x => x.Id === id).name = "Api - " + ExternalApirow.name;
        }
        props.setRows(tempArray);
    }

    const setDescription = (event, id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).description = event.target.value;
        } else {
            tempArray.find(x => x.edit ).description = event.target.value;
        }
        props.setRows(tempArray);
    }
    const setPrice = (event, id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id) !== undefined) {
            if(event.target.value === ''){
                tempArray.find(x => x.Id === id).price = '';

            }else{
                tempArray.find(x => x.Id === id).price = parseFloat(event.target.value);

            }
        } else {
            if(event.target.value === ''){
                tempArray.find(x => x.edit).price = '';

            }else{
                tempArray.find(x => x.edit).price = parseFloat(event.target.value);

            }
        }
        props.setRows(tempArray);
    }
    const handleCostProfit = (event, id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).costProfit = event;
            if(event === '+'){
                tempArray.find(x => x.Id === id).isProfit = true;
            }else{
                tempArray.find(x => x.Id === id).isProfit = false;
            }
        } else {
            tempArray.find(x => x.edit).costProfit = event;
            if(event === '+'){
                tempArray.find(x => x.Id === id).isProfit = true;
            }else{
                tempArray.find(x => x.Id === id).isProfit = false;
            }
        }
        props.setRows(tempArray);
    };


    const GenerateID = () => {
        let tempArray = props.rows.map(x => ({ ...x }));
        const ids = tempArray.map(object => {
            return object.Id;
        });
        const max = Math.max(...ids);
        if (tempArray.length === 0) {
            return 1;
        }
        else {
            let index = max;
            index++;
            return index;
        }
    }

    const editProjectRow = (id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        tempArray.find(x => x.Id === id).edit = true;
        props.setRows(tempArray);
    }

    const saveProjectRow = (id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id).description === "" || tempArray.find(x => x.Id === id).type === null) {
            handleDialogOpen();
        } else {
            tempArray.find(x => x.Id === id).edit = false;

            props.setRows(tempArray);
            props.makeSave(tempArray);
        }
    }

    const deleteProjectRow = (id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        let index = tempArray.indexOf(tempArray.find(x => x.Id === id));
        tempArray.splice(index, 1);
        props.setRows(tempArray);
        props.makeSave(tempArray);
    }

    const addRow = () => {
        var tempArray = props.rows.map(x => ({ ...x }))
        if (tempArray.find(x => x.type === null || x.description === "") === undefined) {
            let Rowid = { Id: GenerateID(), description: "", type: null, edit: true, name: "", sequence: GenerateID(), comment: false, newRow: true, price: 0, costProfit: '+', isProfit: true };
            let PrevId = GenerateID() - 1;
            if (tempArray.length !== 0) {
                if (tempArray.find(x => x.Id === PrevId).description !== "" && tempArray.find(x => x.Id === PrevId).type !== null) {
                    tempArray.find(x => x.Id === PrevId).edit = false;
                    tempArray.push(Rowid);
                    props.setRows(tempArray);
                }
                else {
                    tempArray.push(Rowid);
                    props.setRows(tempArray);
                }
            }
            else {
                tempArray.push(Rowid);
                props.setRows(tempArray);
            }
        }
    }

    const handleColor = (event, id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).color = event;
        } else {
            tempArray.find(x => x.edit).color = event;
        }
        props.setRows(tempArray);
    }

    const handleComment = (id) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).comment = !tempArray.find(x => x.Id === id).comment;
        } else {
            tempArray.find(x => x.edit).comment = !tempArray.find(x => x.Id === id).comment;
        }
        props.setRows(tempArray);
    }

    const MoveSequence = (source, destination) => {
        let tempArray = props.rows.map(x => ({ ...x }));
        tempArray.forEach(function (value, i) {
            value.sequence = i;
        });
        tempArray[source].sequence = -1;
        if (source > destination) {
            tempArray.filter(x => x.sequence >= destination && x.sequence !== -1 && x.sequence < source).forEach(x => {
                x.sequence++;
            });
        }
        else if (source < destination) {
            tempArray.filter(x => x.sequence <= destination && x.sequence !== -1 && x.sequence > source).forEach(x => {
                x.sequence--;
            });
        }

        tempArray.find(x => x.sequence === -1).sequence = destination;
        tempArray.sort((a, b) => a.sequence - b.sequence);
        props.setRows(tempArray);
        Shrink();
    }

    const Expand = () => {
        setExpandTable(true);
    }

    const Shrink = () => {
        setExpandTable(false);
    }

    function handleOnDragEnd(result) {
        if (result.destination) {
            MoveSequence(result.source.index, result.destination.index);
        }
        if (!result.destination) return;
    }

    return (
        <React.Fragment>
            <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
                <Table>
                    <thead className={classes.tableHead} style={{ backgroundColor: "#e0dce4" }}>
                        <tr style={{ height: '100%' }}>
                            <th style={{ paddingRight: '6px', paddingLeft: '6px' }}>#</th>
                            <th></th>
                            <th width="35%">Description</th>
                            <th width="20%">Type</th>
                            <th width="30%" style={{ display: 'flex', justifyContent: 'center', height: '100%', paddingTop: '5px', width: '100%' }}>€</th>
                            <th></th>
                            <th width="5%" style={{ whiteSpace: "nowrap" }}>Comment</th>
                            <th width="5%" className={classes.tableTd}>Color</th>
                            <th width="10%" className={classes.tableTd} colSpan={2} style={{ paddingLeft: '43px', paddingRight: '43px' }}>Action</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='droppable' >
                            {(provided) => (
                                <tbody align="center" className="tableContent" {...provided.droppableProps} ref={provided.innerRef}>
                                    {props.rows.map((row, index) => {
                                        return (
                                            <Draggable key={row.Id} draggableId={row.description + '_' + row.Id.toString()} className='droppable' index={index}>
                                                {(provided) => (
                                                    <tr ref={provided.innerRef} key={row.Id} className={classes.tableRow} {...provided.draggableProps}>
                                                        <td className={classes.indexTd} >
                                                            <div className={classes.listOrder}>
                                                                {index + 1}
                                                            </div>
                                                        </td>
                                                        <td onMouseDown={() => (Expand())} onMouseUp={() => (Shrink())} className={classes.dragTd}  {...provided.dragHandleProps}>
                                                            <DragIndicatorIcon />
                                                        </td>
                                                        <td className={classes.tableTd}>
                                                            {row.edit ?// eslint-disable-next-line
                                                                <TextField fullWidth label={row.description ? " " : "Description"} InputLabelProps={{ shrink: false }} style={{ width: '100%' }} className={classes.descriptionField} id="outlined-basic" type={"search"} variant="outlined" value={row.description.length > 0 ? row.description : ''} onChange={(event) => setDescription(event, row.Id)} /> :
                                                                <TextField fullWidth style={{ width: '100%' }} disabled value={row.description} />
                                                            }
                                                        </td>
                                                        <td className={classes.tableTd}>{row.edit ?
                                                            <DropdownType {...props} id={row.Id} types={types} row={selectTypeId(row)} actionDataList={setTypeDataList} actionExternalApi={setTypeExternalApi} action={setType} /> :// eslint-disable-next-line
                                                            types.length === 0 ? null : <TextField fullWidth disabled value={selectType(row)} />}
                                                        </td>
                                                        <td className={classes.tableTd}>
                                                            {row.edit ?// eslint-disable-next-line
                                                                <TextField fullWidth InputLabelProps={{ shrink: false }} style={{ width: '100%' }} className={classes.measurementPrice} id="outlined-basic" variant="outlined" value={row.price} onChange={(event) => setPrice(event, row.Id)} InputProps={{
                                                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                                    endAdornment: <InputAdornment position="end">{selectType(row) === 'Stopwatch' ? 'price/hour' : 'price/event'}</InputAdornment>,
                                                                    inputMode: 'numeric'
                                                                }} /> :
                                                                <TextField fullWidth style={{ width: '100%' }} disabled value={row.price} InputProps={{
                                                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                                    endAdornment: <InputAdornment position="end">{selectType(row) === 'Stopwatch' ? 'price/hour' : 'price/event'}</InputAdornment>,
                                                                    inputMode: 'numeric'
                                                                }} />

                                                            }
                                                        </td>
                                                        <td className={classes.tableTd}>
                                                            <ToggleButtonGroup size="small" value={row.costProfit} exclusive aria-label="costPrice" orientation="vertical">
                                                                <ToggleButton value="+" aria-label="Profit" style={{ height: '28px', width: '28px' }} onClick={() => handleCostProfit('+', row.Id)} disabled={row.edit ? false : true}>
                                                                    <Tooltip placement="left" title="Profit">
                                                                        <AddIcon />
                                                                    </Tooltip>
                                                                </ToggleButton>

                                                                <ToggleButton value="-" aria-label="Cost" style={{ height: '28px', width: '28px' }} onClick={() => handleCostProfit('-', row.Id)} disabled={row.edit ? false : true}>
                                                                    <Tooltip placement="left" title="Cost">
                                                                        <RemoveIcon />
                                                                    </Tooltip>
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </td>
                                                        <td className={classes.tableTd}>{row.edit && (row.type === controlTypes.COUNTER || row.type === controlTypes.SCANNER || row.type === controlTypes.CAMERA) ?
                                                            <Radio onClick={() => handleComment(row.Id)} checked={row.comment} /> :
                                                            <Radio disabled checked={row.comment} />}
                                                        </td>
                                                        <td className={classes.tableTd}>
                                                            {row.edit ?
                                                                <ColorPicker color={props.rows.find(y => y.Id === row.Id).color} id={row.Id} action={handleColor} /> :
                                                                <div className={classes.ColorPickerBorder}>
                                                                    <div className={classes.ColorPicker} style={{ background: props.rows.find(y => y.Id === row.Id).color }}>
                                                                    </div>
                                                                </div>}
                                                        </td>
                                                        <td className={classes.tableTd} align="center">
                                                            {row.edit ?
                                                                <IconButton style={{borderRadius: '0'}} aria-label="save" onClick={() => saveProjectRow(row.Id)} className={classes.editButton} size="large">
                                                                    <SaveIcon fontSize="inherit" />
                                                                </IconButton> :
                                                                <IconButton style={{borderRadius: '0'}} aria-label="edit" onClick={() => editProjectRow(row.Id)} className={classes.editButton} size="large">
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            }
                                                        </td>
                                                        <td className={classes.tableTd} align="center">
                                                            <IconButton style={{borderRadius: '0'}}  aria-label="delete" onClick={() => deleteProjectRow(row.Id)} className={classes.functionButton} color='error' size="large">
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </td>
                                                        {provided.placeholder}
                                                    </tr>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                    }
                                    {expandTable ?
                                        <tr><td style={{ height: "65px" }}></td></tr>
                                        : <React.Fragment></React.Fragment>}
                                    <TableRow className={classes.addRow} onClick={addRow} >
                                        <td className={classes.indexTd} style={{ paddingRight: '7px', paddingLeft: '7px' }}>
                                            -
                                        </td>
                                        <td className={classes.dragTd}>
                                            <DragIndicatorIcon />
                                        </td>
                                        <td className={classes.tableTd}>
                                            <FormControl fullWidth>
                                                <InputLabel>Description</InputLabel>
                                                <Select
                                                    IconComponent={() => (null
                                                    )} disabled />
                                            </FormControl>
                                        </td>
                                        <td className={classes.tableTd}>
                                            <DropdownType {...props} disabled />
                                        </td>
                                        <td className={classes.tableTd} >

                                            <TextField fullWidth style={{ width: '100%' }} disabled value={'0'} InputProps={{
                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                endAdornment: <InputAdornment position="end">price/event</InputAdornment>,
                                                inputMode: 'numeric'
                                            }} />

                                        </td>
                                        <td className={classes.tableTd}>
                                            <ToggleButtonGroup size="small" value={'+'} exclusive aria-label="costPrice" orientation="vertical">
                                                <ToggleButton value="+" aria-label="Profit" style={{ height: '28px', width: '28px' }}>
                                                    <AddIcon />
                                                </ToggleButton>
                                                <ToggleButton value="-" aria-label="Cost" style={{ height: '28px', width: '28px' }}>
                                                    <RemoveIcon />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </td>
                                        <td className={classes.tableTd}>
                                            <Radio disabled />
                                        </td>
                                        <td className={classes.tableTd}>
                                            <div className={classes.ColorPickerBorder}>
                                                <div className={classes.ColorPicker} >
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2} className={classes.tableTd} style={{ paddingLeft: '43px', paddingRight: '43px' }} align="center">
                                            <Fab size="medium">
                                                <AddIcon />
                                            </Fab>
                                        </td>
                                    </TableRow>
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Dialog
                        open={open}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Error"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Some fields are empty.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ButtonNormalText content="Close" onClick={handleDialogClose}>Close</ButtonNormalText>
                        </DialogActions>
                    </Dialog>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default TableDragResources;

const useStyles = makeStyles({
    tableContent: {
        paddingTop: "15px",
    },
    tableHead: {
        marginBottom: "15px",
    },
    addRow: {
        opacity: "0.3"
    },
    tableTd: {
        padding: "5px",
    },
    tableRow: {
        tableLayout: "fixed",
    },
    indexTd: {
        display: 'table-cell',
        borderRight: '2px solid rgb(224, 224, 224)',
        width: '25px',
        background: 'rgba(0, 0, 0, 0.54)',
        color: 'white',
        zIndex: '2',
    },
    emptydrag: {
        padding: '17px !important',
        border: '0'
    },
    listOrder: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    dragTd: {
        border: '0',
        width: '24px',
    },
    dragIcon: {
        float: 'left',
    },
    descriptionField: {
        width: "100%",
    },
    editButton: {
        fontSize: '2rem !important',
        color: '#0B4097 !important',
    },
    functionButton: {
        fontSize: '2rem !important',
    },
    ColorPickerBorder: {
        padding: '5px',
        width: '46px',
        height: '24px',
        borderRadius: '2px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        background: 'white',
        marginBottom: '5px'
    },
    ColorPicker: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
});