import * as types from './LoginActionTypes';

export const setLogin = (loginstate) => {
    return {
        type: types.SET_LOGIN,
        payload: loginstate
    };
};

export const setUserid = (userId) => {
    return {
        type: types.SET_USERID,
        payload: userId
    };
};



export const setUserName = (userName) => {
    return {
        type: types.SET_USERNAME,
        payload: userName
    };
};
export const setLastName = (lastName) => {
    return {
        type: types.SET_LASTNAME,
        payload: lastName
    };
};

export const setEmail = (email) => {
    console.log(types.SET_EMAIL, email)
    return {
        type: types.SET_EMAIL,
        payload: email
    };
};