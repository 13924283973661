import React, { useEffect, useState } from "react";
import TitlePage from "../../Shared/TitlePage";
import ButtonNormalText from "../../Shared/Buttons/ButtonNormalText";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableResults from "./Components/TableResults";
import { dataService } from "../../../Services/data/data.service";
import { Button, Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import Divider from '@mui/material/Divider';
import DropdownLocations from "./Components/DropdownLocations";
import DropdownProjectNames from "./Components/DropdownProjects";
import MyDateTimePicker from "../../Shared/DateSelection/DateTimePicker";
import { makeStyles } from "@material-ui/core/styles";
import { ProjectFilterTypes } from '../../../Config/constants';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment'
import { controlTypes } from '../../../Config/constants';
import TimeLineChart from './Components/TimeLineChart'
import TimeRangeChart from './Components/TimeRangeChart'
import DatePickerText from "../../Shared/DateSelection/DatePickerText";
import { parseNewDateToYYMMDDDash } from '../../../Utils/dateHelper';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Spin } from "react-cssfx-loading";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    "&:hover": { backgroundColor: "#C3996B" },
    padding: 0,
    color: 'white',
    transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function ResultsPage(props) {
    const classes = useStyles();
    const [measurementRows, setMeasurementRows] = useState([]);
    const GetUserID = props.Login.userid;
    const [filters, setFilters] = useState([]);
    const [filterOn, setFilterOn] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(10);
    const [types, setTypes] = useState([]);
    const [selectedProject, setSelectedProject] = useState(false);
    const [timeLineData, setChartData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [timeRangeData, setTimeRangeData] = useState([]);
    const [imgData, setImageData] = useState([]);
    const [exportActive, setExportActive] = useState(false);
    const [isChecked, setIsChecked] = useState(false);    
    const [location, setLocation] = useState('');
    const [projectName, setProjectName] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [hideProjectFilters, setHideProjectFilters] = useState(false)
    const anchorRef = React.useRef(null);
    const [isLoading, setLoading] = useState(true);
    const [timeRangeDates, setTimeRangeDates] = useState({ fromDate: new Date(Date.now() - 86400000), toDate: new Date(Date.now())});

    useEffect(() => {
        if (props.projectCopy.projectId === null || props.projectCopy.projectId === 0 || props.projectCopy.projectId === "") {
            dataService.getTypes().then(results => {
                setTypes(results.sort((a, b) => a.description.localeCompare(b.description)));
            });
            let TempMeasurementRows;
            dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToYYMMDDDash(timeRangeDates.fromDate), parseNewDateToYYMMDDDash(timeRangeDates.toDate), location, projectName).then(results => {
                TempMeasurementRows = (results.map(
                    y => ({
                        checked: true,
                        projectId: y.projectId,
                        toDate: y.toDate,
                        fromDate: y.fromDate,
                        color: y.color,
                        id: y.id,
                        typeId: y.typeId,
                        companyId: y.companyId,
                        qrCode: y.qrCode,
                        companyName: y.companyName,
                        detailId: y.projectDetailID,
                        projectName: y.projectName,
                        resourceName: y.resourceName,
                        description: y.description,
                        startTime: y.startTime,
                        stopTime: y.stopTime,
                        insertedOn: y.startTime,
                        updatedOn: y.stopTime,
                        measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                        running: (y.running === true ? 'True' : 'False'),
                        thumbnail: y.thumbnail,
                        imageId: y.imageId,
                        commentText: y.commentText,
                        commentImage: y.commentImage,
                        commentId: y.commentId
                    })));
                setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
                setExportActive(true);
                setSelectedProject(true);
                chartData(TempMeasurementRows.flat());
            });
        } else if (!props.projectCopy.report || props.projectCopy.report === undefined) {
            let TempMeasurementRows;
            dataService.getMeasurementById(GetUserID, props.projectCopy.projectId).then(results => {
                TempMeasurementRows = (results.map(
                    y => ({
                        checked: true,
                        projectId: y.projectId,
                        toDate: y.toDate,
                        fromDate: y.fromDate,
                        color: y.color,
                        id: y.id,
                        typeId: y.typeId,
                        companyId: y.companyId,
                        qrCode: y.qrCode,
                        companyName: y.companyName,
                        detailId: y.projectDetailID,
                        projectName: y.projectName,
                        resourceName: y.resourceName,
                        description: y.description,
                        startTime: y.startTime,
                        stopTime: y.stopTime,
                        insertedOn: y.startTime,
                        updatedOn: y.stopTime,
                        measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                        running: y.running,
                        imageId: y.imageId,
                        thumbnail: y.thumbnail,
                        commentText: y.commentText,
                        commentImage: y.commentImage,
                        commentId: y.commentId
                    })));
                var sortedMeasurements = TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime));
                var sortedMeasurementsDesc = TempMeasurementRows.flat().sort((a, b) => a.startTime.localeCompare(b.startTime));
                var dateFirstMeasurement;
                var dateLastMeasurement;

                if (sortedMeasurements.length > 0) {
                    dateFirstMeasurement = new Date(sortedMeasurements[sortedMeasurements.length - 1].startTime);
                    dateLastMeasurement = new Date(sortedMeasurementsDesc[sortedMeasurementsDesc.length - 1].startTime);
                } else {
                    dateFirstMeasurement = new Date();
                    dateLastMeasurement = new Date();

                }
                let tempFromDate = new Date(dateLastMeasurement.getTime() - 86400000);
                let tempToDate = dateLastMeasurement;
                setTimeRangeDates({ fromDate: tempFromDate, toDate: tempToDate});
                sortedMeasurements = sortedMeasurements.filter(x => new Date(x.insertedOn) <= tempToDate && new Date(x.insertedOn) >= tempFromDate);
                setMeasurementRows(sortedMeasurements);
                setExportActive(true);
                setHideProjectFilters(true);
                setSelectedProject(true);
                chartData(sortedMeasurements.flat());
            });
        } else if (props.projectCopy.report) {
            const [date, time] = props.projectCopy.toDate.split(' ');
            const [toDay, toMonth, toYear] = date.split('/');
            const [toHours, toMinutes, toSeconds] = time.split(':');

            let tempToDate = new Date(+toYear, toMonth - 1, +toDay, toHours, toMinutes, toSeconds);
            let TempMeasurementRows;
            let tempFromDate = new Date(tempToDate - 86400000);

            setProjectName(props.projectCopy.projectName);
            setLocation(props.projectCopy.resource);
            dataService.getMeasurementById(GetUserID, props.projectCopy.projectId).then(results => {
                let filteredResults = results.filter(x => new Date(x.startTime) >= tempFromDate && new Date(x.startTime) <= tempToDate);
                TempMeasurementRows = (filteredResults.map(
                    y => ({
                        checked: true,
                        projectId: y.projectId,
                        toDate: y.toDate,
                        fromDate: y.fromDate,
                        color: y.color,
                        id: y.id,
                        typeId: y.typeId,
                        companyId: y.companyId,
                        qrCode: y.qrCode,
                        companyName: y.companyName,
                        detailId: y.projectDetailID,
                        projectName: y.projectName,
                        resourceName: y.resourceName,
                        description: y.description,
                        startTime: y.startTime,
                        stopTime: y.stopTime,
                        insertedOn: y.startTime,
                        updatedOn: y.stopTime,
                        measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                        running: (y.running === true ? 'True' : 'False'),
                        thumbnail: y.thumbnail,
                        imageId: y.imageId,
                        commentText: y.commentText,
                        commentImage: y.commentImage,
                        commentId: y.commentId
                    })));
                setTimeRangeDates({ fromDate: tempFromDate, toDate: tempToDate});
                setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
                setExportActive(true);
                setSelectedProject(true);
                chartData(TempMeasurementRows.flat());
            });
        }
        // eslint-disable-next-line
    }, []);

    const limitString = (str, limit) => {
        if (str.length > limit) {
            return str.substring(0, limit) + "...";
        } else {
            return str;
        }
    }

    const chartData = (data) => {
        let tempdata = [];
        let tempObj = {};
        setLoading(true);
        
        data.map((x => {
            let startDate = new Date(x.startTime);
            let tempEndDate = new Date(x.startTime);
            let insertedOn = new Date(x.startTime);
            let updatedOn = new Date(x.startTime);

            if (x.typeId === controlTypes.STOPWATCH) {
                if(x.updatedOn !== undefined){
                    updatedOn = x.updatedOn;
                }
                if (x.stopTime.length > 0 || x.stopTime !== null) {
                    tempEndDate = new Date(x.stopTime);

                }
                if(x.insertedOn !== undefined){
                    insertedOn = x.insertedOn;
                }
                tempObj = { commentText: x.commentText, commentImage: x.commentImage,  checked: x.checked, id: x.id, startDate: startDate, insertedOn: insertedOn,updatedOn: updatedOn, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Stopwatch', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, description: limitString(x.description, 15), value: x.dateValue, counter: null, color: x.color, chartType: 'rangeBar', argumentField: 'description', detailId: x.detailId };
            } else if (x.typeId === controlTypes.COUNTER) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Counter', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, value: x.integerValue, counter: x.measurementValue, counterDescription: limitString(x.description, 15), color: x.color, chartType: 'scatter', argumentField: "counterDescription" };
            } else if (x.typeId === controlTypes.FREETEXT) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Free text', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, value: x.textValue, counter: null, freetextDescription: limitString(x.description, 15), color: x.color };
            } else if (x.typeId === controlTypes.DATALIST) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Data list', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, datalistDescription: limitString(x.description, 15), counter: null, color: x.color };
            } else if (x.typeId === controlTypes.CAMERA) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Image', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, image: x.imageId, thumbnail: x.thumbnail, cameraDescription: limitString(x.description, 15), counter: null, color: x.color };
            } else if (x.typeId === controlTypes.SCANNER) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'Data list', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, datalistDescription: limitString(x.description, 15), counter: null, color: x.color };
            } else if (x.typeId === controlTypes.EXTERNALAPI) {
                tempObj = { commentText: x.commentText, commentImage: x.commentImage, checked: x.checked, id: x.id, startDate: startDate, endDate: tempEndDate, companyName: x.companyName, measurementValue: x.measurementValue, type: 'External API', projectName: x.projectName, resource: x.resourceName, typeId: x.typeId, value: x.externalApiValues, externalApiDescription: limitString(x.description, 15), counter: null, color: x.color };
            }

            tempdata.push(tempObj);
        }));
        setLoading(false);
        setChartData(tempdata);
        setTimeRangeData(tempdata);
    }

    const getMeasurementValue = (TypeId, Text, Int, Time, DataListValue, ImageLink, ExternalApiValue) => {
        switch (TypeId) {
            case 1: return Int;
            case 2:
                if (Int > 0) {
                    return Time;
                } else {
                    return '';
                }
            case 3: return Text;
            case 4: return DataListValue;
            case 5: return Text;
            case 6: return ImageLink;
            case 7: return ExternalApiValue;
            // eslint-disable-next-line 
        }
    }

    const onCheckChanged = (id) => {
        setIsChecked(true)
        let tempMeasurementRows = [...measurementRows];
        tempMeasurementRows.find(x => x.id === id).checked = !tempMeasurementRows.find(x => x.id === id).checked;
        setMeasurementRows(tempMeasurementRows);
        if (tempMeasurementRows.flat().filter(x => x.checked === true).length > 0) {
            setExportActive(true);
            setSelectedProject(true);
        }
        else {
            setExportActive(false);
            setSelectedProject(false);
        }
        chartData(tempMeasurementRows.flat());
    }

    const selectAll = (selectAll) => {
        let tempMeasurementRows = [...measurementRows];
        tempMeasurementRows.map(y => y.checked = !selectAll);
        setMeasurementRows(tempMeasurementRows);
        chartData(tempMeasurementRows.flat());
        if (tempMeasurementRows.flat().filter(x => x.checked === true).length > 0) {
            setExportActive(true);
            setSelectedProject(true);
        }
        else {
            setExportActive(false);
            setSelectedProject(false);
        }
    }

    const filter = [
        { number: 1, column: "companyName", sortOrder: "ASC" },
        { number: 2, column: "companyName", sortOrder: "DESC" },
        { number: 3, column: "projectName", sortOrder: "ASC" },
        { number: 4, column: "projectName", sortOrder: "DESC" },
        { number: 5, column: "resourceName", sortOrder: "ASC" },
        { number: 6, column: "resourceName", sortOrder: "DESC" },
        { number: 7, column: "description", sortOrder: "ASC" },
        { number: 8, column: "description", sortOrder: "DESC" },
        { number: 9, column: "startTime", sortOrder: "ASC" },
        { number: 10, column: "startTime", sortOrder: "DESC" },
    ];

    const dynamicSorting = (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        let asc = false;
        let desc = false;
        /* SC: Filter now works both ways (ASC, DESC) */
        if (filter.find(x => x.number === selectedFilter).column === property) {
            if (filter.find(x => x.number === selectedFilter).sortOrder === 'ASC') {
                desc = true;
                asc = false;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'DESC').number);
            } else {
                desc = false;
                asc = true;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'ASC').number);
            }
        } else {
            desc = false;
            asc = true;
            setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'ASC').number);
        }
        if (asc === true) {
            /* ASC */
            if (property === 'startTime') {
                return function (a, b) {
                    var aMoment = moment(a[property], "DD/MM/YYYY, h:mm:ss a");
                    var bMoment = moment(b[property], "DD/MM/YYYY, h:mm:ss a'");
                    var result = aMoment - bMoment;
                    return result;
                }
            } else {
                return function (a, b) {
                    var result = (a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
                    return result * sortOrder;
                }
            }
        } else {
            /* DESC */
            if (property === 'startTime') {
                return function (a, b) {
                    var aMoment = moment(a[property], "DD/MM/YYYY, h:mm:ss a");
                    var bMoment = moment(b[property], "DD/MM/YYYY, h:mm:ss a'");
                    var result = bMoment - aMoment;
                    return result;
                }
            } else {
                return function (a, b) {
                    var result = (a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1);
                    return result * sortOrder;
                }
            }
        }
    }

    const handleSorting = (sortType) => {
        let tempMeasurementRows = [...measurementRows];
        setMeasurementRows(tempMeasurementRows.sort(dynamicSorting(sortType)));
    }

    const headers = [
        { label: "Customer", key: "companyName" },
        { label: "Project name", key: "projectName" },
        { label: "Location name", key: "resourceName" },
        { label: "Description", key: "description" },
        { label: "Value", key: "measurementValue" },
        { label: "Start Time", key: "startTime" },
        { label: "Running", key: "running" },
        { label: "Comment", key: "commentText" },
    ]

    const toggleFilter = () => {
        if (filterOn) {
            setFilters([])
        }
        setFilterOn(!filterOn)
    }

    const updateFilters = (type, event) => {
        let tempFilters = [...filters]

        if (tempFilters.some(x => x.type === type)) {
            let index = tempFilters.indexOf(tempFilters.find(x => x.type === type))
            tempFilters.splice(index, 1)
            tempFilters.unshift({ type: type, filterValue: event.target.value })
        }
        else {
            tempFilters.unshift({ type: type, filterValue: event.target.value })
        }
        setFilters(tempFilters);
        filterResults(tempFilters);
    }

    const filterResults = (thisFilters) => {
        let varFilteredResults = [...measurementRows]
        var i;
        for (i = 0; i < thisFilters.length; i++) {
            let filter = thisFilters[i];
            switch (filter.type) {
                case ProjectFilterTypes.CUSTOMER:
                    varFilteredResults = varFilteredResults.filter(x => x.companyName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                case ProjectFilterTypes.PROJECT_NAME:
                    varFilteredResults = varFilteredResults.filter(x => x.projectName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                case ProjectFilterTypes.LOCATION_NAME:
                    varFilteredResults = varFilteredResults.filter(x => x.resourceName.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                case ProjectFilterTypes.DESCRIPTION:
                    varFilteredResults = varFilteredResults.filter(x => x.description.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;
                default:
                    ;
            }
        }
        setMeasurementRows(varFilteredResults)
    }

    const handleVisualRange = (data) => {
        setTimeRangeData(data);
    }

    const updateDataRange = (data) => {
        let tempToDate = new Date(data[1]);
        let tempFromDate = new Date(data[0]);
        let tempRows = [...measurementRows];
        tempRows.map(x => {
            if ((new Date(x.startTime) >= tempFromDate && new Date(x.startTime) <= tempToDate)) {
                if(x.stopTime === ''){
                    x.checked = true;

                }else{
                    if ((new Date(x.stopTime) >= tempFromDate && new Date(x.stopTime) <= tempToDate)) {
                        x.checked = true;
                    }else{
                        x.checked = true;
                        if(new Date(x.startTime) < tempFromDate){
                            x.startTime = tempFromDate;
                        }
                        if(new Date(x.stopTime) > tempToDate){
                            x.stopTime = tempToDate;
                        }
                    }
                }
            } else {
                if(x.stopTime === ''){
                    x.checked = false;
                }else{
                    if((new Date(x.stopTime) >= tempFromDate && new Date(x.stopTime) <= tempToDate)){
                        x.checked = true;                    
                        if(new Date(x.startTime) < tempFromDate){
                            x.startTime = tempFromDate;
                        }
                        if(new Date(x.stopTime) > tempToDate){
                            x.stopTime = tempToDate;
                        }
                    }
                }
            }
        });
        console.log('tempRows , ', tempRows)

        chartData(tempRows.flat());
        setMeasurementRows(tempRows);

    }
    const getDataByDates = (tempFromDate, temptoDate) => {
        let TempMeasurementRows;

        dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToYYMMDDDash(tempFromDate), parseNewDateToYYMMDDDash(temptoDate), location, projectName).then(results => {
            TempMeasurementRows = (results.map(
                y => ({
                    checked: true,
                    projectId: y.projectId,
                    toDate: y.toDate,
                    fromDate: y.fromDate,
                    color: y.color,
                    id: y.id,
                    typeId: y.typeId,
                    companyId: y.companyId,
                    qrCode: y.qrCode,
                    companyName: y.companyName,
                    detailId: y.projectDetailID,
                    projectName: y.projectName,
                    resourceName: y.resourceName,
                    description: y.description,
                    startTime: y.startTime,
                    stopTime: y.stopTime,
                    measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                    running: (y.running === true ? 'True' : 'False'),
                    thumbnail: y.thumbnail,
                    imageId: y.imageId,
                    commentText: y.commentText,
                    commentImage: y.commentImage,
                    commentId: y.commentId
                })));

            setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
            setExportActive(true);
            setSelectedProject(true);
            chartData(TempMeasurementRows.flat());
            // setRefresh(!refresh);
        });
    };

    // const handleSelectVisualRange = (data) => {
    //     let dayBeforeFromDate = new Date(data[0]);
    //     let dayBeforeToDate = new Date(data[1]);
    //     console.log(dayBeforeFromDate, dayBeforeToDate);
    //     // setToDate(dayBeforeToDate);
    //     // setFromDate(dayBeforeFromDate);
    //     let TempMeasurementRows;
    //     dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToDDMMYYYDash(dayBeforeFromDate), parseNewDateToDDMMYYYDash(dayBeforeToDate), location, projectName).then(results => {
    //         TempMeasurementRows = (results.map(
    //             y => ({
    //                 checked: true,
    //                 projectId: y.projectId,
    //                 toDate: y.toDate,
    //                 fromDate: y.fromDate,
    //                 color: y.color,
    //                 id: y.id,
    //                 typeId: y.typeId,
    //                 companyId: y.companyId,
    //                 qrCode: y.qrCode,
    //                 companyName: y.companyName,
    //                 detailId: y.projectDetailID,
    //                 projectName: y.projectName,
    //                 resourceName: y.resourceName,
    //                 description: y.description,
    //                 startTime: y.startTime,
    //                 stopTime: y.stopTime,
    //                 measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
    //                 running: (y.running === true ? 'True' : 'False'),
    //                 thumbnail: y.thumbnail,
    //                 imageId: y.imageId,
    //                 commentText: y.commentText,
    //                 commentImage: y.commentImage,
    //                 commentId: y.commentId
    //             })));

    //         setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
    //         setExportActive(true);
    //         setSelectedProject(true);
    //         chartData(TempMeasurementRows.flat());
    //         // setRefresh(!refresh);
    //     });    
    // }

    const handleIsChecked = (e) => {
        setIsChecked(e);
    }

    const setTheDate = (event) => {
        var yesterday = new Date(new Date(event));
        yesterday.setDate(yesterday.getDate() + 1);
        setTimeRangeDates({ fromDate: new Date(event), toDate: yesterday});
    }

    const handleSetLocation = (event) => {
        setLocation(event.map(x => x.location).join(","));
    }

    const handleSetProjectName = (event) => {
        setProjectName(event.map(x => x.projectName).join(","));
    }

    const handleOpenSearch = () => {
        setOpenSearch(!openSearch);
    }

    const handleNextDay = () => {
        var tempFromDate = new Date(timeRangeDates.toDate);
        var tempToDate = new Date(timeRangeDates.toDate.getTime() + 86400000);
        setTimeRangeDates({ fromDate: tempFromDate, toDate: tempToDate });
        let TempMeasurementRows;
        dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToYYMMDDDash(tempFromDate), parseNewDateToYYMMDDDash(tempToDate), location, projectName).then(results => {
            TempMeasurementRows = (results.map(
                y => ({
                    checked: true,
                    projectId: y.projectId,
                    toDate: y.toDate,
                    fromDate: y.fromDate,
                    color: y.color,
                    id: y.id,
                    typeId: y.typeId,
                    companyId: y.companyId,
                    qrCode: y.qrCode,
                    companyName: y.companyName,
                    detailId: y.projectDetailID,
                    projectName: y.projectName,
                    resourceName: y.resourceName,
                    description: y.description,
                    startTime: y.startTime,
                    stopTime: y.stopTime,
                    measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                    running: (y.running === true ? 'True' : 'False'),
                    thumbnail: y.thumbnail,
                    imageId: y.imageId,
                    commentText: y.commentText,
                    commentImage: y.commentImage,
                    commentId: y.commentId
                })));
            setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
            setExportActive(true);
            setSelectedProject(true);
            chartData(TempMeasurementRows.flat());
            setRefresh(!refresh);
        });
    }

    const handlePreviousDay = () => {
        //-24 hours, so the Todate becomes the fromdate
        var tempFromDate = new Date(timeRangeDates.fromDate.getTime() - 86400000);
        var tempToDate = new Date(timeRangeDates.fromDate);

        setTimeRangeDates({ fromDate: tempFromDate, toDate: tempToDate });

        let TempMeasurementRows;
        dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToYYMMDDDash(tempFromDate), parseNewDateToYYMMDDDash(tempToDate), location, projectName).then(results => {
            TempMeasurementRows = (results.map(
                y => ({
                    checked: true,
                    projectId: y.projectId,
                    toDate: y.toDate,
                    fromDate: y.fromDate,
                    color: y.color,
                    id: y.id,
                    typeId: y.typeId,
                    companyId: y.companyId,
                    qrCode: y.qrCode,
                    companyName: y.companyName,
                    detailId: y.projectDetailID,
                    projectName: y.projectName,
                    resourceName: y.resourceName,
                    description: y.description,
                    startTime: y.startTime,
                    stopTime: y.stopTime,
                    insertedOn: y.startTime,
                    updatedOn: y.stopTime,
                    measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                    running: (y.running === true ? 'True' : 'False'),
                    thumbnail: y.thumbnail,
                    imageId: y.imageId,
                    commentText: y.commentText,
                    commentImage: y.commentImage,
                    commentId: y.commentId
                })));
            setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
            setExportActive(true);
            setSelectedProject(true);
            chartData(TempMeasurementRows.flat());
            setRefresh(!refresh);
        });
    }


    const handleSearch = () => {
        let TempMeasurementRows;
        dataService.getMeasurementsByUserIdDateLocationName(GetUserID, parseNewDateToYYMMDDDash(timeRangeDates.fromDate), parseNewDateToYYMMDDDash(timeRangeDates.toDate), location, projectName).then(results => {
            console.log(results);
            TempMeasurementRows = (results.map(
                y => ({
                    checked: true,
                    projectId: y.projectId,
                    toDate: y.toDate,
                    fromDate: y.fromDate,
                    color: y.color,
                    id: y.id,
                    typeId: y.typeId,
                    companyId: y.companyId,
                    qrCode: y.qrCode,
                    companyName: y.companyName,
                    detailId: y.projectDetailID,
                    projectName: y.projectName,
                    resourceName: y.resourceName,
                    description: y.description,
                    startTime: y.startTime,
                    stopTime: y.stopTime,
                    insertedOn: y.startTime,
                    updatedOn: y.stopTime,
                    measurementValue: getMeasurementValue(y.typeId, y.textValue, y.integerValue, y.dateValue, y.dataListValue, y.imageLink, y.externalApiValues),
                    running: (y.running === true ? 'True' : 'False'),
                    thumbnail: y.thumbnail,
                    imageId: y.imageId,
                    commentText: y.commentText,
                    commentImage: y.commentImage,
                    commentId: y.commentId
                })));
            setMeasurementRows(TempMeasurementRows.flat().sort((b, a) => a.startTime.localeCompare(b.startTime)));
            setExportActive(true);
            setSelectedProject(true);
            chartData(TempMeasurementRows.flat());
            setRefresh(!refresh);
        });
    }

    return (
        <div>
            <TitlePage Text="Results" {...props} backbutton={true} />
            <div className={classes.container}>
                <div className={classes.buttons}>
                    <div>
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">

                            <Button onClick={handleOpenSearch} className={classes.filterButton} content="Filters" >
                                Filters
                                <ExpandMore
                                    expand={openSearch}
                                    onClick={handleOpenSearch}
                                    aria-expanded={openSearch}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Button>
                            <Button style={{ borderLeft: '1px solid #e3c09a', backgroundColor: "#C3996B", color: "white", marginLeft: "-10px", borderRadius: 0 }} sx={{ boxShadow: 3 }} onClick={handleSearch} size={"large"}>
                                <RefreshIcon />
                            </Button>
                        </ButtonGroup>

                    </div>
                    <div>
                        {exportActive ?
                            <CSVLink data={measurementRows} headers={headers} filename={"MeasurementData.csv"} style={{ textDecoration: 'none' }}>
                                <ButtonNormalText startIcon={<FileDownloadIcon />} content="Export Results To CSV" />
                            </CSVLink>
                            :
                            <ButtonNormalText disabled startIcon={<FileDownloadIcon />} content="Export Results To CSV" />
                        }
                    </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit style={{ marginBottom: "15px" }}>
                    <div className={classes.stylingBlock}>
                        <div className={classes.filterBlock}>
                            <div>
                                <DatePickerText Text="Timestamp: " />
                                <MyDateTimePicker {...props} value={timeRangeDates.fromDate} toDate={timeRangeDates.toDate} action={setTheDate} />
                            </div>
                            {!hideProjectFilters &&
                                <React.Fragment>
                                    <div>
                                        <DatePickerText Text="Project: " />
                                        <DropdownProjectNames {...props} refresh={refresh} fromDate={parseNewDateToYYMMDDDash(timeRangeDates.fromDate)} toDate={parseNewDateToYYMMDDDash(timeRangeDates.toDate)} action={handleSetProjectName} />
                                    </div>
                                    <div>
                                        <DatePickerText Text="Location: " />
                                        <DropdownLocations {...props} refresh={refresh} fromDate={parseNewDateToYYMMDDDash(timeRangeDates.fromDate)} toDate={parseNewDateToYYMMDDDash(timeRangeDates.toDate)} action={handleSetLocation} />
                                    </div>
                                </React.Fragment>}
                            <div className={classes.filterItem}>
                                <Button onClick={handleSearch} style={{ backgroundColor: "#C3996B", color: "white", fontSize: "15px" }} sx={{ boxShadow: 3 }}>
                                    <SearchIcon />
                                    Search
                                </Button>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>

                            <div style={{ width: "100%" }}>
                                {/* setSelectVisualRange={handleSelectVisualRange} */}
                                <TimeRangeChart updateDataRange={updateDataRange} handlePreviousDay={handlePreviousDay} handleNextDay={handleNextDay} timeRangeDates={timeRangeDates}></TimeRangeChart>
                            </div>

                        </div>
                        <Typography fontSize={15} style={{ marginTop: "5px", textAlign: "right", color: "rgba(0, 0, 0, 0.3)" }} component="div">
                            Your results window is 24 hours from your timestamp.
                        </Typography>
                    </div>
                </Collapse>
                {isLoading ?
                    <div className={classes.loadingChart}><Spin width="100px" height="100px" color="#C3996B"></Spin></div> :
                    <div>
                        <React.Fragment>
                            {selectedProject && timeLineData.length > 0 ? <TimeLineChart data={timeRangeData.filter(x => x.checked === true)} imgData={imgData}></TimeLineChart> : <React.Fragment></React.Fragment>}
                        </React.Fragment>
                        <Grid
                            container
                            columnSpacing={3}
                            rowSpacing={1}
                            alignItems="center">
                            <Grid item xs={12}>
                                <Divider style={{ marginBottom: "1rem", marginTop: "1rem", }}></Divider>
                                <TableResults filterOn={filterOn} filterResults={filterResults} types={types} updateFilters={updateFilters} toggleFilter={toggleFilter} filters={filters} rows={measurementRows} handleSorting={handleSorting} onCheckChanged={onCheckChanged} selectAll={selectAll} selectedProject={selectedProject} />
                            </Grid>
                        </Grid>
                    </div>
                }
            </div>
        </div>
    )
}

export default ResultsPage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        paddingBottom: "1.5rem",
        paddingTop: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "5%"
    },
    loadingChart:{
        height: '70vh',
        width: '100%',
        position: 'relative',
        borderRadius: "8px",
        backgroundColor: 'white',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    exportContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginLeft: '1.5rem',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
    },
    dateSelection: {
        display: 'flex',
        flexDirection: 'column'
    },
    filterBlock: {
        display: 'flex',
        marginBottom: '15px',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            gap: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
        }
    },
    filterItem: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '25px'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px',
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
        }
    },
    filterButton: {
        backgroundColor: "#C3996B !important"
    },
    stylingBlock: {
        backgroundColor: '#f0f0f0',
        borderRadius: '6px',
        boxShadow: 'inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692)',
        padding: "25px"
    },
    buttons: {
        paddingBottom: "15px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    }
}));