import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function DropdownResources(props) {

    // Getting the Array List
    const dropdownList = props.resources.map(x => { return { label: x.resourceDescription, value: x.id } })
    // Filtering the Array list for any duplicates
    const filterd = Array.from(new Set(dropdownList.map(a => a.label)))
        .map(label => {
            return dropdownList.find(a => a.label === label)
        })

    return (
        <Autocomplete
            freeSolo
            disablePortal
            value={props.value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    props.action(newValue)
                } else if (newValue && newValue.inputValue) {
                    props.action(newValue)
                } else {
                    props.action(event)
                }
            }}
            options={filterd.sort((a, b) => a.label.localeCompare(b.label))}
            renderInput={(params) => <TextField error={props.error} helperText={props.helperText} {...params} InputLabelProps={{ shrink: false }} label={props.value ? " " : "Location"} onChange={(event) => props.action(event)}/>}
        />
    );
}

export default DropdownResources