import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import cameraIcon from './camera.svg';
import { makeStyles } from "@material-ui/core/styles";
import { LightenDarkenColor } from 'lighten-darken-color';
import Webcam from "react-webcam";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CircleIcon from '@mui/icons-material/Circle';
import { IconButton } from '@material-ui/core';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import PhotoIcon from '@mui/icons-material/Photo';
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { dataService } from '../../../Services/data/data.service';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNormalText from '../../Shared/Buttons/ButtonNormalText';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { parseNewDateToDDMMYYY } from '../../../Utils/dateHelper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px" }}>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Camera(props) {

    const [openImage, setOpenImage] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [captured, setCaptured] = useState(false);
    const [lastImages, setLastImages] = useState([]);
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState("")
    const [cameraSide, setCameraSide] = useState(true)
    const webcamRef = React.useRef(null);
    const current = new Date();
    const date = parseNewDateToDDMMYYY(current);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [extraContent, setExtraContent] = useState(false)
    const [comment, setComment] = useState("");
    const [openComment, setOpenComment] = useState(false);
    const [listOfComments, setListOfComments] = useState([]);

    useEffect(() => {
        setListOfComments(props.comments)
        // eslint-disable-next-line no-undef
    }, []);
    const cameraFace = {
        facingMode: "user"
    }

    const cameraBack = {
        facingMode: { exact: "environment" }
    }

    const CameraStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        objectFit: "cover",
        top: "0",
        left: "0",
    }

    const handlePage = (event, value) => {
        setPage(value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDialogOpenComment = () => {
        setOpenComment(true);
    }

    const handleDialogCloseComment = () => {
        setOpenComment(false);
    }

    const Cameremode = () => {
        setCameraSide(!cameraSide)
    }

    const handleDialogOpen = () => {
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleOpenImageOpen = (id) => {
        setOpenImage(true)
        setSelectedImage(id)
    };

    const handleOpenImageClose = () => {
        setOpenImage(false)
    };

    const handleExtraContentClick = () => {
        setExtraContent(!extraContent);
    };

    const GenerateID = () => {
        let tempArray = [...lastImages];
        const ids = tempArray.map(object => {
            return object.id;
        });
        const max = Math.max(...ids);
        if (tempArray.length === 0) {
            return 1;
        }
        else {
            let index = max;
            index++;
            return index;
        }
    }

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc !== null) {
            setCaptured(true);
            setImage(imageSrc);
            let tempImages = [...lastImages]
            let ImageObject = { id: GenerateID(), image: imageSrc }
            if (tempImages.length < 3) {
                tempImages.push(ImageObject);
                setSelectedImage(ImageObject.id)
                setLastImages(tempImages)
            }
            else {
                tempImages.push(ImageObject);
                tempImages.shift();
                setSelectedImage(ImageObject.id)
                setLastImages(tempImages)
            }
            if (props.comment) {
                setComment("");
                handleDialogOpenComment();
            }
            else {
                dataService.newMediaImage(date, imageSrc.slice(23), props.id, );
            }
        }
        else {
            alert("No Camera Found");
        }
    }

    const handleSendWithComment = () => {

        dataService.newMediaImageWithComment(date, image.slice(23), props.id, comment);
        handleDialogCloseComment();
        let tempListOfComments = [...listOfComments];
        let index = tempListOfComments.length + 1;
        let newListItem = { comment: comment, index: index++, insertedOn: new Date(Date.now()) };
        tempListOfComments.push(newListItem)
        setListOfComments(tempListOfComments)
        setPage(index - 1)
    }

    return (
        <div>
            <Card>
                <div className={classes.freeText} style={{
                    color: props.textColor,
                    backgroundColor: `${props.color}`,
                }}>
                    <div className={classes.headerDiv}>
                        {props.comment && listOfComments.length > 0 ?
                            <Button style={{ zIndex: "4", position: "absolute", left: "3%" }} size={'large'} onClick={handleExtraContentClick}>
                                <ReportProblemIcon style={{ color: props.textColor }} />
                            </Button>
                            : <React.Fragment></React.Fragment>
                        }
                        <h2 className={classes.h2style}>{props.description}</h2>
                        <img className={classes.imageStyle} src={cameraIcon} alt="" />
                    </div>
                    <Divider></Divider>
                    <div className={classes.textDiv} style={{
                        backgroundColor: LightenDarkenColor(props.color, 20)
                    }}>
                        <IconButton onClick={handleDialogOpen} style={{ color: props.textColor }}>
                            <PhotoCameraIcon fontSize="large" color='white' />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <div className={classes.StyleDiv}>
                        {lastImages.length > 0 ?
                            lastImages.map(x =>
                                <IconButton onClick={() => handleOpenImageOpen(x.id)} size="large">
                                    <img style={{ margin: "0px", width: "2rem", height: "2rem", borderRadius: "50%" }} src={x.image}></img>
                                </IconButton>
                            ) :
                            <React.Fragment></React.Fragment>
                        }
                    </div>
                </div>

                <Dialog
                    open={openImage}
                    onClose={handleOpenImageClose}
                    maxWidth={"lg"}
                >
                    <DialogTitle>
                        {"Image"}
                    </DialogTitle>
                    <DialogContent>
                        {selectedImage && <img style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }} src={lastImages.length > 0 ? lastImages.find(x => x.id === selectedImage).image : ""}></img>}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <ButtonNormalText content={"Close"} onClick={handleOpenImageClose} size="large">
                        </ButtonNormalText>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    open={openComment}
                    onClose={handleDialogCloseComment}>
                    <DialogTitle>
                        {"Comment"}
                        <IconButton style={{ position: "absolute", right: "10px", top: "5px" }} onClick={handleDialogCloseComment} size="large">
                            <CloseIcon fontSize="inherit" color='blue' />
                        </IconButton>
                    </DialogTitle>
                    <Divider ></Divider>
                    <div className={classes.StyleDivComment}>
                        <TextField multiline
                            rows={8}
                            style={{ marginBottom: "10px", margin: "10px", width: "87%" }}
                            label={comment ? " " : "Make a comment."}
                            InputLabelProps={{ shrink: false }} variant="outlined"
                            onChange={(event) => setComment(event.target.value)}
                            value={comment} />
                        <IconButton style={{ marginBottom: "12px" }} onClick={handleSendWithComment} size="large">
                            <SendIcon fontSize="inherit" color='blue' />
                        </IconButton>
                    </div>
                </Dialog>

                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleDialogClose}
                    maxWidth={"lg"}
                >
                    <DialogTitle style={{ background: "rgba(255, 255, 255, .0)", zIndex: "1", boxShadow: 2, color: 'white' }}>
                        {"Camera"}
                        <IconButton style={{ position: "absolute", right: "10px", top: "5px" }} onClick={handleDialogClose} size="large">
                            <CloseIcon size="large" fontSize="inherit" style={{ color: "white", boxShadow: 2 }} />
                        </IconButton>
                    </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        {
                            cameraSide === true ?
                                <Webcam
                                    style={CameraStyle}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={cameraBack}
                                ></Webcam> :
                                <Webcam
                                    style={CameraStyle}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={cameraFace}
                                ></Webcam>
                        }
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions style={{ justifyContent: "center", background: "rgba(255, 255, 255, .25)", zIndex: "1" }}>
                        <IconButton onClick={handleExpandClick} size="large" style={{  fontSize: '50px', marginRight: '15px'  }}>
                            {captured ?
                                <img style={{ margin: "0px", width: "2rem", height: "2rem", borderRadius: "50%" }} src={image}></img>
                                :
                                <PhotoIcon size="large" fontSize="inherit" style={{ color: "white" }} />
                            }
                        </IconButton>
                        <IconButton onClick={capture} size="large" style={{ border: '1px solid white', fontSize: '50px', marginRight: '15px'   }} className={classes.takePictureIcon}>
                            <CircleIcon size="large" fontSize="inherit" style={{ color: "white" }}  />
                        </IconButton>
                        <IconButton onClick={Cameremode} size="large" style={{  fontSize: '50px'  }}>
                            <CameraswitchIcon size="large" fontSize="inherit" style={{ color: "white" }} />
                        </IconButton>
                    </DialogActions>
                    <Divider style={{ background: "rgb(0, 0, 0)", zIndex: "2" }}></Divider>
                    <Collapse style={{ background: "rgb(255, 255, 255)", zIndex: "2" }} in={expanded} timeout="auto" unmountOnExit>
                        <DialogContent style={{ background: "rgb(255, 255, 255)", zIndex: "2" }}>
                            <img style={{ background: "rgb(255, 255, 255)", zIndex: "2", maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                                src={image}></img>
                        </DialogContent>
                    </Collapse>
                </Dialog>
            </Card>
            {
                extraContent ? <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", top: "1", width: "100%", zIndex: "10" }}>
                        <Collapse in={extraContent} timeout="auto" unmountOnExit >
                            <Card >
                                {listOfComments.filter(list => list.index === page).map(x =>
                                    <List sx={{ width: '100%', overflow: "hidden" }}>
                                        <ListItem>
                                            <Typography style={{ width: "70%" }} >{props.description + " Comment: " + x.index}</Typography>
                                            <Typography style={{ width: "30%", fontWeight: "bold" }}>{parseNewDateToDDMMYYY(new Date(x.insertedOn))}</Typography>
                                        </ListItem>
                                        <Divider></Divider>
                                        {x.comment !== "" ?
                                            <ListItem>
                                                <Typography style={{ hyphens: "auto", wordWrap: "break-word", overflowWrap: "anywhere" }}>{x.comment}</Typography>
                                            </ListItem>
                                            :
                                            <React.Fragment></React.Fragment>}
                                        {x.comment === "" && (x.image === "" || x.image === null) ?
                                            <ListItem>
                                                <Typography style={{ color: "red" }} >No recorded comment.</Typography>
                                            </ListItem>
                                            :
                                            <React.Fragment></React.Fragment>}
                                        <Divider></Divider>
                                        <ListItem style={{ justifyContent: 'center' }}>
                                            <Pagination page={page} variant="outlined" size="small" siblingCount={0} count={listOfComments.length} onChange={handlePage} />
                                        </ListItem>
                                    </List>
                                )
                                }
                            </Card>
                        </Collapse>
                    </div>
                </div> :
                    <React.Fragment></React.Fragment>
            }
        </div >
    );
}


export default Camera;

const useStyles = makeStyles({
    freeText: {
        overflow: 'hidden',
        backgroundRepeat: "no-repeat",
        backgroundSize: "10rem 10rem",
        backgroundPosition: "center",
        height: "12rem",
    },
    takePictureIcon:{
        padding: '0px !important'
    },
    headerDiv: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
    },
    h2style: {
        margin: '0',
        zIndex: '2',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    StyleDiv: {
        height: '33%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: 'center',
    },
    StyleDivComment: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'flex-end',
    },
    imageStyle: {
        position: 'absolute',
        right: '3%',
        filter: 'contrast(80%)',
        opacity: '50%',
        width: '50px'
    },
    textDiv: {
        paddingLeft: '15px',
        paddingRight: '15px',
        display: "flex",
        justifyContent: "center",
        height: '34%',
        alignItems: 'center',
    }
});