import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function DropdownCompany(props) {
  const dropdownList = props.companies.map(x => { return { label: x.name, value: x.id } })
  return (
    <Autocomplete
      freeSolo
      disablePortal
      value={props.value}
      options={dropdownList.sort((a, b) => a.label.localeCompare(b.label))}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.action(newValue)
        } else if (newValue && newValue.inputValue) {
          props.action(newValue)
        } else {
          props.action(event)
        }
      }} renderInput={(params) => <TextField error={props.error} helperText={props.helperText} {...params} InputLabelProps={{ shrink: false }} label={props.value ? " " : "Company"} onChange={(event) => props.action(event)} />}
    />
  );
}

export default DropdownCompany