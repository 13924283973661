import * as types from './ExternalAPiCopyActionTypes.js';

export const externalApiNameAction = (externalApiName) => {
    return {
        type: types.SET_EXTERNALAPINAME,
        payload: externalApiName
    };
};

export const externalApiDescriptionAction = (externalApiDescription) => {
    return {
        type: types.SET_EXTERNALAPIDESCRIPTION,
        payload: externalApiDescription
    };
};

export const externalApiKeyAction = (externalApiKey) => {
    return {
        type: types.SET_EXTERNALAPIKEY,
        payload: externalApiKey
    };
};

export const externalApiItemsAction = (externalApiItems) => {
    return {
        type: types.SET_EXTERNALAPIITEMS,
        payload: externalApiItems
    };
};

export const externalApiIdAction = (externalApiId) => {
    return {
        type: types.SET_EXTERNALAPIID,
        payload: externalApiId
    };
};