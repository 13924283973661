import * as types from './ExternalAPiCopyActionTypes';

const initialState = {
    ExternalApiName: "",
    ApiDescription: "",
    ExternalApiKey: "",
    ExternalApiItems: [],
    ExternalApiId: "",
};

export default function ExternalApiCopy(state = initialState, action) {
    switch (action.type) {
        case types.SET_EXTERNALAPINAME:
            if (action.payload !== state.ExternalApiName) {
                return Object.assign({}, state, { ExternalApiName: action.payload });
            }
            return state;
        case types.SET_EXTERNALAPIDESCRIPTION:
            if (action.payload !== state.ExternalApiDescription) {
                return Object.assign({}, state, { ExternalApiDescription: action.payload });
            }
            return state;
        case types.SET_EXTERNALAPIKEY:
            if (action.payload !== state.ExternalApiKey) {
                return Object.assign({}, state, { ExternalApiKey: action.payload });
            }
            return state;
        case types.SET_EXTERNALAPIITEMS:
            if (action.payload !== state.ExternalApiItems) {
                return Object.assign({}, state, { ExternalApiItems: action.payload });
            }
            return state;
            case types.SET_EXTERNALAPIID:
                if (action.payload !== state.ExternalApiId) {
                    return Object.assign({}, state, { ExternalApiId: action.payload });
                }
                return state;
        default: {
            return state;
        }
    }
}