import React, { useEffect, useState } from "react";
import RangeSelector, { Size, Scale, SliderMarker, MinorTick } from 'devextreme-react/range-selector';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

class TimeRangeChartCopy extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            startValue: props.timeRangeDates.fromDate,
            endValue: props.timeRangeDates.toDate,
        };
        //Functions in the props
        this.handlePreviousDay = this.props.handlePreviousDay(this);
        this.handleNextDay = this.props.handleNextDay(this);
        this.updateDataRange = this.props.updateDataRange(this);

        //Functions on this page
        this.thisPageUpdateVisualRange = this.thisPageUpdateVisualRange.bind(this);
        this.thisPageHandlePreviousDay = this.thisPageHandlePreviousDay.bind(this);
        this.thisPageHandleNextDay = this.thisPageHandleNextDay.bind(this);
        this.dateFormatter = this.dateFormatter.bind(this);
    };

    render() {
        return (

            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginTop: 'auto', marginBottom: 'auto', width: '3%' }}>
                    <Tooltip title="Previous 24 hours" arrow>
                        <IconButton style={{ backgroundColor: "#C3996B", marginLeft: '10px', color: "white" }} sx={{ boxShadow: 3 }} onClick={this.thisPageHandlePreviousDay} size={"small"}>
                            <ChevronLeftIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ width: '93%' }}>
                    {console.log(this.state.startValue, ' ---------- ', this.state.endValue)}
                    <RangeSelector id="range-selector" selectedRangeColor="#C3996B" onValueChanged={(e) => this.thisPageUpdateVisualRange(e)} defaultValue={[this.state.startValue, this.state.endValue]}>
                        <Scale startValue={this.state.startValue} endValue={this.state.endValue} tickInterval={"hours"} >

                            <MinorTick visible={false} />
                        </Scale>
                        <SliderMarker format={this.dateFormatter} />
                        <Size height={50} />
                    </RangeSelector>
                </div>
                <div style={{ marginTop: 'auto', marginBottom: 'auto', width: '3%' }}>
                    <Tooltip title="Next 24 hours" arrow>
                        <IconButton style={{ backgroundColor: "#C3996B", marginRight: '10px', color: "white" }} sx={{ boxShadow: 3 }} onClick={this.thisPageHandleNextDay} size={"small"}>
                            <ChevronRightIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>

            </div>

        );

    }
    thisPageUpdateVisualRange(data) {
        //Update date range values (This will select and deselect all measurements between these dates)
        this.props.updateDataRange(data.value);
        console.log('this.state ', this.state)
    }
    thisPageHandlePreviousDay() {
        console.log('handlePreviousDay ?????????????????????????????????????????????????????')
        let tempStartValue = new Date(this.state.startValue - 86400000);
        let tempEndValue = new Date(this.state.endValue - 86400000);

        this.setState({
            startValue: tempStartValue,
            endValue: tempEndValue,
        });
        this.props.handlePreviousDay();

    }
    thisPageHandleNextDay() {
        let tempStartValue = new Date(this.state.startValue + 86400000);
        let tempEndValue = new Date(this.state.endValue + 86400000);
        this.setState({
            startValue: tempStartValue,
            endValue: tempEndValue,
        });
        this.props.handleNextDay();

    }
    dateFormatter(date) {
        const hour = date.getHours();
        const minute = date.getMinutes();
        console.log(date)
        return `${("0" + (date.getDate())).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + (hour)).slice(-2)}:${("0" + (minute)).slice(-2)}`;
        //let tempTomorrow = new Date(date.getDate() + 86400000);
    }
}

export default TimeRangeChartCopy;
