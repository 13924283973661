import React, { useState } from "react";
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@mui/icons-material/Add';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; import Divider from '@mui/material/Divider';
import CottageIcon from '@mui/icons-material/Cottage';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getAuth, signOut } from "firebase/auth";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkIcon from '@mui/icons-material/Link';
import ResultsIcon from './ResultsIcon.svg';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';

function Menu(props) {

    const classes = useStyles();

    const history = useHistory();
    const loggedInState = props.Login.loginState;
    const [openCollapse, setOpenCollapse] = useState(false);

    function handleOpenSettings() {
        setOpenCollapse(!openCollapse);
    }
    const auth = getAuth();

    const LogOut = () => {
        signOut(auth).then((response) => {
            history.push('/login');
            props.actions.setLogin(false);
            props.actions.setUserid(null);

            // Sign-out successful.
        }).catch((error) => {
            console.log(error, 'error')
            // An error happened.
        });
        // props.actions.setLogin(false);
        // props.actions.setUserid(null);
        // if (loggedInState === true) {
        //     history.push('/login');
        // }
    }

    let location = history.location.pathname;

    const navigateTo = (destination) => {
        history.push(destination);
    }
    const navigateResults = () => {
        props.actions.projectIdAction(null);
        history.push('/results');

    }

    const navigateUserSettings = () => {
        props.actions.projectIdAction(null);
        // props.actions.userInformation(auth);
        history.push('/user');

    }

    return (
        <div>
            <Drawer className={classes.sideBar} anchor={'right'} width={300} open={props.open}
                ModalProps={{ onBackdropClick: props.close }} >
                <div className={classes.titleHeader}>
                    <h2>Menu</h2>
                    <IconButton onClick={props.close} style={{ marginLeft: "120px", marginRight: '10px' }}>
                        <CloseIcon />
                    </IconButton>

                </div>
                <Divider></Divider>
                <div className={classes.drawerItems}>
                    <MenuItem
                        selected={location === '/' ? true : false}
                        className={classes.menuItem}
                        onClick={() => navigateTo('/')}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <CottageIcon></CottageIcon>
                        </span>
                        <span className={classes.menuText}>Homepage</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/create' ? true : false}
                        className={classes.menuItem}
                        onClick={() => navigateTo('/create')}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <AddIcon></AddIcon>
                        </span>
                        <span className={classes.menuText}>Create a project</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/manage' ? true : false}
                        className={classes.menuItem}
                        onClick={() => navigateTo('/manage')}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <BuildIcon></BuildIcon>
                        </span>
                        <span className={classes.menuText}>Manage projects</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/projectreportoverview' ? true : false}
                        className={classes.menuItem}
                        onClick={() => navigateTo('/projectreportoverview')}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <FormatListNumberedIcon></FormatListNumberedIcon>
                        </span>
                        <span className={classes.menuText}>Project overview</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/results' ? true : false}
                        className={classes.menuItem}
                        onClick={navigateResults}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <BarChartIcon></BarChartIcon>
                        </span>
                        <span className={classes.menuText}>Results overview</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/user' ? true : false}
                        className={classes.menuItem}
                        onClick={navigateUserSettings}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <ManageAccountsIcon></ManageAccountsIcon>
                        </span>
                        <span className={classes.menuText}>User settings</span>
                    </MenuItem>
                    <MenuItem
                        selected={location === '/dropdownoverview' || location === '/createdropdown' ? true : false}
                        className={classes.menuItem} onClick={handleOpenSettings}
                        divider={true}>
                        <span className={classes.iconItem}>
                            <EngineeringIcon></EngineeringIcon>
                        </span>
                        <span className={classes.menuText}>Configuration</span>
                        {openCollapse ? <ExpandLess className={classes.toggleArrow} /> : <ExpandMore className={classes.toggleArrow} />}

                    </MenuItem>
                    <Collapse in={location === '/createdropdown' ? true : openCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}
                                selected={location === '/createdropdown' ? true : false}
                                onClick={() => navigateTo('/createdropdown')} divider={true}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PlaylistAddIcon></PlaylistAddIcon>

                                </ListItemIcon>

                                <ListItemText className={classes.listItemText} primary="Create a dropdown" />
                            </ListItem>
                            <ListItem button className={classes.nested}
                                selected={location === '/dropdownoverview' ? true : false}
                                onClick={() => navigateTo('/dropdownoverview')} divider={true}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AssignmentOutlinedIcon></AssignmentOutlinedIcon>

                                </ListItemIcon>

                                <ListItemText className={classes.listItemText} primary="Dropdown overview" />
                            </ListItem>

                            <ListItem button className={classes.nested}
                                selected={location === '/createapi' ? true : false}
                                onClick={() => navigateTo('/createapi')} divider={true}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <AddLinkIcon></AddLinkIcon>

                                </ListItemIcon>

                                <ListItemText className={classes.listItemText} primary="Create a API" />
                            </ListItem>

                            <ListItem button className={classes.nested}
                                selected={location === '/externalapioverview' ? true : false}
                                onClick={() => navigateTo('/externalapioverview')} divider={true}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LinkIcon></LinkIcon>

                                </ListItemIcon>

                                <ListItemText className={classes.listItemText} primary="API overview" />
                            </ListItem>
                        </List>
                    </Collapse>
                    {/*                     <MenuItem 
                        onClick={props.onClick} 
                        className={classes.menuItem} 
                        divider={true}>
                            <span className={classes.iconItem}>
                                <ManageAccountsIcon />
                            </span>
                            <span className={classes.menuText}>User settings</span>
                    </MenuItem>
                    <MenuItem onClick={props.onClick} className={classes.menuItem} divider={true}>
                    <span className={classes.iconItem}>

                        <ArticleOutlinedIcon />
                        </span>
                        <span className={classes.menuText}>Terms {"&"} Policy</span>
                    </MenuItem> */}
                    <MenuItem onClick={LogOut} style={{ color: "red" }} divider={true}>
                        <span className={classes.iconItem}>

                            <ExitToAppOutlinedIcon />
                        </span>
                        <span className={classes.menuText}>Logout</span>
                    </MenuItem>
                </div>
            </Drawer>
        </div>
    );
}

export default Menu
const useStyles = makeStyles({
    sideBar: {
        color: '#1D3557 !important',
        width: '300px',
    },
    titleHeader: {
        color: '#1D3557 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'around',
        marginLeft: '25px'
    },
    drawerItems: {

    },
    menuItem: {
        marginBottom: '15px',
        color: 'rgb(105, 105, 105) !important',
        // borderTop: '1px solid grey',
    },
    menuText: {
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '15px',
    },
    iconItem: {
        display: 'flex',
        justifyContent: 'center',
        width: '30px',
        paddingRight: '10px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        fontSize: '25px !important'
    },
    ImgItem: {
        display: 'flex',
        justifyContent: 'center',
        width: '30px',
        paddingRight: '10px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    selectedItem: {
        backgroundColor: 'red !important',
    },
    toggleArrow: {
        float: 'right',
        marginRight: '0',
        marginLeft: 'auto'
    },
    listItemIcon: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        paddingRight: '10px',

        justifyContent: 'end'

    },
    listItemText: {
        color: 'rgb(105, 105, 105) !important',

        paddingLeft: '10px'
    },
    imageStyle: {
        filter: 'contrast(80%)',
        opacity: '75%',
        width: '30px'
    },
});