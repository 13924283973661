import * as types from './ProjectCopyActionTypes.js';

export const companyAction = (company) => {
    return {
        type: types.SET_COMPANY,
        payload: company
    };
};

export const companyIdAction = (companyId) => {
    return {
        type: types.SET_COMPANYID,
        payload: companyId
    };
};

export const resourceAction = (resource) => {
    return {
        type: types.SET_RESOURCE,
        payload: resource
    };
};

export const fromDateAction = (fromDate) => {
    return {
        type: types.SET_FROMDATE,
        payload: fromDate
    };
};

export const toDateAction = (toDate) => {
    return {
        type: types.SET_TODATE,
        payload: toDate
    };
};

export const detailsAction = (details) => {
    return {
        type: types.SET_DETAILS,
        payload: details
    };
};

export const projectIdAction = (projectId) => {
    return {
        type: types.SET_PROJECTID,
        payload: projectId
    };
};

export const projectNameAction = (projectName) => {
    return {
        type: types.SET_PROJECTNAME,
        payload: projectName
    };
};

export const projectQrAction = (projectQr) => {
    return {
        type: types.SET_PROJECTQR,
        payload: projectQr
    };
};

export const getAllTypes = (types) => {
    return {
        type: types.SET_TYPES,
        payload: types
    };
};

export const projectReportAction = (report) => {
    return {
        type: types.SET_PROJECTREPORT,
        payload: report
    };
};
