import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@mui/material';
import EditExternalApisButton from './EditExternalApisButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import DescriptionItem from '../../../Shared/DescriptionItem';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Snackbar from '@mui/material/Snackbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import FilterListRow from '../../../Shared/Tables/FilterListRow';
import 'react-toastify/dist/ReactToastify.css';

function Row(props) {
    const classes = useStyles();
    const row = props.row;
    const [open, setOpen] = useState(false);
    const [apiExamplePoints, setApiExamplePoints] = useState("");
    const [openToast, setOpenToast] = useState(false);
    const handleCopyFormat = () => {
        handleOpenToast();
        navigator.clipboard.writeText(apiExamplePoints);
    };

    const handleOpenToast = () => {
        setOpenToast(true);
    };

    const handleCloseToast = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const handleOpen = () => {
        var apiFormat = JSON.parse(row.apiFormat);
        var ExampleApiValuesMapped = apiFormat.map(item => ({ [item.Name]: "Your data here" }))
        var NewExampleApiValues = JSON.stringify(Object.assign({}, ...ExampleApiValuesMapped), null, 2);
        setApiExamplePoints(NewExampleApiValues)
        setOpen(!open);
    }

    return (
        <React.Fragment>
            <tr className={classes.tableBodyRow} key={"thow"} style={props.index % 2 ? { background: "#e6ecf0" } : { background: "white" }}>
                <td>
                    <IconButton style={{ borderRadius: 0 }}
                        aria-label="expand row"
                        size="small"
                        onClick={handleOpen}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </td>
                <td>
                    {row.name}
                </td>
                <td>
                    {row.description}
                </td>
                <td><EditExternalApisButton {...props} style={{ borderRadius: 0 }} data={
                    ({
                        externalApiName: props.row.name,
                        externalApiDescription: props.row.description,
                        externalApiFormat: props.row.apiFormat,
                        externalApiKey: props.row.apiKey,
                        externalApiId: props.row.id                        
                    })} />
                </td>
            </tr>

            <tr style={props.index % 2 ? { background: "#e6ecf0" } : { background: "white" }}>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {row.name}
                            </Typography>
                            <DescriptionItem Text="Api format example" />
                            <div className={classes.ApiContainer}>
                                <TextField fullWidth disabled multiline value={apiExamplePoints} label={apiExamplePoints ? " " : "API Example"} InputLabelProps={{ shrink: false }} style={{ marginRight: "5px" }} />
                                {apiExamplePoints !== "" ?
                                    <Button size='large' variant="contained" onClick={handleCopyFormat} style={{ background: "#C3996B", color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                                    :
                                    <Button disabled size='large' variant="contained" onClick={handleCopyFormat} style={{ color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                                }
                            </div>
                        </Box>
                    </Collapse>
                </td>
            </tr>
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message="Copied to clipboard"
            />
        </React.Fragment>
    );
}

export default function TableExternalApi(props) {
    const classes = useStyles();
    return (
        <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
            <Table size="sm" key={"table"}>
                <TableHead className={classes.headStyle} key={"thead"}>
                    <TableRow className={classes.tableBodyRow} key={"throw"}>
                        <th className={classes.tableHead} style={{ width: '2%' }} />
                        <th className={classes.tableHead} >
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('name')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    API Name
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    API Description
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '2%' }}>
                            Actions
                        </th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.filterOn && <FilterListRow rows={props.rows} filters={props.filters} filterList={props.filterApi} updateFilters={props.updateFilters}></FilterListRow>}
                    {props.rows.map((row, index) => (
                        <Row {...props} row={row} index={index} className={classes.tableBodyRow} />
                    ))}
                    {props.rows.length === 0 &&
                        <tr className={classes.tableBodyRow}>
                            <td colSpan={4}>No connection available</td>
                        </tr>}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles((theme) => ({
    tableHead: {
        height: '42px',
    },
    titleSearch: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headStyle: {
        backgroundColor: "#e0dce4",
    },
    tableBodyRow: {
        justifyContent: "center",
        textAlign: "center",
    },
    tableRow: {
        borderBottom: '2px solid #e0dce4',
    },
    actionButtons: {
        padding: '0px !important',
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
    tableFooter: {
        width: '100%'
    },
    footer: {
        display: 'flex',
    },
    icons: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ApiContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'flex-end',
    },
}));

