import React, { useState, useRef } from 'react';
import { useReactToPrint, ReactToPrint } from 'react-to-print';
import CopyQrLink from './CopyQrLink';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import QRCode from "qrcode.react";
import ApiHelper from '../../Utils/API/apiHelper';
import IconButton from '@mui/material/IconButton';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Dialog from '@mui/material/Dialog';
import PrintIcon from '@mui/icons-material/Print';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';

function QrButton(props) {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const componentRef = useRef();
    const appSettings = ApiHelper.appSettings;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    class ComponentToPrint extends React.Component {
        render() {
            return (
                <div className='print-source' style={{ position: 'relative', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <h1 style={{ marginBottom: "20px" }}>{props.projectName}</h1>
                    <QRCode
                        id="Qr"
                        value={appSettings.TAPLR_BASE_URL + "perfmeasurement?qr=" + props.QrCode}
                        bgColor={"#ffffff"}
                        renderAs={"svg"}
                        size={400} />
                    <div style={{ position: 'relative', width: '400px' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>Powered by Flagstone</div>
                    </div>
                </div>
            );
        }
    }





    const actionStyle = {
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    }

    const contentStyle = {
        textAlign: "center",
        justifyContent: "center",
        margin: '15px',
    }

    return (
        <div>
            <IconButton style={{ borderRadius: 0 }} disabled={props.disabled} onClick={handleClickOpen} size='large'>
                <QrCode2Icon fontSize="inherit" />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.modalQRCode}
                maxWidth={'lg'}
            >
                <DialogContent style={contentStyle}>
                    <ComponentToPrint ref={el => (componentRef.current = el)} />
                    <CopyQrLink QrCode={props.QrCode} value={appSettings.TAPLR_BASE_URL + "perfmeasurement?qr=" + props.QrCode} />
                </DialogContent>
                <DialogActions style={actionStyle} className={classes.buttonRow}>
                    <Button size='large' className={classes.doneButton} onClick={handleClose} variant="contained">Close</Button>
                    <Button size='large' className={classes.printButton} type={props.type} onClick={handlePrint} variant="contained"><PrintIcon className={classes.printIcon}></PrintIcon>Print QR-code</Button>
                    <Link to={"perfmeasurement?qr=" + props.QrCode} style={{ textDecoration: 'none', marginLeft: '10px' }}>
                        <Button className={classes.printButton} size="large" variant="contained"><ViewComfyIcon className={classes.printIcon}></ViewComfyIcon>{props.content}View</Button>
                    </Link>
                </DialogActions>



            </Dialog>
        </div>
    )

}

export default QrButton;

const useStyles = makeStyles({
    buttonRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        marginBottom: '10px',
    },
    qrCode: {
        marginLeft: '50px',
        marginTop: '25px',
        marginRight: '50px',
        marginBottom: '25px',

    },
    printIcon: {
        paddingRight: '5px',
    },
    doneButton: {
        backgroundColor: '#C3996B !important',
    },
    printButton: {
        backgroundColor: '#0B4097 !important',
    }
});
