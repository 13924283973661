import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

export default function ButtonClose(props) {

    const classes = useStyles();

    return (
        <button className={classes.closeButton} onClick={() => props.closeDialog()}>{props.text}</button>
    );
}


const useStyles = makeStyles(() => ({

    closeButton: {
        border: "1px solid #E8E8E8",
        outline:"none",
        cursor: "pointer",
        backgroundColor: "white",
        margin: "10px",
        fontFamily: "Lato, sans-serif",
        height:"30px",

    }
}));
