import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { dataService } from '../../../../Services/data/data.service';

function DropdownLocations(props) {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    let tempLocations = [];
    if (props.projectCopy === null) {
      dataService.getProjectLocationsByUserId(props.Login.userid, props.fromDate, props.toDate).then(result => {
        if (result.length > 0) {
          tempLocations = result.map(x => ({ location: x.resourceDescription }));
          setLocations(result.map(x => ({ location: x.resourceDescription })));
          if (props.projectCopy !== null && props.projectCopy !== undefined && tempLocations.length > 0 && props.projectCopy.projectId !== null) {
            let index = tempLocations.find(x => x.location === props.projectCopy.resource);
            if (index) {
              setSelectedLocation(index.location);
            }
          }
        }
      });
    } else {
      tempLocations.push({location: props.projectCopy.resource})
      setLocations(tempLocations);
      if (props.projectCopy !== null && props.projectCopy !== undefined && props.projectCopy.projectId !== null) {
        setSelectedLocation(props.projectCopy.resource);
      }
    }


  }, [props.refresh])

  return (
    <div>
      {
        locations.length > 0 && selectedLocation !== null ?
          <div>
            <Autocomplete
              multiple
              disablePortal
              options={locations}
              limitTags={1}
              style={{ minWidth: "200px", maxWidth: "200px" }}
              onChange={(event, newValue) => {
                props.action(newValue)
              }}
              inputValue={selectedLocation}

              getOptionLabel={(option) => option.location || ""}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          :
          <div>
            <Autocomplete
              multiple
              disablePortal
              options={locations}
              limitTags={1}
              style={{ minWidth: "200px", maxWidth: "200px" }}
              onChange={(event, newValue) => {
                props.action(newValue)
              }}

              getOptionLabel={(option) => option.location || ""}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
      }</div>
  );

}

export default DropdownLocations