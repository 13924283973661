import * as types from './ProjectCopyActionTypes.js';

const initialState = {
    company: "",
    companyId: "",
    resource: "",
    fromDate: null,
    toDate: null,
    details: [],
    projectId: "",
    projectName: "",
    projectQr: null,
    report: false,
};

export default function projectCopy(state = initialState, action) {
    switch (action.type) {
        case types.SET_COMPANY:
            if (action.payload !== state.company) {
                return Object.assign({}, state, { company: action.payload });
            }
            return state;
        case types.SET_COMPANYID:
            if (action.payload !== state.companyId) {
                return Object.assign({}, state, { companyId: action.payload });
            }
            return state;
        case types.SET_RESOURCE:
            if (action.payload !== state.resource) {
                return Object.assign({}, state, { resource: action.payload });
            }
            return state;
        case types.SET_FROMDATE:
            if (action.payload !== state.fromDate) {
                return Object.assign({}, state, { fromDate: action.payload });
            }
            return state;
        case types.SET_TODATE:
            if (action.payload !== state.toDate) {
                return Object.assign({}, state, { toDate: action.payload });
            }
            return state;
        case types.SET_DETAILS:
            if (action.payload !== state.details) {
                return Object.assign({}, state, { details: action.payload });
            }
            return state;
        case types.SET_PROJECTID:
            if (action.payload !== state.projectId) {
                return Object.assign({}, state, { projectId: action.payload });
            }
            return state;
        case types.SET_PROJECTNAME:
            if (action.payload !== state.projectName) {
                return Object.assign({}, state, { projectName: action.payload });
            }
            return state;
        case types.SET_PROJECTQR:
            if (action.payload !== state.projectQr) {
                return Object.assign({}, state, { projectQr: action.payload });
            }
            return state;
        case types.SET_PROJECTREPORT:
            if (action.payload !== state.report) {
                return Object.assign({}, state, { report: action.payload });
            }
            return state;
        default: {
            return state;
        }
    }
}