import React, { useState, useEffect } from "react";
import TitlePage from "../../../Shared/TitlePage";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { getAuth, sendEmailVerification, updatePassword, onAuthStateChanged } from "firebase/auth";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { dataService } from '../../../../Services/data/data.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

toast.configure();

function UserSettingsPage(props) {
    const classes = useStyles();
    const [verified, setVerified] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [lastName, setLastName] = useState(props.Login.lastName);
    const [firstName, setFirstName] = useState(props.Login.userName);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // ...
                setVerified(user.emailVerified);
            } else {
                // User is signed out
                // ...
            }
        });
        let user = auth.currentUser;

       

    }, []);
    console.log('verified ', verified)
    const handleCheckBox = (e) => {
        setChangePassword(!changePassword);
    }
    const handleVerificationMail = () => {
        const tempAuth = getAuth();

        sendEmailVerification(tempAuth.currentUser)
            .then(() => {
                // Email verification sent!
                // ...
            });
    }
    const handleSaveChanges = () => {
        const auth = getAuth();

        const user = auth.currentUser;
        if (changePassword === true) {
            updatePassword(user, password).then(() => {
                // Update successful.
            }).catch((error) => {
                // An error ocurred
                // ...
            });
        }
        if (firstName !== "" || lastName !== "") {
            dataService.updateUserData(user.email, firstName, lastName).then(response => {
                if (response.success === true) {
                    props.actions.setUserName(firstName);
                    props.actions.setLastName(lastName);
                    toast.success('User updated succefully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                } else {
                    toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                }


            })
        }

    }

    const changeLastName = (event) => {
        setLastName(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }


    return (
        <div>
            <TitlePage Text="User settings" backbutton={true} {...props} />
            <div className={classes.container}>
                {verified ? <div className={classes.verifiedRow}>
                    <p className={classes.verifiedText}>Verified user: <CheckCircleOutlineIcon color={'success'} style={{ marginLeft: '10px' }}></CheckCircleOutlineIcon></p>
                </div> :
                    <div className={classes.verifiedRow}>
                        You're not verified yet!: <Button variant="contained" className={classes.buttonStyle} onClick={() => handleVerificationMail()} >Resend mail?</Button>
                    </div>

                }


                <div className={classes.row}>
                    <span className={classes.labelText}>Email</span>
                    <TextField
                        className={classes.textField}
                        type={"text"}
                        variant="outlined"
                        value={props.Login.email}
                        disabled={true}
                        fullWidth={true}

                    >
                    </TextField>
                </div>
                <div className={classes.row}>
                    <span className={classes.labelText}>First name</span>
                    <TextField
                        className={classes.textField}
                        type={"text"}
                        variant="outlined"
                        onChange={(event) => changeFirstName(event)}

                        value={firstName}
                        fullWidth={true}

                    >
                    </TextField>
                </div>
                <div className={classes.row}>
                    <span className={classes.labelText}>Last name</span>
                    <TextField
                        className={classes.textField}
                        type={"text"}
                        variant="outlined"
                        onChange={(event) => changeLastName(event)}

                        value={lastName}
                        fullWidth={true}
                    >
                    </TextField>
                </div>
                <div className={classes.row}>
                    <span className={classes.labelText}>Change password <Checkbox style={{ color: '#C3996B' }} onChange={(e) => handleCheckBox(e)}></Checkbox></span>

                    <TextField
                        className={changePassword === false ? classes.disabledTextField : classes.textField}
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        value={password}
                        onChange={(event) => handleChangePassword(event)}

                        fullWidth={true}
                        disabled={changePassword === false}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <LockIcon style={{ marginRight: '10px' }}></LockIcon>
                                </InputAdornment>,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>)
                        }}
                    >
                    </TextField>
                </div>
                <Button size='large' className={classes.logInButton} variant="contained" onClick={() => handleSaveChanges()}>Save changes</Button>

            </div>

        </div>
    )
}


export default UserSettingsPage;

const useStyles = makeStyles({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    }, verifiedRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        alignItems: 'center'
    }, buttonStyle: {
        marginLeft: '15px !important',
        backgroundColor: '#C3996B !important',
    }, labelText: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '10px',
        marginTop: '20px',
        fontWeight: '400',
        fontSize: '1rem',
        color: 'rgb(105, 105, 105) !important'

    }, verifiedText: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: '400',
        fontSize: '1rem',
        color: 'rgb(105, 105, 105) !important'

    }, logInButton: {
        backgroundColor: "#C3996B !important",
        marginTop: '15px !important',
        display: 'flex'
    },
    titleTextStyle: {
        marginLeft: "20px",
    },
    titleSection: {
        width: "100%",
    },
    titleStyle: {
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#FFFFFF",
        justifyContent: "space-between"
    },
    dateAndCreateDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        flexWrap: 'wrap',


    },
    activeFor: {
        color: "#696969",
        fontSize: "15px",
    },
    backIcon: {
        padding: "20px",
        cursor: "pointer"
    },
    iconItem: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
    textField: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    disabledTextField: {
        backgroundColor: '#dddddd'
    }


});