import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

function DropdownCompanyAdd(props) {

  const dropdownList = props.companies.map(x => { return { label: x.name, value: x.id } })
  const filter = createFilterOptions();

  return (
    <Autocomplete
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      freeSolo
      value = {props.value}
      options={dropdownList.sort((a, b) => a.label.localeCompare(b.label))}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      inputValue={props.value? props.value:""}
      onInputChange={(e) => props.action(e)}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          props.action(newValue)
          props.action2(newValue)
        } else if (newValue && newValue.inputValue) {
          props.action2(newValue)
          props.action(newValue)
        } else {
          props.action(event)
        }

      }} renderInput={(params) => <TextField
        error={props.error}
        helperText={props.helperText} {...params} InputLabelProps={{ shrink: false }} label={props.value ? " " : "Company"} onChange={(event) => props.action(event)} />}
    />
  );
}

export default DropdownCompanyAdd