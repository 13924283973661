import React, { useState, useEffect } from 'react';
import {
    Chart,
    Title,
    CommonSeriesSettings,
    Series,
    ArgumentAxis,
    ValueAxis,
    Format,
    Label,
    Tooltip,
    Export,
    Crosshair,
    TickInterval,
} from 'devextreme-react/chart';
import Button from 'devextreme-react/button';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export default function FinancialChart(props) {
    const [chartRef, setChartRef] = useState(null);

    useEffect(() => {
        let chartRef = React.createRef();
        setChartRef(chartRef);
    }, []);

    function customizeAnnotation(pointinfo) {
        let color = pointinfo.data.color;
        return {
            color: color
        }
    }

    function customizeTooltip(pointinfo) {
        let seriesName = pointinfo.seriesName;
        let htmlText = '';
        htmlText += '<b style="text-style: underline">' + seriesName + '</b><br/>';
        // if(seriesName === 'Total'){
        let imgUrl = '';
        if (pointinfo.point.data !== null) {
            if (pointinfo.point.data.isProfit) {
                imgUrl = process.env.PUBLIC_URL + "/images/greenarrow.png";
            } else {
                imgUrl = process.env.PUBLIC_URL + "/images/arrowdown.png";
            }
            let valueTextConverted = pointinfo.valueText.replace(',', '');
            let priceConverted = pointinfo.point.data.price;
            htmlText += '<span><img style="width: 15px, height: 15px" src=' + imgUrl + ' alt="Taplr Logo"></img> € ' + parseFloat(valueTextConverted).toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2}) + '</span> <br/>'
                    if(seriesName === 'Total'){
                        htmlText += '<small>' + (pointinfo.point.data.originalMeasurement) + '</small>'
                    }
            htmlText += '<small>' + (pointinfo.point.data.thismeasurement !== undefined ? pointinfo.point.data.thismeasurement : '') + ' (' + (pointinfo.point.data.isProfit ? ' + ' : ' - ') + '€' + priceConverted.toLocaleString('nl-BE', {minimumFractionDigits: 2,maximumFractionDigits: 2})  + ')</small>';
        }
        return {
            html: htmlText,
        };
    }
    function legendClickHandler(e) {
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }
    function customizePoint(e) {
        let color = e.data.color;

        return {
            color: color,
            size: 6,
        }


    }
    function customizeSeriesPoint(e) {
        let color = e.data.color;
        return {
            visible: false
        }

    }
    function customizeCrosshairLabel(e) {
        if(typeof e.value === 'number'){
            return '€ ' + e.point.originalValue.toFixed(2).replace('.', ',');

        }else{
            return (new Date(e.point.data.date)).toTimeString().split(' ')[0];
        }

    }
    return (
        <div style={{ height: '100%', backgroundColor: 'white', padding: '20px', borderRadius: '8px', margin: '15px' }}>
            {props.financialChartData !== null && props.projectDetailNames.length > 0 ?
                <Chart id="chart" style={{ width: '98%' }} dataSource={props.financialChartData.filter(x => x.isVisible)} onLegendClick={legendClickHandler} customizePoint={customizePoint} customizeAnnotation={customizeAnnotation}>
                    <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                        <Format type="fixedPoint" precision={2} />
                    </Tooltip>
                    <Title text="Financial overview" subtitle={props.project.projectName} />
                    <CommonSeriesSettings argumentField="date" type="line" />
                    <Format type="fixedPoint" precision={2} />
                    {props.projectDetailNames.map(x =>
                        <Series valueField={x.name + '_price'} name={x.name} color={x.color} customizePoint={customizeSeriesPoint} key={x.name}>
                            {/* <Label visible={true} >
                            <Connector visible={true} />
                        </Label> */}
                        </Series>
                    )}
                    <ArgumentAxis argumentType="datetime" />
                    <ValueAxis position="right">
                        <TickInterval hours={1}></TickInterval>
                    </ValueAxis>
                    <Export enabled={true} />

                    {/* <ZoomAndPan
                    valueAxis="both"
                    argumentAxis="both"
                    dragToZoom={true}
                    allowMouseWheel={true}
                    panKey="shift" /> */}
                    <Crosshair
                        enabled={true}>
                        <Label visible={true} customizeText={customizeCrosshairLabel} />
                    </Crosshair>
                </Chart>
                : ''}

        </div>
    );

}
