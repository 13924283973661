import React from 'react';
import { useState } from 'react';
import Divider from '@mui/material/Divider';
import { TextField } from '@mui/material';
import TextIcon from './Freetext.svg';
import { makeStyles } from "@material-ui/core/styles";
import { LightenDarkenColor } from 'lighten-darken-color';
import Card from '@mui/material/Card';

function FreeText(props) {
        
    function getRGB(c) {
        return parseInt(c, 16) || c
    }

    function getsRGB(c) {
        return getRGB(c) / 255 <= 0.03928
            ? getRGB(c) / 255 / 12.92
            : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
    }

    function getLuminance(hexColor) {
        if (hexColor.includes('#')) {
            return (
                0.2126 * getsRGB(hexColor.substr(1, 2)) +
                0.7152 * getsRGB(hexColor.substr(3, 2)) +
                0.0722 * getsRGB(hexColor.substr(-2))
            )
        } else {
            return (
                0.2126 * hexColor +
                0.7152 * hexColor +
                0.0722 * hexColor
            )
        }

    }

    function getContrast(f, b) {
        const L1 = getLuminance(f)
        const L2 = getLuminance(b)
        return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
    }

    function getTextColor(bgColor) {

        if (bgColor === null) {
            return '#ffffff';
        } else {
            const whiteContrast = getContrast(bgColor, '#ffffff')
            const blackContrast = getContrast(bgColor, '#000000')
            return whiteContrast > blackContrast ? '#ffffff' : '#000000';

        }

    }

    const classes = useStyles();
    const [extraContent, setExtraContent] = useState(false)

    return (
        <Card>
            <div className={classes.freeText} style={{
                color: props.textColor,
                backgroundColor: `${props.color}`
            }}>
                <div className={classes.headerDiv}>
                    <h2 className={classes.h2style}>{props.description}</h2>
                    <img className={classes.imageStyle} src={TextIcon} alt="" />
                </div>
                <Divider></Divider>
                <div className={classes.textDiv} style={{
                    backgroundColor: LightenDarkenColor(props.color, 20)
                }}>
                    <TextField InputProps={{style: {color: getTextColor(props.color)}}} onChange={(event) => props.setText(event.target.value, props.id)} autoFocus margin="dense" id={props.description} type="string" fullWidth value={props.text !== null ? props.text : ''} variant="standard" />
                </div>
            </div>
        </Card>
    );
}


export default FreeText;

const useStyles = makeStyles({

    freeText: {
        overflow: 'hidden',
        backgroundRepeat: "no-repeat",
        backgroundSize: "10rem 10rem",
        backgroundPosition: "center",
        height: "12rem",

    },
    headerDiv: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
    },
    h2style: {
        margin: '0',
        zIndex: '2',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    imageStyle: {
        position: 'absolute',
        right: '3%',
        filter: 'contrast(80%)',
        opacity: '50%'
    },
    textDiv: {
        paddingLeft: '15px',
        paddingRight: '15px',
        display: "flex",
        justifyContent: "center",
        height: '34%',
        alignItems: 'center',
    }

});