
import { handleResponse } from "../../Utils/API/handle-reponse";
import * as apiHelper from '../../Utils/API/apiHelper';

export const dataService = {
    login,
    getCompanies,
    getResources,
    getTypes,
    createNewProject,
    saveCompany,
    findProjects,
    updateProjects,
    getMeasurement,
    getMeasurementByIdAndDate,
    getProjectDetails,
    getProjectDetailsByQr,
    getAllProjectInfoByProjectID,
    saveText,
    saveTime,
    saveCount,
    getName,
    saveMeasurement,
    saveMeasurementWithComment,
    getMeasurementById,
    getMeasurementForModify,
    deleteProject,
    getByProjectID,
    saveAndResetMeasurements,
    createNewDataList,
    getLists,
    deleteDataList,
    modifyDataList,
    getListItems,
    newMediaImage,
    newMediaImageWithComment,
    GetImageById,
    getSystemParameters,
    getUserByEmail,
    updateUserData,
    createUser,
    createNewApi,
    getApis,
    modifyApi,
    deleteApi,
    getProjectLocationsByUserId,
    getProjectNamesByUserId,
    getMeasurementsByUserIdDateLocationName,
    getProjectDetailWithComments,
    getProjectAndDetails,
    getProjectByProjectIdUserId,
    requestLogin,
    getAllUserCompanyProjects,
}

export function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    };

    let url = apiHelper.ComposeUri(apiHelper.LOGIN_URL);
    return fetch(url, requestOptions).then(handleResponse);
}

export function requestLogin(firstName, lastName, email) {
    console.log(firstName, lastName, email)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            lastName: lastName,
            firstName: firstName
        })
    };

    let url = apiHelper.ComposeUri(apiHelper.REQUESTLOGIN_URL);
    console.log(url, requestOptions);
    return fetch(url, requestOptions).then(handleResponse);
}

export function createNewProject(GetUserID, ProjectName, ResourceName, CompanyId, CompanyName, FromDate, ToDate, projectrows) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            UserId: GetUserID,
            ProjectName: ProjectName,
            ResourceName: ResourceName,
            CompanyId: CompanyId,
            CompanyName: CompanyName,
            FromDate: FromDate,
            ToDate: ToDate,
            Details: projectrows
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.CREATENEWPROJECT);
    return fetch(url, requestOptions).then(handleResponse);
}

export function getUserByEmail(email) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETUSERBYEMAIL_URL);
    url += "?email=" + email

    return fetch(url, requestOptions).then(handleResponse);
}

export function getCompanies(userId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETCOMPANIES_URL);
    url += "?userId=" + userId

    return fetch(url, requestOptions).then(handleResponse);
}

export function getResources(userId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETRESOURCES_URL);
    url += "?userId=" + userId

    return fetch(url, requestOptions).then(handleResponse);
}

export function getTypes() {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETTYPES_URL);
    
    return fetch(url, requestOptions).then(handleResponse);
}

export function saveCompany(companyName, GetUserID) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: companyName,
            UserId: GetUserID
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.SAVECOMPANY);

    return fetch(url, requestOptions).then(handleResponse);
}

export function findProjects(CompanyId, GetUserID, FromDate, ToDate, ResourceName) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTS_URL);
    url += "?UserId=" + GetUserID + "&CompanyId=" + CompanyId + "&FromDate=" + FromDate + "&ToDate=" + ToDate + "&ResourceName=" + ResourceName

    return fetch(url, requestOptions).then(handleResponse);
}

export function updateProjects(GetUserID, ProjectName, ResourceName, OldCompanyId, CompanyId, CompanyName, FromDate, ToDate, projectrows, ProjectId, ProjectQr) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectId: ProjectId,
            UserId: GetUserID,
            ProjectName: ProjectName,
            ResourceName: ResourceName,
            OldCompanyId: OldCompanyId,
            CompanyId: CompanyId,
            CompanyName: CompanyName,
            FromDate: FromDate,
            ToDate: ToDate,
            QrCode: ProjectQr,
            Details: projectrows
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.UPDATEPROJECTS_URL);
    return fetch(url, requestOptions).then(handleResponse);
}

export function updateUserData(email, firstName, lastName) {
    console.log(email, firstName, lastName)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Email: email,
            FirstName: firstName,
            LastName: lastName,
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.UPDATEUSERDATA_URL);
    
    return fetch(url, requestOptions).then(handleResponse);
}

export function createUser(email,  firstName, lastName, uid) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: lastName,
            FirstName: firstName,
            EmailAddress: email,
            FireBaseId: uid,
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.CREATEUSER_URL);
    
    return fetch(url, requestOptions).then(handleResponse);
}

export function getMeasurement(userId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENT_URL);
    url += "?userId=" + userId
    return fetch(url, requestOptions).then(handleResponse);
}

export function getMeasurementForModify(userId, FromDate, ToDate,) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENTFORMODIFY_URL);
    url += "?userId=" + userId + "&FromDate=" + FromDate + "&ToDate=" + ToDate
    return fetch(url, requestOptions).then(handleResponse);
}
export function getAllProjectInfoByProjectID(projectId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETALLPROJECTINFOBYPROJECTID);
    url += "?projectId=" + projectId;
    return fetch(url, requestOptions).then(handleResponse);
}
export function getProjectDetails(Qr) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTDETAILS_URL);
    url += "?qr=" + Qr
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectDetailsByQr(Qr) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTDETAILSBYQR_URL);
    url += "?qr=" + Qr
    return fetch(url, requestOptions).then(handleResponse);
}

export function saveText(projectDetailId, StartTime, TextValue) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: projectDetailId,
            StartTime: StartTime,
            TextValue: TextValue
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.SAVETEXT);

    return fetch(url, requestOptions).then(handleResponse);
}

export function saveTime(projectDetailId, StartTime, stopwatch) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: projectDetailId,
            StartTime: StartTime,
            DateValue: stopwatch
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.SAVETIME);

    return fetch(url, requestOptions).then(handleResponse);
}

export function saveCount(projectDetailId, StartTime, counterValue) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: projectDetailId,
            StartTime: StartTime,
            CounterValue: counterValue
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.SAVECOUNT);

    return fetch(url, requestOptions).then(handleResponse);
}

export function saveMeasurement(measurement) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: measurement.id,
            StartTime: measurement.startTime,
            Value: measurement.value,
            StartValue: measurement.startValue,
            StopValue: measurement.stopValue,
            Running: measurement.running,
            ControlTypeId: measurement.controlTypeId,
        })

    };

    let url = apiHelper.ComposeUri(apiHelper.SAVEMEASUREMENT);
    return fetch(url, requestOptions).then(handleResponse);
}

export function saveMeasurementWithComment(measurement) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: measurement.id,
            StartTime: measurement.startTime,
            Value: measurement.value,
            StartValue: measurement.startValue,
            StopValue: measurement.stopValue,
            Running: measurement.running,
            ControlTypeId: measurement.controlTypeId,
            Image: measurement.commentImage,
            Comment: measurement.commentText,
        })
    };

    let url = apiHelper.ComposeUri(apiHelper.SAVEMEASUREMENTWITHCOMMENT);
    return fetch(url, requestOptions).then(handleResponse);
}

export function saveAndResetMeasurements(measurements) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            measurements: measurements
        })
    };

    let url = apiHelper.ComposeUri(apiHelper.SAVEANDRESETMEASUREMENTS);
    return fetch(url, requestOptions).then(handleResponse);
}

export function getName(Qr) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETNAME_URL);
    url += "?Qr=" + Qr

    return fetch(url, requestOptions).then(handleResponse);
}

export function getMeasurementById(userId, ProjectId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENTBYID_URL);
    url += "?userId=" + userId + "&ProjectID=" + ProjectId
    return fetch(url, requestOptions).then(handleResponse);
}
export function getMeasurementByIdAndDate(userId, ProjectId, FromDate, ToDate) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENTBYIDANDDATE_URL);
    url += "?userId=" + userId + "&ProjectID=" + ProjectId + "&FromDate=" + FromDate + "&ToDate=" + ToDate
    return fetch(url, requestOptions).then(handleResponse);
}



export function getByProjectID(ProjectId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENTSBYPROJECTID_URL);
    url += "?ProjectID=" + ProjectId
    return fetch(url, requestOptions).then(handleResponse);
}

export function deleteProject(projectId, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectId: projectId,
            UserId: UserId
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.DELETEPROJECT);

    return fetch(url, requestOptions).then(handleResponse);
}

export function createNewDataList(Name, Description, InsertedOn, InsertedByUserId, DataListItems) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: Name,
            Description: Description,
            InsertedOn: InsertedOn,
            InsertedByUserId: InsertedByUserId,
            DataListItems, DataListItems
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.CREATENEWDATALIST);
    return fetch(url, requestOptions).then(handleResponse);
}

export function getLists(UserId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETLISTS);
    url += "?UserId=" + UserId
    return fetch(url, requestOptions).then(handleResponse);
}

export function deleteDataList(Name, Description, InsertedOn, InsertedByUserId, DataListItems, listId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: Name,
            Description: Description,
            InsertedOn: InsertedOn,
            InsertedByUserId: InsertedByUserId,
            DataListItems: DataListItems,
            Id: listId
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.DELETEDATALIST);
    return fetch(url, requestOptions).then(handleResponse);
}

export function modifyDataList(Name, Description, InsertedOn, InsertedByUserId, DataListItems, listId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: Name,
            Description: Description,
            InsertedOn: InsertedOn,
            InsertedByUserId: InsertedByUserId,
            DataListItems: DataListItems,
            Id: listId
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.MODIFYDATALIST);
    return fetch(url, requestOptions).then(handleResponse);
}

export function getListItems(dataListId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETLISTITEMS);
    url += "?dataListId=" + dataListId
    return fetch(url, requestOptions).then(handleResponse);
}


export function newMediaImage(InsertedOn, Image, ProjectDetailId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: ProjectDetailId,
            InsertedOn: InsertedOn,
            Image: Image
        })
    };
    let url = apiHelper.ComposeUri(apiHelper.NEWMEDIAIMAGE);
    return fetch(url, requestOptions).then(handleResponse);
}

export function newMediaImageWithComment(InsertedOn, Image, ProjectDetailId, CommentText) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ProjectDetailId: ProjectDetailId,
            InsertedOn: InsertedOn,
            Image: Image,
            Comment: CommentText,
        })
    };
    let url = apiHelper.ComposeUri(apiHelper.NEWMEDIAIMAGEWITHCOMMENT);
    return fetch(url, requestOptions).then(handleResponse);
}

export function GetImageById(ImageId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETIMAGEBYID);
    url += "?ImageId=" + ImageId
    return fetch(url, requestOptions).then(handleResponse);
}

export function getSystemParameters() {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETSYSTEMPARAMETERS);

    return fetch(url, requestOptions).then(handleResponse);
}

export function createNewApi(Name, Description, ApiKey, ApiFormat, InsertedByUserId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Name: Name,
            Description: Description,
            ApiKey: ApiKey,
            ApiFormat: ApiFormat,
            InsertedByUserId: InsertedByUserId,
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.CREATENEWAPI);
    return fetch(url, requestOptions).then(handleResponse);
}

export function modifyApi(ID, Name, Description, ApiKey, ApiFormat, UpdatedByUserId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID: ID,
            Name: Name,
            Description: Description,
            ApiKey: ApiKey,
            ApiFormat: ApiFormat,
            UpdatedByUserId: UpdatedByUserId,
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.MODIFYAPI);
    return fetch(url, requestOptions).then(handleResponse);
}

export function deleteApi(ID, Name, Description, ApiKey, ApiFormat, UpdatedByUserId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ID: ID,
            Name: Name,
            Description: Description,
            ApiKey: ApiKey,
            ApiFormat: ApiFormat,
            UpdatedByUserId: UpdatedByUserId,
        })

    };
    let url = apiHelper.ComposeUri(apiHelper.DELETEAPI);
    return fetch(url, requestOptions).then(handleResponse);
}
export function getApis(UserId) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETAPIS);
    url += "?UserId=" + UserId
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectLocationsByUserId(userId, FromDate, ToDate) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETLOCATION_URL);
    url += "?UserId=" + userId + "&FromDate=" + FromDate + "&ToDate=" + ToDate
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectNamesByUserId(userId, FromDate, ToDate) {

    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTNAMES_URL);
    url += "?UserId=" + userId + "&FromDate=" + FromDate + "&ToDate=" + ToDate
    return fetch(url, requestOptions).then(handleResponse);
}

export function getMeasurementsByUserIdDateLocationName(userId, FromDate, ToDate, Location, ProjectName) {
    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETMEASUREMENTBYDATELOCATIONNAME);
    url += "?UserId=" + userId + "&FromDate=" + FromDate + "&ToDate=" + ToDate + "&Location=" + Location + "&ProjectName=" + ProjectName
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectDetailWithComments(Qr) {
    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETDETAILMEASUREMENTSANDCOMMENTS);
    url += "?Qr=" + Qr
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectAndDetails(UserId, Page, PageSize) {
    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTSANDDETAILS);
    url += "?UserId=" + UserId + "&Page=" + Page + "&PageSize=" + PageSize
    return fetch(url, requestOptions).then(handleResponse);
}

export function getProjectByProjectIdUserId(ProjectId, UserId) {
    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETPROJECTBYPROJECTIDUSERID);
    url += "?ProjectId=" + ProjectId + "&UserId=" + UserId
    return fetch(url, requestOptions).then(handleResponse);
}

export function getAllUserCompanyProjects(UserId, Page, PageSize) {
    const requestOptions = { method: 'GET' };

    let url = apiHelper.ComposeUri(apiHelper.GETALLUSERCOMPANYPROJECTS);
    url += "?UserId=" + UserId + "&Page=" + Page + "&PageSize=" + PageSize
    return fetch(url, requestOptions).then(handleResponse);
}