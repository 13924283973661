import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Menu from './Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

function TitlePage(props) {
    const [menuState, setMenuState] = useState(false)

    const classes = useStyles();
    const history = useHistory();

    const Back = () => {
        if (history.location.pathname === '/create') {
            if (props.wipe) {
                props.actions.projectIdAction(null);
                props.actions.companyAction("");
                props.actions.companyIdAction("");
                props.actions.resourceAction("");
                props.actions.fromDateAction(null);
                props.actions.toDateAction(null);
                props.actions.detailsAction([]);
                props.actions.projectIdAction("");
                props.actions.projectNameAction("");
                props.actions.projectQrAction(null);
            }
        }

        history.goBack();
    }

    const openMenu = () => {
        setMenuState(true);
    }
    const closeMenu = () => {
        setMenuState(false);
    }
    const navigateTo = (destination) => {
        history.push(destination);
    }

    return (
        <div className={classes.titleSection}>

            <div className={classes.titleStyle}>
                <div className={classes.headerLeft}>
                    {props.backbutton === true ?
                        <IconButton onClick={Back} className={classes.arrowBack} >
                            <ArrowBackIosIcon color='white' style={{ color: "white" }} />
                        </IconButton> : <React.Fragment></React.Fragment>}
                    <h1 className={props.backbutton === false ? classes.hstylewithoutbutton : classes.hstyle} >{props.Text}</h1>
                </div>
                <div className={classes.headerMiddle}>
                    <img onClick={() => navigateTo('/')} className={classes.taplrLogo} src={process.env.PUBLIC_URL + "/images/Taplr.png"} alt="Taplr Logo"></img>
                </div>

                <div className={classes.headerRight}>
                    <Menu {...props} open={menuState} close={closeMenu}></Menu>
                    <div className={classes.aubWerk}>
                        {"Hi, " + props.Login.userName}
                    </div>
                    <IconButton onClick={openMenu} size="large" style={{ color: 'white' }} className={classes.toggleButton}>
                        <MenuIcon fontSize="inherit" />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default TitlePage;
const useStyles = makeStyles((theme) => ({

    titleTextStyle: {
        marginLeft: "20px",
    },

    hstyle: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('md')]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.2rem",

        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white'
    },

    hstylewithoutbutton: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('md')]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "1.5rem",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.2rem",
        },
        marginLeft: '54px',

        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white'
    },

    titleStyle: {
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        position: 'fixed',
        zIndex: '955',
        top: '0',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '100px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px',
            flexDirection: 'column-reverse',
        },


    },
    headerLeft: {
        paddingLeft: "5%",
        alignItems: 'center',
        display: 'flex',
        width: '33%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: "0%",
            position: "absolute",
            top: '55px',
            justifyContent: 'center'
        },
        color: 'white'

    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
            position: "absolute",
            top: '5px',
            height: 'auto'
        },

    },
    aubWerk: {
        display: 'flex',
        fontSize: '1.2rem',

        justifyContent: 'center !important',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',

        },
        alignItems: 'center !important'


    },
    headerRight: {
        width: '33%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'fixed',
            top: '105px',
            paddingRight: '5px'
        },

        display: 'flex',
        justifyContent: 'end',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            paddingRight: "5%",
        },
    }, toggleButton: {
        [theme.breakpoints.down('sm')]: {
            background: "#C3996B !important",
        },
    },
    arrowBack: {
        zIndex: '999',
        color: 'white',
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            position: 'absolute !important',
            left: '25px',
            fontSize: '50px !important',
            top: '-50%'
        },
        cursor: 'pointer'
    },
    taplrLogo: {
        marginTop: '15px',
        marginBottom: '15px',
        width: '150px',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            height: 'auto',
            maxHeight: '46px'
        },
        cursor: 'pointer',
    },
}));