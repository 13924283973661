import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import * as Actions from '../../../../Reducers/actions'
import { useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { dataService } from "../../../../Services/data/data.service";


function ProjectButton(props) {
    const [row, setRow] = useState([]);
    const history = useHistory();

    useEffect(() => {
        setRow(props.data);
    }, [props.row])
    const getEditedDetails = (details) => {
        return details.map(x => ({ description: x.description, typeId: x.controlTypeID, id: x.id, edit: false, sequence: x.sequence, color: x.color, dataListId: x.dataListId })).sort((a, b) => b.sequence + a.sequence);
    }

    const SaveAndRedirect = () => {
        let TempMeasurementRows;
        dataService.getAllProjectInfoByProjectID(props.data.projectId).then(results => {
            TempMeasurementRows = (results.map(
                x => x.details.map(
                    y => ({
                        details: getEditedDetails(x.details),
                        projectId: x.projectId,
                        toDate: x.toDate,
                        fromDate: x.fromDate,
                        Id: y.id,
                        typeId: y.controlTypeID,
                        color: y.color,
                        companyId: x.companyId,
                        qrCode: x.qrCode,
                        companyName: x.companyName,
                        projectName: x.projectName,
                        resourceName: x.resourceName,
                        description: y.description,
                        sequence: y.sequence
                    })

                )))
                
            props.actions.companyAction(props.data.companyName);
            props.actions.companyIdAction(props.data.companyId);
            props.actions.resourceAction(props.data.resourceName);
            props.actions.fromDateAction(props.data.fromDate);
            props.actions.toDateAction(props.data.toDate);
            props.actions.detailsAction(TempMeasurementRows[0][0].details);
            props.actions.projectIdAction(props.data.projectId);
            props.actions.projectNameAction(props.data.projectName);
            props.actions.projectQrAction(props.data.qrCode);
            history.push("/modify");
        });

    }

    return (
        <div>
            <Tooltip title="Project information" placement="top" arrow>
                <IconButton style={{ borderRadius: 0 }} onClick={SaveAndRedirect}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>

        </div>
    )
}

const mapStateToProps = state => ({
    projects: state.projects,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
))(ProjectButton);