import React from 'react';
import Button from '@mui/material/Button'

function ButtonNormalText(props) {
    return (
        <Button size='large' disabled={props.disabled} autoFocus={props.autoFocus} startIcon={props.startIcon} type={props.type} style={ButtonStyle} onClick={props.onClick} variant="contained">{props.content}</Button>
    )
}

const ButtonStyle = {
    background: "#C3996B"
}

export default ButtonNormalText;