import React, { useEffect, useState } from 'react';
import TextfieldInput from '../../Shared/TextField';
import TitlePage from '../../Shared/TitlePage';
import DateSelectionFuture from "../../Shared/DateSelection/DateSelectionFuture";
import DescriptionItem from '../../Shared/DescriptionItem';
import DropdownCompanyAdd from './Components/DropdownCopmanyAdd';
import DropdownResources from '../../Shared/Dropdowns/DropdownResources';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableDragResources from '../../Shared/Tables/TableDragResources';

function CreatePage(props) {

    const classes = useStyles();
    var today = new Date();
    var nextMonth = new Date();
    nextMonth.setDate(nextMonth.getDate() + 30);
    const [FromDate, setFromDate] = useState(today);
    const [ToDate, setToDate] = useState(nextMonth);
    const [ResourceName, setResourceName] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState(null);
    const [ProjectName, setProjectName] = useState("");
    const [companies, setCompanies] = useState([]);
    const [resources, setResources] = useState([]);
    const [projectrows, setProjectRows] = useState([]);
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [types, setTypes] = useState([]);
    const [helperTextProjectName, setHelperTextProjectName] = useState(null);
    const [helperTextCompany, setHelperTextCompany] = useState(null);
    const [helperTextResourceDescription, setHelperTextResourceDescription] = useState(null);
    const [errorProjectName, setErrorProjectName] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [errorResourceDescription, setErrorResourceDescription] = useState(false);
    const [emptyDetails, setEmptyDetails] = useState(false);

    useEffect(() => {
        dataService.getCompanies(props.Login.userid).then(results => {
            setCompanies(results);
        })
        dataService.getResources(GetUserID).then(results => {
            setResources(results);
        });
        dataService.getTypes().then(results => {
            setTypes(results.sort((a, b) => a.description.localeCompare(b.description)));
        });
    }, []);

    const setProject = (event) => {
        setProjectName(event.target.value);
    }

    const setTheFromDate = (event) => {
        if (event !== FromDate) {
            setFromDate(event);
        }
    }

    const setTheToDate = (event) => {
        if (event !== ToDate) {
            setToDate(event);
        }
    }

    const setDates = (fromDate, toDate) => {
        setToDate(toDate);
        setFromDate(fromDate);
    }

    const setNewCompanyFunction = (event) => {
        dataService.saveCompany(event.inputValue, props.Login.userid).then(results => {
            let newCompany = { id: results.id, name: results.name };
            let tempCompanies = [...companies];
            tempCompanies.push(newCompany);
            setCompanies(tempCompanies);
            setCompanyId(results.id);
            setCompanyName(results.name);
        })
    }

    const setCompany = (event) => {
        console.log(event.target.value);

        if (event.target.value) {
            if (companies.some(x => x.name === event.target.value)) {
                setCompanyId(companies.find(x => x.name === event.target.value).id);
                setCompanyName(event.target.value);
            }
        } else {
            setCompanyId(null);
            setCompanyName("");
        }

        // if (event.inputValue) {
        //     if (companies.some(x => x.name === event.inputValue)) {
        //         setCompanyId(companies.find(x => x.name === event.inputValue).id);
        //         setCompanyName(event.inputValue);
        //     }
        // }
        // else {
        //     if (event.target.textContent) {
        //         if (companies.some(x => x.name === event.target.textContent)) {
        //             setCompanyId(companies.find(x => x.name === event.target.textContent).id);
        //             setCompanyName(event.target.textContent);
        //         }
        //     }
        //     else {

        //         if (companies.some(x => x.name === event.target.value)) {
        //             console.log("company id: ", companies.find(x => x.name === event.target.value).id);
        //             setCompanyId(companies.find(x => x.name === event.target.value).id);
        //         }
        //         setCompanyName(event.target.value);
        //     }
        // }

    }

    const setResource = (event) => {
        if (event.inputValue) {
            setResourceName(event.inputValue);
        }
        else {
            if (event.target.textContent) {
                setResourceName(event.target.textContent);
            }
            else {
                setResourceName(event.target.value);
            }
        }
    }

    const save = (newProjectrows) => {
        setProjectRows(newProjectrows);
    }

    const sendToApi = () => {
        if (ProjectName === "" || ResourceName === "" || CompanyId === "" || CompanyName === "" || FromDate === "" || ToDate === "" || ResourceName == null || FromDate == null || ToDate == null) {
            if (ProjectName === "") {
                setHelperTextProjectName("Please enter a project name.");
                setErrorProjectName(true);
            }
            else {
                setHelperTextProjectName(null)
                setErrorProjectName(false)
            }
            if (ResourceName === "" || ResourceName === null) {
                setHelperTextResourceDescription("Please Select or enter a Loaction.");
                setErrorResourceDescription(true);
            }
            else {
                setHelperTextResourceDescription(null);
                setErrorResourceDescription(false);
            }
            if (CompanyId === "" || CompanyId === null || CompanyName === "" || CompanyName === null) {
                setHelperTextCompany("Please Select or Create a Company.");
                setErrorCompany(true);
            }
            else {
                setHelperTextCompany(null);
                setErrorCompany(false);
            }
        }
        else {
            setHelperTextResourceDescription(null);

            setErrorResourceDescription(false);
            setHelperTextCompany(null);
            setErrorCompany(false);
            setHelperTextProjectName(null);
            setErrorProjectName(false);
            setEmptyDetails(false);
            if (projectrows.length === 0 || projectrows.find(x => x.Id).edit === true) {
                setEmptyDetails(true);
            }
            else {
                let i = 1;
                let projectDetails = projectrows.map(x => ({ description: x.description, typeId: x.type, type: x.type, sequence: i++, color: x.color, dataListId: x.dataListId, HasComment: x.comment, externalApiId: x.externalApiId, price: x.price, isProfit: x.isProfit }));
                console.log("creating new project: ", props.Login);
                dataService.createNewProject(props.Login.userid, ProjectName, ResourceName, CompanyId, CompanyName, FromDate, ToDate, projectDetails).then(result => {
                    if (result.success === true) {
                        toast.success('Project was saved successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    } else {
                        toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    }
                    let tempProjectName = ProjectName
                    props.actions.projectIdAction(result.createmodel.projectID);
                    props.actions.projectQrAction(result.createmodel.qrCode);
                    props.actions.projectNameAction(tempProjectName);
                });
                let tempCompanyName = CompanyName;
                let tempCompanyId = CompanyId;
                let tempResourceName = ResourceName;
                let tempFromDate = FromDate;
                let tempToDate = ToDate;
                let tempProjectName = ProjectName;
                props.actions.companyAction(tempCompanyName);
                props.actions.companyIdAction(tempCompanyId);
                props.actions.resourceAction(tempResourceName);
                props.actions.fromDateAction(tempFromDate);
                props.actions.toDateAction(tempToDate);
                props.actions.projectNameAction(tempProjectName);
                history.push("/modify");
            }

        }
    }

    return (
        <div>
            <TitlePage Text="Create your project" backbutton={true} {...props} />
            <div className={classes.container}>
                <div className={classes.projectOptions}>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Project name" />
                        <TextfieldInput value={ProjectName} action={setProject} label={ProjectName ? " " : "Project"} InputLabelProps={{ shrink: false }} helperText={helperTextProjectName} error={errorProjectName} />
                    </div>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Company name" />
                        {/* dropdown to select a company from companies and set it */}
                        <select value={CompanyName} onChange={setCompany} className={classes.dropdown}>
                            <option value="" disabled selected>Select a company</option>
                            {companies.map((company) => (
                                <option key={company.id} value={company.name}>{company.name}</option>
                            ))}
                        </select>

                        {/* <DropdownCompanyAdd companies={companies} value={CompanyName} action={setCompany} action2={setNewCompanyFunction} helperText={helperTextCompany} error={errorCompany} /> */}
                    </div>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Location" />
                        <DropdownResources resources={resources} value={ResourceName} action={setResource} helperText={helperTextResourceDescription} error={errorResourceDescription} />
                    </div>
                </div>
                <Grid item xs={12} style={{ marginBottom: '-1rem' }}>
                    <DescriptionItem Text="Active between:" />
                    <DateSelectionFuture setDates={setDates} FromDate={FromDate} ToDate={ToDate} setTheFromDate={setTheFromDate} setTheToDate={setTheToDate} />
                    <Divider style={{ marginBottom: "2rem", marginTop: "1rem" }}></Divider>
                </Grid>
                {emptyDetails === true ?
                    <div>
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableDragResources {...props} create={true} types={types} rows={projectrows} setRows={setProjectRows} makeSave={save} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one item.</p>
                    </div>
                    :
                    <div>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableDragResources {...props} create={true} types={types} rows={projectrows} setRows={setProjectRows} makeSave={save} />
                        </Grid>
                    </div>}
                <div className={classes.buttonContainer}>
                    {/* <Button size='large' className={classes.qrButton} onClick={sendToQr}  disabled variant="contained">Generate QR</Button> */}
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                </div>
            </div>
        </div>
    )
}

export default CreatePage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "90%",
        marginRight: "auto",
        marginLeft: "auto",
        height: '91%'
    },
    qrButton: {
        backgroundColor: "#C3996B"
    },
    buttonContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    dropdown: {
        width: "100%",
        height: "56px",
        borderRadius: "5px",
        border: "1px solid #d3d3d3d",
        padding: "0.5rem",

        fontSize: "16px",
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    projectOption: {
        [theme.breakpoints.up('sm')]: {
            width: "250px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginRight: '25px',
    }
}));