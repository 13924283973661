import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { TableContainer, TableRow } from '@mui/material';
import './TableStyle.css';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

function TableList(props) {
    const classes = useStyles();
    var today = new Date();
    const [saveHelper, setSaveHelper] = useState(false);

    useEffect(() => {

        // eslint-disable-next-line 
    }, [props.Login.userid])

    const setItemName = (event, id) => {
        let tempArray = [...props.rows]
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).itemName = event.target.value
        } else {
            tempArray.find(x => x.edit === true).itemName = event.target.value;
        }
        props.setRows(tempArray)
    }

    const setItemDescription = (event, id) => {
        let tempArray = [...props.rows]
        if (tempArray.find(x => x.Id === id) !== undefined) {
            tempArray.find(x => x.Id === id).itemDescription = event.target.value
        } else {
            tempArray.find(x => x.edit === true).itemDescription = event.target.value;
        }
        props.setRows(tempArray)
    }

    const GenerateID = () => {
        let tempArray = [...props.rows];
        const ids = tempArray.map(object => {
            return object.Id;
        });

        const max = Math.max(...ids);

        if (tempArray.length === 0) {
            return 1;
        }
        else {
            let index = max;
            index++;
            return index;
        }
    }

    const editListItem = (id) => {
        let tempArray = [...props.rows]
        tempArray.find(x => x.Id === id).edit = true
        props.setRows(tempArray)
    }

    const saveListItem = (id) => {
        let tempArray = [...props.rows]
        if (tempArray.find(x => x.Id === id).itemName !== "" && tempArray.find(x => x.Id === id).itemName !== null) {
            tempArray.find(x => x.Id === id).edit = false
            props.setRows(tempArray)
            setSaveHelper(false)
        }
        else{
            setSaveHelper(true)
        }
    }

    const deleteListItem = (id) => {
        let tempArray = [...props.rows]
        let index = tempArray.indexOf(tempArray.find(x => x.Id === id))
        tempArray.splice(index, 1)
        props.setRows(tempArray)
    }

    const addRow = () => {
        let Rowid = { Id: GenerateID(), itemDescription: "", edit: true, itemName: "", UserID: props.Login.userid, InsertedOn: today }
        let PrevId = GenerateID() - 1;
        var tempArray = [...props.rows]
        if (tempArray.find(x => x.itemName === "") === undefined) {
            if (tempArray.length !== 0) {
                if (tempArray.find(x => x.Id === PrevId).itemName !== "") {
                    tempArray.find(x => x.Id === PrevId).edit = false
                    setSaveHelper(false)
                    tempArray.push(Rowid)
                    props.setRows(tempArray)
                }
                else {
                    tempArray.push(Rowid)
                    props.setRows(tempArray)
                }
            }
            else {
                tempArray.push(Rowid)
                props.setRows(tempArray)
            }
        }
    }

    return (
        <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
            <Table>
                <thead className={classes.tableHead}>
                    <tr>
                        <th style={{ paddingRight: '6px', paddingLeft: '6px' }}>#</th>
                        <th width="30%">Name</th>
                        <th width="60%">Description</th>
                        <th width="10%" colSpan={2} style={{ paddingLeft: "50px", paddingRight: "43px" }} >Action</th>
                    </tr>
                </thead>
                <tbody align="center" className={classes.tableContent}>
                    {
                        props.rows.map(x =>
                            <TableRow key={x.Id} className={classes.tableRow}>
                                <td className={classes.indexTd} >
                                    <div className={classes.listOrder}>
                                        {x.Id}
                                    </div>
                                </td>
                                <td className={classes.tableTd}>
                                    {x.edit ?// eslint-disable-next-line
                                        <TextField label={x.itemName ? " " : "Name"} InputLabelProps={{ shrink: false }} style={saveHelper?{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "7px", width: '100%', minWidth: "100px" }:{ width: '100%', minWidth: "100px" }} type={"search"} variant="outlined" value={x.itemName.length > 0 ? x.itemName : ''} onChange={(event) => setItemName(event, x.Id)} /> :
                                        <TextField style={{ width: '100%', minWidth: "100px" }} disabled value={x.itemName} />
                                    }
                                </td>
                                <td className={classes.tableTd}>
                                    {x.edit ?// eslint-disable-next-line
                                        <TextField label={x.itemDescription ? " " : "Description"} InputLabelProps={{ shrink: false }} style={{ width: '100%', minWidth: "140px" }} className={classes.descriptionField} id="outlined-basic" type={"search"} variant="outlined" value={x.itemDescription.length > 0 ? x.itemDescription : ''} onChange={(event) => setItemDescription(event, x.Id)} /> :
                                        <TextField style={{ width: '100%', minWidth: "140px" }} disabled value={x.itemDescription !== "" ? x.itemDescription : x.description} />
                                    }
                                </td>
                                <td className={classes.tableTd} align="center">
                                    {x.edit ?
                                        <IconButton aria-label="save" onClick={() => saveListItem(x.Id)} className={classes.editButton} size="large">
                                            <SaveIcon fontSize="inherit" />
                                        </IconButton> :
                                        <IconButton aria-label="edit" onClick={() => editListItem(x.Id)} className={classes.editButton} size="large">
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </td>
                                <td className={classes.tableTd}>
                                    <IconButton aria-label="delete" onClick={() => deleteListItem(x.Id)} className={classes.functionButton} color='error' size="large">
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </td>
                            </TableRow>
                        )
                    }
                    <TableRow className={classes.addRow} onClick={addRow}>
                        <td className={classes.indexTd}>
                            -
                        </td>
                        <td className={classes.tableTd} style={{ minWidth: "100px" }}>
                            <FormControl fullWidth>
                                <InputLabel>Name</InputLabel>
                                <Select
                                    IconComponent={() => (null
                                    )} disabled />
                            </FormControl>
                        </td>
                        <td className={classes.tableTd} style={{ minWidth: "140px" }}>
                            <FormControl fullWidth>
                                <InputLabel>Description</InputLabel>
                                <Select
                                    IconComponent={() => (null
                                    )} disabled />
                            </FormControl>
                        </td>
                        <td className={classes.tableTd} colSpan="2" align="center">
                            <Fab size="medium">
                                <AddIcon />
                            </Fab>
                        </td>

                    </TableRow>
                </tbody>
            </Table>
        </TableContainer>
    )
}

export default TableList;

const useStyles = makeStyles({
    tableContent: {
        paddingTop: "15px",
    },
    tableRow: {
        height: '60px',
    },
    tableHead: {
        backgroundColor: "#e0dce4",
        marginBottom: "15px",
    },
    addRow: {
        opacity: "0.3"
    },
    tableTd: {
        padding: "5px",
    },
    descriptionField: {
        width: "100%",
    },
    editButton: {
        fontSize: '2rem !important',
        color: '#0B4097 !important',
    },
    functionButton: {
        fontSize: '2rem !important',
    },
    indexTd: {
        borderRight: '2px solid rgb(224, 224, 224)',
        width: '25px',
        background: 'rgba(0, 0, 0, 0.54)',
        color: 'white',
        zIndex: '2',
    },
    emptydrag: {
        padding: '17px !important',
        border: '0'
    },
    listOrder: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    dragTd: {
        border: '0',
        width: '24px',
    },
    dragIcon: {
        float: 'left',
    },
});