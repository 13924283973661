import React from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function MyDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        type="date"
        disabled={props.disabled}
        value={props.value}
        maxDate={props.toDate}
        minDate={props.fromDate}
        ampm={false}
        inputFormat="dd/MM/yyyy"
        onChange={(event) => {
          props.action(event)
        }}
        renderInput={(params) => <TextField
          style={props.style} {...params} />}
      />
    </LocalizationProvider>
  );
}
export default MyDatePicker;