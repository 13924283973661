import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import EditListsButton from './EditListsButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import FilterListRow from '../../../Shared/Tables/FilterListRow';

function Row(props) {
    const classes = useStyles();
    const row = props.row;
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <tr className={classes.tableBodyRow} key={"thow"} style={props.index % 2 ? { background: "#e6ecf0" } : { background: "white" }}>
                <td>
                    <IconButton style={{ borderRadius: 0 }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </td>
                <td>
                    {row.name}
                </td>
                <td>
                    {row.description}
                </td>
                <td><EditListsButton style={{ borderRadius: 0 }} data={
                    ({
                        listName: props.row.name,                        
                        listDescription: props.row.description,                      
                        listItems: props.row.dataListItems,
                        listIsActive: props.row.listIsActive,
                        listId: props.row.listId
                    })} />
                </td>
            </tr>

            <tr style={props.index % 2 ? { background: "#e6ecf0" } : { background: "white" }}>
                <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {row.name}
                            </Typography>
                            <Table size="small">
                                <thead>
                                    <TableRow className={classes.tableRow} >
                                        <td style={{ fontWeight: 'bold' }}>#</td>
                                        <td style={{ fontWeight: 'bold' }}>Name</td>
                                        <td style={{ fontWeight: 'bold' }}>Description</td>
                                    </TableRow>
                                </thead>
                                <TableBody>
                                    {row.dataListItems.map((ItemRows, index) => (
                                        <tr className={classes.tableRow} key={"throw"} style={props.index % 2 ? { background: "#e6ecf0" } : { background: "white" }}>
                                            <td>{index + 1}</td>
                                            <td>{ItemRows.name}</td>
                                            <td>{ItemRows.description}</td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    );
}

export default function TableList(props) {
    const classes = useStyles();
    return (
        <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
            <Table size="sm" key={props.rows.id}>
                <TableHead className={classes.headStyle} key={props.rows.id}>
                    <TableRow className={classes.tableBodyRow} key={props.rows.id}>
                        <th className={classes.tableHead} style={{ width: '2%' }} />
                        <th className={classes.tableHead} >
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('name')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Dropdown Name
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    Dropdown Description
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '2%'}}>
                            Actions
                        </th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.filterOn && <FilterListRow rows={props.rows} filters={props.filters} filterList={props.filterList} updateFilters={props.updateFilters}></FilterListRow>}
                    {props.rows.map((row, index) => (
                        <Row row={row} index={index} className={classes.tableBodyRow} />
                    ))}
                    {props.rows.length === 0 &&
                        <tr className={classes.tableBodyRow} >
                            <td colSpan={4}>No dropdown available</td>
                        </tr>}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles((theme) => ({
    tableHead: {
        height: '42px',
    },
    titleSearch: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headStyle: {
        backgroundColor: "#e0dce4",
    },
    tableBodyRow: {
        justifyContent: "center",
        textAlign: "center",
    },
    tableRow: {
        borderBottom: '2px solid #e0dce4',
    },
    actionButtons: {
        padding: '0px !important',
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
    tableFooter: {
        width: '100%'
    },
    footer: {
        display: 'flex',
    },
    icons: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

