import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

export default function ButtonAction(props) {

    const classes = useStyles(); 

    return (
        <button className={classes.actionButton} disabled={props.disabled} onClick={() => props.action()}>{props.text}</button>
    );
}


const useStyles = makeStyles(() => ({
    actionButton: {
        paddingRight: '15px',
        paddingLeft: '15px',

        border: "none",
        outline: "none",
        cursor: "pointer",
        margin: "10px",
        color: "white",
        height: "30px",
        backgroundColor:'#C3996B',
        fontWeight: "bold",
        fontFamily: "Lato, sans-serif",

    }

}));