import React from 'react';

const Style = {
    fontSize: "16px",
    lineHeight: "18px",
    color: "#1D3557"
}

function DescriptionTitle(props) {
    return (
        <div>
            <p style={Style}>{props.Text}</p>
        </div>
    )
}

export default DescriptionTitle;