export function fromNewDateFormatToYYYYMMDDSlash(newDate) {

    let addZeroToMonth = false

    let addZeroToDay = false

    if (new Date(newDate).getDate() < 10) {
        addZeroToDay = true
    }

    if (new Date(newDate).getMonth() + 1 < 10) {
        addZeroToMonth = true
    }

    let date = new Date(newDate).getFullYear() + "/" + (addZeroToMonth ? "0" : "") + String(new Date(newDate).getMonth() + 1) + "/" + (addZeroToDay ? "0" : "") + new Date(newDate).getDate();

    return date
}

export function parseNewDateToDDMMYYY(newDate){
    return `${("0" + (newDate.getDate())).slice(-2)}/${("0" + (newDate.getMonth() + 1)).slice(-2)}/${newDate.getFullYear()} ${("0" + (newDate.getHours())).slice(-2)}:${("0" + (newDate.getMinutes())).slice(-2)}:${("0" + (newDate.getSeconds())).slice(-2)}`;

}

export function parseNewDateToDDMMYYYnoTime(newDate){
    return `${("0" + (newDate.getDate())).slice(-2)}/${("0" + (newDate.getMonth() + 1)).slice(-2)}/${newDate.getFullYear()}`;

}
export function parseNewDateToYYMMDDDash(newDate){
    return `${newDate.getFullYear()}-${("0" + (newDate.getMonth() + 1)).slice(-2)}-${("0" + (newDate.getDate())).slice(-2)} ${("0" + (newDate.getHours())).slice(-2)}:${("0" + (newDate.getMinutes())).slice(-2)}:${("0" + (newDate.getSeconds())).slice(-2)}`;

}

export function isDateObject(date){
    if(date instanceof Date){
        return true
    }
    else{
        return false
    }
}