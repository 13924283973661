import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import LoginPage from '../Login/LoginPage';
import SelectMenuPage from '../SelectMenu/SelectMenuPage';
import ResultsPage from '../Results/ResultsPage';
import { PrivateRoute } from '../../Shared/PrivateRoute';
import CreatePage from '../CreateProject/CreatePage';
import ConfigurationPage from '../Configuration/ConfigurationPage';
import UserSettingsPage from '../User/UserSettings/userSettings';
import ProjectReportPage from "../ProjectReport/ProjectReport";
import ProjectReportOverviewPage from "../ProjectReportOverview/ProjectReportOverview";
import ModifyPage from '../ModifyProject/ModifyPage';
import PerformMeasurementPage from "../PerformMeasurement/PerformMeasurementPage";
import ManagePage from "../ProjectOverview/ManagePage";
import DuplicatePage from "../DuplicateProject/DuplicatePage";
import CreateListPage from "../CreateList/CreateList";
import CreateExternalApiPage from "../CreateExternalApi/CreateExternalApi";
import ModifyListPage from "../ModifyList/ModifyList";
import ModifyExternalApiPage from "../ModifyExternalApi/ModifyExternalApi";
import ListOverviewPage from "../ProjectListOverview/ListOverview";
import ExternalApiOverviewPage from "../ExternalApiListOverview/ExternalApiOverview";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { createTheme } from '@mui/material/styles';
import LandingPage from "../Landing/LandingPage";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

function NavigationPage(props) {

    const loggedInState = props.Login.loginState;
    const classes = useStyles();
    const breakpoints = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });

    return (
        <Router basename={`${process.env.PUBLIC_URL}`} >
            {/* basename={`${process.env.PUBLIC_URL}`} */}
            {/* Basename process env public url should be used for the dev env not live */}

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
       
            <Switch>
                <div className={loggedInState ? classes.container : classes.navigationLoginContainer}>
                    {loggedInState ?
                        <PrivateRoute path={"/"} exact loggedInState={loggedInState} component={() => <SelectMenuPage  {...props}></SelectMenuPage>}></PrivateRoute>
                        :
                        <React.Fragment>
                            {/* <Route path="/reset" exact loggedInState={loggedInState} component={() => <ResetPage  {...props} />}><ResetPage  {...props} /></Route> */}
                            <Route path="/login" exact loggedInState={loggedInState} component={() => <LoginPage  {...props} />}><LoginPage  {...props} /></Route>
                            <Route path="/" exact loggedInState={loggedInState} component={() => <LandingPage  {...props} />}><LandingPage  {...props} /></Route>
                        </React.Fragment>
                    }
                    <PrivateRoute path={"/user"} loggedInState={loggedInState} component={() => <UserSettingsPage {...props}></UserSettingsPage>}></PrivateRoute>
                    <PrivateRoute path={"/create"} loggedInState={loggedInState} component={() => <CreatePage {...props}></CreatePage>}></PrivateRoute>
                    <PrivateRoute path={"/modifydropdown"} loggedInState={loggedInState} component={() => <ModifyListPage {...props}></ModifyListPage>}></PrivateRoute>
                    <PrivateRoute path={"/createdropdown"} loggedInState={loggedInState} component={() => <CreateListPage {...props}></CreateListPage>}></PrivateRoute>
                    <PrivateRoute path={"/projectreport"} loggedInState={loggedInState} component={() => <ProjectReportPage {...props}></ProjectReportPage>}></PrivateRoute>
                    <PrivateRoute path={"/projectreportoverview"} loggedInState={loggedInState} component={() => <ProjectReportOverviewPage {...props}></ProjectReportOverviewPage>}></PrivateRoute>
                    <PrivateRoute path={"/createapi"} loggedInState={loggedInState} component={() => <CreateExternalApiPage {...props}></CreateExternalApiPage>}></PrivateRoute>
                    <PrivateRoute path={"/modifyapi"} loggedInState={loggedInState} component={() => <ModifyExternalApiPage {...props}></ModifyExternalApiPage>}></PrivateRoute>
                    <PrivateRoute path={"/dropdownoverview"} loggedInState={loggedInState} component={() => <ListOverviewPage {...props}></ListOverviewPage>}></PrivateRoute>
                    <PrivateRoute path={"/externalapioverview"} loggedInState={loggedInState} component={() => <ExternalApiOverviewPage {...props}></ExternalApiOverviewPage>}></PrivateRoute>
                    <PrivateRoute path={"/modify"} loggedInState={loggedInState} component={() => <ModifyPage {...props}></ModifyPage>}></PrivateRoute>
                    <PrivateRoute path={"/scanqr"} loggedInState={loggedInState} component={() => <LoginPage {...props}></LoginPage>}></PrivateRoute>
                    <PrivateRoute path={"/results"} loggedInState={loggedInState} component={() => <ResultsPage {...props}></ResultsPage>}></PrivateRoute>
                    <PrivateRoute path={"/manage"} loggedInState={loggedInState} component={() => <ManagePage {...props}></ManagePage>}></PrivateRoute>
                    <PrivateRoute path={"/duplicate"} loggedInState={loggedInState} component={() => <DuplicatePage {...props}></DuplicatePage>}></PrivateRoute>
                    <PrivateRoute path={"/configuration"} loggedInState={loggedInState} component={() => <ConfigurationPage {...props}></ConfigurationPage>}></PrivateRoute>
                    <Route path={"/perfmeasurement"} loggedInState={loggedInState} component={() => <PerformMeasurementPage loggedInState={loggedInState} {...props} />}></Route>
                </div>
            </Switch>
        </Router>
    )
}


export default NavigationPage;
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        marginTop: '8%',

        [theme.breakpoints.down('sm')]: {
            marginTop: '150px',

        },
        [theme.breakpoints.up('md')]: {
            marginTop: '12%',

        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '9%',

        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '7%',

        },
        marginRight: "auto",
        marginLeft: "auto"
    },
    navigationLoginContainer: {
        width: '100%',
        height: '100%',
        marginRight: "auto",
        marginLeft: "auto"
    },
}));