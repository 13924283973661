import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { dataService } from '../../../Services/data/data.service';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, RecaptchaVerifier } from "firebase/auth";
import BadgeIcon from '@mui/icons-material/Badge';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleLogo from "../../../Images/google.svg";
import TaplrLogo from "../../../Images/favicon.ico";
import ReCAPTCHA from "react-google-recaptcha";
import DottedShape from "../../../Images/dotedshape.png"
toast.configure();

function LoginPage(props) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [email, setEmail] = useState("");
    // const [phoneNumber, setPhoneNumber] = useState();

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [password, setPassword] = useState("");
    const [badInput, setBadInput] = useState(false);
    const [badInputText, setBadInputText] = useState("");
    const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);
    // const appVerifier = window.recaptchaVerifier;
    const [captchaValue, setCaptcha] = useState(null);
    const [imageNumber, setImageNumber] = useState(1);
    const history = useHistory();
    const [loginPage, setLoginPage] = useState(true);
    const [forgotPassword, setForgotPasswordPage] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState(false);
    const [recaptchaRequired, setRecaptchaRequired] = useState(false);
    const [open, setOpen] = useState(false);
    const [recaptchaKey, setRecaptchaKey] = useState(null);

    let auth = getAuth();

    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        var min = 1;
        var max = 3;
        var number = Math.floor(Math.random() * (max - min + 1) + min);
        setImageNumber(number);
        setLoginPage(true);
        if (window.location.origin.includes('localhost')) {
            setRecaptchaKey("6Lc9IawgAAAAAAdGqgG6DWvF1kFB6WIoe1RHKuLV");

        } else {
            dataService.getSystemParameters().then(results => {
                setRecaptchaKey(results.find(x => x.name === "RecaptchaKey").value);
            });
        }


    }, [])
    const recaptchaRef = React.createRef();
    const checkLogin = (e) => {
        e.preventDefault();
        if (email === "" || password === "") { displayError(); }
        else {
            // Sign in with email and pass.
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    onAuthStateChanged(auth, (user) => {
                        if (user) {
                            if (user.emailVerified) {
                                dataService.getUserByEmail(user.email).then(results => {
                                    props.actions.setUserid(results.id);
                                    props.actions.setUserName(results.firstname);
                                    props.actions.setLastName(results.lastname);
                                });
                                props.actions.setEmail(user.email);

                                // User is signed in, see docs for a list of available properties
                                // https://firebase.google.com/docs/reference/js/firebase.User
                                const uid = user.uid;
                                props.actions.setLogin(true);
                                history.push("/");
                            } else {
                                setOpen(true);
                            }

                        } else {

                        }
                    });
                    // ...
                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    displayError();
                });
        }
    }

    const checkRegister = (e) => {
        const recaptchaAuth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier('register-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log(response);
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, recaptchaAuth);
        e.preventDefault();
        // if (phoneNumber === "") {
        if (email === "" || firstName === "" || lastName === "") {
            if (captchaValue === null) {
                setRecaptchaRequired(true);

            }
            displayError();
        } else {
            if (captchaValue !== null) {
                setRecaptchaRequired(false);

                dataService.requestLogin(firstName, lastName, email).then(response => {
                    if (response.success === true) {
                        toast.success('Uw aanvraag werd verzonden! Wij nemen zo snel mogelijk contact op met u!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                        handleSignUp(true);
                    } else {
                        toast.error('Er liep iets verkeerd, probeer opnieuw.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                        handleSignUp(true);
                    }


                });
                // Sign in with email and pass.
                // createUserWithEmailAndPassword(auth, email, password)
                //     .then((userCredential) => {
                //         // Signed in 
                //         const user = userCredential.user;
                //         const uid = user.uid;
                //         setLoginPage(true);
                //         setPassword("");
                //         dataService.createUser(email, firstName, lastName, uid).then(response => {

                //         });
                //         //Verification
                //         sendEmailVerification(user)
                //             .then(() => {
                //                 // Email verification sent!
                //                 toast.success('Verification mail has been sent to: ' + email, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                //             });
                //     }).catch((error) => {
                //         const errorCode = error.code;
                //         const errorMessage = error.message;
                //         if (errorCode === errorCode) {
                //             setEmailAlreadyUsed(true);
                //         }
                //         // toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

                //     });

            } else {
                setRecaptchaRequired(true);
            }
        }
    }
    const handleForgotPassword = () => {
        setBadInput(false);
        setBadInputText("");

        setForgotPasswordPage(true);
        setLoginPage(false);

    }
    const handleVerificationMail = (event) => {
        setOpen(false);
        let auth = getAuth();

        if (email === "") {
            displayError();
        } else {
            sendEmailVerification(auth.currentUser)
                .then(() => {
                    // Email verification sent!
                    // ...
                });
        }


    }
    const sendPasswordMail = (event) => {
        event.preventDefault();

        if (email === "") { displayError(); }
        else {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    toast.success('Email has been sent to: ' + email, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

                    // ..
                });
        }

    }
    let tempUrl = window.location.origin;

    const handleSignUp = (value) => {
        setBadInput(false);
        setBadInputText("");
        setPassword("");
        setForgotPasswordPage(false);
        setLoginPage(value);
    }

    const displayError = () => {
        setBadInput(true);
        setBadInputText("Incorrect email or password.");
    }
    const navigateToHomePage = () => {
        history.push("/");
    }

    const handleGoogleLogin = () => {
        const googleAuth = getAuth();
        signInWithPopup(googleAuth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        if (user.emailVerified) {
                            dataService.getUserByEmail(user.email).then(results => {
                                props.actions.setUserid(results.id);
                                props.actions.setUserName(results.firstname);
                                props.actions.setLastName(results.lastname);


                            });
                            props.actions.setEmail(user.email);

                            // User is signed in, see docs for a list of available properties
                            // https://firebase.google.com/docs/reference/js/firebase.User
                            const uid = user.uid;
                            props.actions.setLogin(true);
                            history.push("/");
                        } else {
                            setOpen(true);
                        }

                    } else {

                    }
                });
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    const changeEmail = (event) => {
        setEmail(event.target.value);
    }

    // const changePhoneNumber = (event) => {
    //     setPhoneNumber(event.target.value);
    // }
    const changeLastName = (event) => {
        setLastName(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const changePassword = (event) => {
        setPassword(event.target.value);
    }


    // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
    // window.recaptchaVerifier = new RecaptchaVerifier('login-button', {
    //     'size': 'invisible',
    //     'callback': (response) => {
    //         // reCAPTCHA solved, allow signInWithPhoneNumber.
    //         checkRegister();
    //     }
    // }, recaptchaAuth);

    // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, recaptchaAuth);
    // window.recaptchaVerifier = new RecaptchaVerifier('register-button', {
    //     'size': 'invisible',
    //     'callback': (response) => {
    //         // reCAPTCHA solved, allow signInWithPhoneNumber.
    //         checkLogin();
    //     }
    // }, recaptchaAuth);

    function onChange(value) {
        setCaptcha(value);
    }

    return (
        <div className={classes.loginContainer}>
            <div className={classes.imageContainer}
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/Login/istock' + imageNumber + '.jpg'})` }}>
                <div className={classes.imageOverlay}>

                </div>
            </div>
            {/* <div id="recaptcha-container" data-callback="sendForm"
                data-size="invisible">

            </div> */}
            {loginPage === undefined || loginPage === true ?
                <form onSubmit={checkLogin} className={classes.loginForm}>

                    <div className={classes.login}>

                            <img className={classes.taplrLogo} src={process.env.PUBLIC_URL + "/images/Taplr.png"} onClick={() => navigateToHomePage()} alt="Taplr Logo"></img>

                            <h1 className={classes.actionType}>Sign in</h1>
                            <p className={classes.pWhatToDo}>Enter your details below to continue</p>
                            <div className={classes.inputDiv}>
                                <span style={{ fontWeight: '400', fontSize: '1rem' }}>Email</span>

                                <TextField
                                    className={classes.textField}
                                    type={"text"}
                                    variant="outlined"
                                    value={email}
                                    error={badInput}
                                    helperText={badInputText}
                                    fullWidth={true}
                                    onChange={(event) => changeEmail(event)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <div style={{ width: "40px" }}></div>
                                            </InputAdornment>,
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <PersonIcon style={{ marginRight: '10px' }}></PersonIcon>
                                            </InputAdornment>,
                                    }}>

                                </TextField>
                                <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                            </div>
                            <div className={classes.inputDiv}>
                                <span style={{ fontWeight: '400', fontSize: '1rem' }}>Password</span>

                                <TextField
                                    className={classes.textField}
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    value={password}
                                    error={badInput}
                                    helperText={badInput}
                                    fullWidth={true}
                                    onChange={(event) => changePassword(event)}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <LockIcon style={{ marginRight: '10px' }}></LockIcon>
                                            </InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>)
                                    }}>
                                </TextField>
                                <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <a className={classes.styleLink} style={{ marginTop: '10px' }} onClick={() => handleForgotPassword(true)}>Forgot Password?</a>

                            </div>
                            {verificationMessage ?
                                <div>
                                    <p>A verification e-mail was sent to {email}, please verify your account before using our app.</p>
                                    <a onClick={() => handleVerificationMail()}>Resend the mail?</a>
                                </div>

                                : <React.Fragment></React.Fragment>}
                            <div className={classes.buttonDiv}>
                                <Button style={{ marginTop: '15px', marginBottom: '15px' }} size='small' type="submit" className={classes.logInButton} variant="contained" id="login-button">Sign in</Button>
                                {/*  <ButtonNormalText type="submit" content="Sign In" />  onClick={checkLogin} */}
                                {/* <p style={{ fontWeight: '500', fontSize: '1rem', marginTop: '15px' }}>OR</p> */}
                                {/*  <ButtonNormalText type="submit" content="Sign In" />  onClick={checkLogin} */}
                                {/* <Button style={{ marginTop: '15px', marginBottom: '15px' }} size='large' className={classes.googleButton} variant="contained" onClick={() => handleGoogleLogin()} id="google"><img className={classes.imageStyle} src={GoogleLogo} alt="" /> CONTINUE WITH GOOGLE
                                </Button> */}
                            </div>
                            <Divider style={{ width: '100%' }} />
                            <div style={{ display: 'flex', marginBottom: '15px', marginTop: '15px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <small style={{ float: 'left' }}>New to Taplr?</small>
                                <Button size='small' className={classes.signupButton} variant="contained" onClick={() => handleSignUp(false)}> REQUEST AN ACCOUNT
                                </Button>
                            </div>

                        <div className={classes.taplrBlocks}>
                            <img src={DottedShape}></img>
                            {/* <div className={classes.taplrBlockOne}>

                            </div>
                            <div className={classes.taplrBlockTwo}>

                            </div>
                            <div className={classes.taplrBlockThree}>

                            </div> */}
                        </div>

                    </div>
                </form> : forgotPassword === true ?
                    <form onSubmit={sendPasswordMail} className={classes.loginForm}>
                        <div className={classes.login}>
           
                                <img className={classes.taplrLogo} src={process.env.PUBLIC_URL + "/images/Taplr.png"} onClick={() => navigateToHomePage()} alt="Taplr Logo"></img>

                                <h1 className={classes.actionType}>Forgot password</h1>
                                <p className={classes.pWhatToDo}>Enter your email below to continue</p>
                                <div className={classes.inputDiv}>
                                    <span style={{ fontWeight: '400', fontSize: '1rem' }}>Email</span>

                                    <TextField
                                        className={classes.textField}
                                        type={"text"}
                                        variant="outlined"
                                        value={email}
                                        error={badInput}
                                        helperText={badInput ? "Email is required" : ""}
                                        fullWidth={true}
                                        onChange={(event) => changeEmail(event)}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <div style={{ width: "40px" }}></div>
                                                </InputAdornment>,
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <PersonIcon style={{ marginRight: '10px' }}></PersonIcon>
                                                </InputAdornment>,
                                        }}>

                                    </TextField>
                                    <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                </div>

                                <Button size='small' type="submit" className={classes.logInButton} variant="contained">Send mail</Button>
                                {/*  <ButtonNormalText type="submit" content="Sign In" />  onClick={checkLogin} */}
                                <Divider style={{ width: '100%', marginTop: '15px' }} />

                                {/* <Grid item>
                                    <p>Back to login-screen: <a className={classes.styleLink} onClick={() => handleSignUp(true)}>Login</a></p>
                                </Grid> */}
                                <div style={{ display: 'flex', marginBottom: '15px', marginTop: '15px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <small style={{ float: 'left' }}>Already have an account?</small>
                                    <Button size='small' className={classes.signupButton} variant="contained" onClick={() => handleSignUp(true)}>SIGN IN TO TAPLR
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', marginBottom: '15px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <small>New to Taplr?</small>
                                    <Button size='small' className={classes.regButton} variant="contained" onClick={() => handleSignUp(false)}>SIGN UP FOR TAPLR
                                    </Button>
                                </div>
                        </div>
                    </form>
                    : forgotPassword === false && loginPage === false ?

                        <form onSubmit={checkRegister} className={classes.loginForm}>
                            <div className={classes.login}>
                            
                                    <img className={classes.taplrLogo} src={process.env.PUBLIC_URL + "/images/Taplr.png"} onClick={() => navigateToHomePage()} alt="Taplr Logo"></img>

                                    <h1 className={classes.actionType}>Request an account</h1>
                                    <p className={classes.pWhatToDo}>Enter your details below to continue</p>
                                    <div className={classes.detailDiv}>
                                        <div className={classes.nameDiv}>
                                            <span style={{ fontWeight: '400', fontSize: '1rem' }}>First name</span>
                                            <TextField
                                                className={classes.nameTextField}
                                                type={"text"}
                                                variant="outlined"
                                                value={firstName}
                                                error={badInput}
                                                helperText={badInput ? "First name is required" : ""}
                                                fullWidth={true}
                                                onChange={(event) => changeFirstName(event)}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <div style={{ width: "40px" }}></div>
                                                        </InputAdornment>,
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <BadgeIcon style={{ marginRight: '10px' }}></BadgeIcon>
                                                        </InputAdornment>,
                                                }}>

                                            </TextField>
                                            <Divider className={classes.dividerName} sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                        </div>
                                        <div className={classes.nameDiv}>
                                            <span style={{ fontWeight: '400', fontSize: '1rem' }}>Last name</span>
                                            <TextField
                                                className={classes.nameTextField}
                                                type={"text"}
                                                variant="outlined"
                                                value={lastName}

                                                error={badInput}
                                                helperText={badInput ? "Last name is required" : ""}
                                                fullWidth={true}
                                                onChange={(event) => changeLastName(event)}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <div style={{ width: "40px" }}></div>
                                                        </InputAdornment>,
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <BadgeIcon style={{ marginRight: '10px' }}></BadgeIcon>
                                                        </InputAdornment>,
                                                }}>

                                            </TextField>
                                            <Divider className={classes.dividerLastName} sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        </div>
                                    </div>
                                    <div className={classes.inputDiv}>
                                        <span style={{ fontWeight: '400', fontSize: '1rem' }}>Email</span>

                                        <TextField
                                            className={classes.textField}
                                            type={"email"}
                                            variant="outlined"
                                            value={email}
                                            error={badInput}
                                            helperText={badInput ? "Email is required" : ""}
                                            fullWidth={true}
                                            onChange={(event) => changeEmail(event)}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <div style={{ width: "40px" }}></div>
                                                    </InputAdornment>,
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ marginRight: '10px' }}></PersonIcon>
                                                    </InputAdornment>,
                                            }}>

                                        </TextField>
                                        <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                    </div>
                                    {/* <div className={classes.inputDiv}>
                                        <span>Phone number</span>

                                        <TextField
                                            className={classes.textField}
                                            type={"text"}
                                            variant="outlined"
                                            value={phoneNumber}
                                            error={badInput}
                                            helperText={badInput ? "Email is required" : ""}
                                            fullWidth={true}
                                            onChange={(event) => changePhoneNumber(event)}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <div style={{ width: "40px" }}></div>
                                                    </InputAdornment>,
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ marginRight: '10px' }}></PersonIcon>
                                                    </InputAdornment>,
                                            }}>

                                        </TextField>
                                        <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                    </div> */}
                                    {/* 
                                    <div className={classes.inputDiv} style={{ marginBottom: '45px' }}>
                                        <span style={{ fontWeight: '400', fontSize: '1rem' }}>Password</span>

                                        <TextField
                                            className={classes.textField}
                                            type={showPassword ? "text" : "password"}
                                            variant="outlined"
                                            value={password}
                                            error={badInput}
                                            helperText={badInput ? "Password is required" : ""}
                                            fullWidth={true}
                                            onChange={(event) => changePassword(event)}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <LockIcon style={{ marginRight: '10px' }}></LockIcon>
                                                    </InputAdornment>,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>)
                                            }}>
                                        </TextField>
                                        <Divider className={classes.divider} sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <a className={classes.styleLink} onClick={() => handleForgotPassword(true)}>Forgot Password?</a>

                                    </div> */}
                                    {emailAlreadyUsed ? <b style={{ color: 'red' }}>This email address is already in use.</b> : <React.Fragment></React.Fragment>}
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                                        <ReCAPTCHA style={recaptchaRequired ? { border: 'red 1px solid' } : { border: 'none' }}
                                            ref={recaptchaRef}
                                            sitekey={recaptchaKey}
                                            onChange={onChange}
                                        />
                                    </div>

                                    {recaptchaRequired ? <p style={{ color: 'red' }}>Recaptcha is required</p> : <React.Fragment></React.Fragment>}

                                    <Grid item>

                                        <Button size='small' type="submit" className={classes.logInButton} style={{ marginBottom: '15px' }} variant="contained" id="register-button">Request an account</Button>
                                        {/*  <ButtonNormalText type="submit" content="Sign In" />  onClick={checkLogin} */}
                                    </Grid>
                                    {/* <Grid item>
                                    </Grid> */}
                                    <Divider style={{ width: '100%' }} />


                                    <div style={{ display: 'flex', marginBottom: '15px', marginTop: '15px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                                        <small>Already have an account?</small>
                                        <Button size='small' className={classes.signupButton} variant="contained" onClick={() => handleSignUp(true)}>SIGN IN TO TAPLR
                                        </Button>
                                    </div>
                                <div className={classes.taplrBlocks}>
                                    <img src={DottedShape}></img>
                                    {/* <div className={classes.taplrBlockOne}>

                            </div>
                            <div className={classes.taplrBlockTwo}>

                            </div>
                            <div className={classes.taplrBlockThree}>

                            </div> */}

                                </div>
                                <div>

                                </div>
                            </div>
                        </form>
                        : <React.Fragment></React.Fragment>}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Verify your account!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You're account has not been verified yet. Check your mailbox for our email!
                    </DialogContentText>
                </DialogContent>
                <DialogActions >

                    <Button onClick={() => handleVerificationMail()} variant="contained" autoFocus className={classes.verifyButton}>
                        Resend mail
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default LoginPage;

const useStyles = makeStyles((theme) => ({

    loginForm: {
        backgroundColor: '#a99985',
        height: '100vh',
        [theme.breakpoints.up('sm')]: {
            width: '45%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        display: 'flex',
    },
    taplrBlocks: {
        position: 'absolute',
        bottom: '-100px',
        right: '-100px',
        [theme.breakpoints.down('md')]: {

            bottom: '-35px',
            '& img': {
                width: '70%',
                height: '70%',
            }
        },
        [theme.breakpoints.down('sm')]: {

            bottom: '-25px',
            '& img': {
                width: '50%',
                height: '50%',
            }
        },
    },
    taplrBlockOne: {
        backgroundColor: '#f79222',
        height: '50px',
        width: '50px',
        position: 'absolute',
        bottom: '0px',
        right: '0px'
    },
    taplrBlockTwo: {
        backgroundColor: 'white',
        height: '75px',
        width: '75px',
        position: 'absolute',
        bottom: '-75px',
        right: '-75px'
    },
    taplrBlockThree: {
        backgroundColor: '#f79222',
        height: '25px',
        width: '25px',
        position: 'absolute',
        bottom: '-25px',
        right: '-125px'
    },
    loginContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    imageContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

        backgroundColor: "#e6ccb1",
        width: '55%',
        maxWidth: '100%',
        maxHeight: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
    },
    imageOverlay: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        backgroundColor: 'black',
        opacity: '0.6',
    },

    login: {
        flexDirection: 'column',
        position: 'relative',
        width: '70%',
        outline: "5px solid #F7941E",
        boxShadow: '0 0 15px 5px rgb(0 0 0 / 25%)',
        background: "#C3996B",
        borderRadius: "8px",
        padding: "1.5rem",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        display: "flex",
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        color: "white !important",
    },
    taplrLogo: {
        zIndex: '3',
        marginTop: "-17%",
        [theme.breakpoints.up('sm')]: {
            width: "50%",
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        cursor: 'pointer',
        marginLeft: "auto",
        marginRight: "auto",
        display: "block"
    }, nothingReceived: {
        display: 'flex',
        alignItems: 'center'
    },
    styleLink: {
        fontSize: '12px',
        fontWeight: '600',
        cursor: 'pointer',
        color: "white",
    },
    actionType: {
        marginTop: '15px',
        marginBottom: '15px'
    },
    imageStyle: {
        height: '30px',
        width: '30px',
        marginRight: '15px'
    },
    googleButton: {
        backgroundColor: 'white !important',
        color: '#6a6a6a !important'
    },
    signupButton: {
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        alignItems: 'center',
        backgroundColor: '#1D3557 !important',
        color: 'white',
        '&:hover': {
            backgroundColor: 'white !important',
            color: '#1D3557 !important',

        }

    },
    regButton: {
        display: 'flex',
        alignItems: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff !important',
        color: '#C3996B !important',
        '&:hover': {
            backgroundColor: '#C3996B !important',
            color: '#ffffff !important',

        }
    },
    textField: {
        border: "#f0f0f0",

        '& .MuiOutlinedInput-root': {
            backgroundColor: "#f0f0f0",
            '&:focus': {
                borderColor: "#1D3557 !important",
            },
        },
        '& Mui-focused': {
            border: "1px solid #1D3557 !important",
        }
    },
    nameTextField: {
        border: "#f0f0f0",
        width: '45%',
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#f0f0f0",
            '&:focus': {
                borderColor: "#1D3557 !important",
            },
        },
        '& Mui-focused': {
            border: "1px solid #1D3557 !important",
        }
    },

    inputDiv: {
        marginTop: "10px",
        width: '95%',
        height: '70px',
        position: 'relative',
        marginBottom: '20px',
    },
    divider: {
        position: 'absolute',
        top: '30px',
        left: '40px',
    },
    dividerName: {
        position: 'absolute',
        top: '40px',
        left: '40px',
    },
    logInButton: {
        backgroundColor: "#1D3557 !important",
        '&:hover': {
            backgroundColor: 'white !important',
            color: '#1D3557 !important',

        }
    },
    nameDiv: {
        width: '48%',
        position: 'realtive',
        marginTop: "10px",
        height: '70px',

        marginBottom: '20px',
    },
    pWhatToDo: {
        marginBottom: '15px',
    },
    detailDiv: {
        width: '95%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between'

    },
    dividerLastName: {
        position: 'absolute',
        top: '40px',
        left: 'calc(49% + 60px)',
    },
    verifyButton: {
        backgroundColor: "#1D3557 !important",
    },
    buttonDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));