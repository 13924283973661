import { Divider, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dataService } from '../../../Services/data/data.service';
import DividerImage from '../../../Images/line2.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LandingPage(props) {

    const classes = useStyles();
    const history = useHistory();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);



    const gotoLogin = () => {
        history.push("/login");
    }
    const requestLogin = () => {
        console.log(firstName, lastName, email)
        const errors = {}
        if (lastName === null) {
            errors.lastName = 'Required';
        }
        if (firstName === null) {
            errors.firstName = 'Required';
        }
        if (email === null) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address';
        }

        if (Object.keys(errors).length > 0) {
            return errors;
        } else {
            dataService.requestLogin(firstName, lastName, email).then(response => {
                if (response.success === true) {
                    toast.success('Uw aanvraag werd verzonden! Wij nemen zo snel mogelijk contact op met u!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                } else {
                    toast.error('Er liep iets verkeerd, probeer opnieuw.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                }


            });

        }
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }



    return (
        <div style={{ backgroundColor: "#231F20" }}>
            <div class="container">
                <header class="headersec">
                    <div >
                        <div class="hderinr">
                            <div class="hdrlgo">
                                <a href="/"><img src="images/hderlogo.png" alt="" /></a>
                                <button className={classes.loginButton} onClick={() => gotoLogin()}>Login</button>

                            </div>
                        </div>
                    </div>
                </header>
                <div class="maadeSec">
                    <div class="mademain">
                
                        <div className={classes.infoAndVideoContainer}>

                            <div className={classes.infoPannel}>
                                <h2>Hoe efficiënt zijn jouw machines?</h2>
                                <ul class="ulList">
                                    <li class="listItem">
                                        <div className={classes.listPoint}>
                                            <span class="icon-Vector4"></span>
                                            <div class="media-object">
                                                <h5>Registreer</h5>
                                                <p>Verzamel real-time events per registratiepunt</p>
                                            </div>
                                        </div>
                                        <img className={classes.imageStyle} src={DividerImage} alt="" />
                                    </li>
                                    <li class="listItem">
                                        <div className={classes.listPoint}>
                                            <span class="icon-Vector5"></span>
                                            <div class="media-object">
                                                <h5>Evalueer</h5>
                                                <p>Bekijk alle geregistreerde events op een tijdsas</p>
                                            </div>
                                        </div>
                                        <img className={classes.imageStyle} src={DividerImage} alt="" />

                                    </li>
                                    <li class="listItem">
                                        <div className={classes.listPoint}>
                                            <span class="icon-Vector6"></span>
                                            <div class="media-object">
                                                <h5>Reageer</h5>
                                                <p>Verhoog je efficiëntie door gericht je processen te verbeteren</p>
                                            </div>
                                        </div>
                                        <img className={classes.imageStyle} src={DividerImage} alt="" />

                                    </li>
                                </ul>
                            </div>
                            <div className={classes.videoPannel}>
                                <div className={classes.videoResponsive}>
                                    <iframe src="https://www.youtube.com/embed/ubZkyYdHrPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    {/* <iframe src="https://www.youtube.com/embed/ubZkyYdHrPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loginSec">
                    <div >
                        <div class="loginhed">
                            <h1>Vraag nu je login aan</h1>
                        </div>
                        <div class="loginform">
                            {/* <div> */}
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="frmbx">
                                        <label>voornaam</label>
                                        <input type="text" onChange={handleFirstNameChange} value={firstName} placeholder="" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="frmbx">
                                        <label>familienaam</label>
                                        <input onChange={handleLastNameChange} value={lastName} type="text" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="frmbx">
                                        <label>emailadres</label>
                                        <input onChange={handleEmailChange} value={email} type="email" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="frmsubmt">
                                        <input type="submit" onClick={() => requestLogin()} value="Vraag nu je login aan" />
                                    </div>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>

                <div class="panelSec">
                    <div>
                        <div class="panelinr">
                            <img src="images/Design_02a.png" alt="" />
                            <div class="panelcont">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="pnlcontinr">
                                            <span class="icon-Vector1"></span>
                                            <h4>Minder dan een minuut</h4>
                                            <p>Dit is de kost om een registratiepunt te configureren</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="pnlcontinr">
                                            <span class="icon-Vector2-svg"></span>
                                            <h4>Gemakkelijk</h4>
                                            <p>Taplr is even inuïtief als Payconiq</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="pnlcontinr">
                                            <span class="icon-Vector3"></span>
                                            <h4>Real-time</h4>
                                            <p>Bekijk real-time wat er gebeurt per registratiepunt</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <footer class="foterSec">
                <div class="container">
                    <div class="ftrinr">
                        <a href="#url" class="ftrlgo"><img src="images/foterlogo.png" alt="" /></a>
                        <div class="ftrRt">
                            <p>© 2022 <a href="#url">Taplr</a>. Alle rechten voorbehouden.</p>
                            <a href="#url" target="_blank" class="ftrsocil"><span class="icon-Vector"></span></a>
                        </div>
                    </div>
                </div>
            </footer>

        </div>

    )
}



const useStyles = makeStyles((theme) => ({
    infoAndVideoContainer: {
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        },
    },
    imageStyle: {
        width: '100%'
    },
    infoPannel: {
        width: '49%',

        [theme.breakpoints.down('lg')]: {
            width: '100%',
            marginBottom: '50px',
        },
        '& h2': {

            color: '#F7921C',
            font: '700 42px/50px "Comfortaa", cursive',
        },
        '& ul': {
            width: '100%',
            display: 'inline-block',
            position: 'relative',
            '& li': {
                position: 'relative',
            },

            '& li:last-child': {
                margin: '0px',
            },

        },
        '& ul:before': {
            position: 'absolute',
            content: '""',
            top: '10px',
            bottom: '10px',
            left: '59px',
            width: '1.2px',
            background: '#F8931D',
            zIndex: '-1',

        },


    },
    listPoint: {
        display: 'flex',

        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid #F8931D',
            width: '118px',
            height: '118px',
            borderRadius: '100%',
            marginRight: '24px',
            color: '#F7921C',
            fontSize: '46px',
            background: '#231F20',
            position: 'relative',
        },

        '& div': {
            alignItems: 'center',
            '& h5': {
                color: '#F7921C',
                font: '600 24px/30px "Roboto", sans-serif',

            },
            '& p': {
                color: '#B9B9B9',
                font: '400 20px/27px "Roboto", sans-serif',
                margin: '10px 0 0',


            },

        },
    },
    videoPannel: {
        width: '48%',
        display: 'block',
        height: '100%',
        position: 'relative',
        marginBottom: 'auto',
        marginTop: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',

        [theme.breakpoints.down('lg')]: {
            marginTop: '50px',
            width: '100%',

        },


    },
    videoResponsive: {
        position: 'relative',
        height: '100%',
        width: '100%',
        paddingBottom: '56.25%',

        [theme.breakpoints.down('lg')]: {
            paddingBottom: '56.25%',


        },

        '& iframe': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
        }

    },
    loginButton: {
        backgroundColor: "#F8931D",
        color: "white",
        borderRadius: "20px",
        cursor: "pointer",
        outline: "none",
        border: "none",
        fontFamily: "'Roboto', sans-serif",
        fontSize: "24px",
        fontWeight: 500,
        float: "right",
        width: "120px",
        height: "60px",
        marginTop: "20px"
    },
    videoContainter: {
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        paddingTop: "56.25%",
    },
    video: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }

}));
export default LandingPage;