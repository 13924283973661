import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import TitlePage from '../../Shared/TitlePage';

function SelectMenuPage(props) {
    const [menuState, setMenuState] = useState(false)
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
       // props.actions.projectIdAction(null);
        // eslint-disable-next-line
    }, [])

    const openMenu = () => {
        setMenuState(true);
    }
    const closeMenu = () => {
        setMenuState(false);
    }
    const navigateTo = (destination) => {
        history.push(destination);
    }
    return (
        <div>
            <TitlePage Text="What do you want to do?" backbutton={false} {...props} />
            {/* 
            <div className={classes.titleStyle}>
                <div className={classes.headerLeft}>
                    <h1 className={classes.hstyle}>What do you want to do?</h1>
                </div>
                <div className={classes.headerMiddle}>
                    <img onClick={() => navigateTo('/')} className={classes.taplrLogo} src={process.env.PUBLIC_URL + "/images/Taplr.png"} alt="Taplr Logo"></img>
                </div>
                <div className={classes.headerRight}>

                    <Menu {...props} open={menuState} close={closeMenu}></Menu>
                    <IconButton onClick={openMenu} className={classes.menuButton} size="large" style={{ color:'white' }}>
                        <MenuIcon fontSize="inherit" />
                    </IconButton>
                </div>

            </div> */}

            <div className={classes.container}>
                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <NoteAddIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Create a new project linked to a specific location.
                        </div>
                        <Link to='/create' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Create a project</Button>

                        </Link>
                    </div>
                </div>

                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <TuneIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Manage existing projects. (Edit, delete, duplicate,...)
                        </div>
                        <Link to='/manage' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Manage projects</Button>

                        </Link>
                    </div>
                </div>

                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <BarChartIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    View all your projects. (See results, rapports,...)
                        </div>
                        <Link to='/projectreportoverview' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Project Overview</Button>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <TuneIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                   Configuration
                        </div>
                        <Link to='/configuration' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Configuration</Button>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectMenuPage;

const useStyles = makeStyles((theme) => ({

    container: {
        borderRadius: "8px",
        [theme.breakpoints.up('lg')]: {
            maxWidth: "50%",
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: "60%",
        },

        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",

        },
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px"
    },


    titleTextStyle: {
        display: "flex",
        marginLeft: "20px",
    },
    boxStyle: {
        display: "flex",
        justifyContent: "space-between",
        background: "#FFFFFF",
        borderRadius: "8px",
        paddingTop: "18px",
        paddingBottom: "18px",
        marginBottom: '25px',
        paddingLeft: '30px',
        paddingRight: '30px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },

    },
    menuItem: {
        color: '#1D3557',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '100% !important',
            marginBottom: '15px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
            fontSize: '1.2rem',
        },
        display: "flex",
        alignItems: "center",
    },

    iconStyle: {
        verticalAlign: "-8px",
        marginRight: "4px"
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
        },
    },
    linkButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
            minHeight: "46.8px",
            height: "90%",
            padding: "10px",
            fontSize: "1rem",
        },

        [theme.breakpoints.up('lg')]: {
            width: '250px',
            minWidth: "250px",
            height: "40px",
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            width: '200px',
            minWidth: "200px",
            fontSize: "1.2rem",
        },

        background: "#C3996B !important",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hstyle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white',
        whiteSpace: 'nowrap'
        // color: "#1D3557",
    },
    titleStyle: {
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        color: 'white'

    },
    headerLeft: {
        alignItems: 'center',
        display: 'flex',
        width: '33%'
    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center'

    },
    headerRight: {
        width: '33%',
        display: 'flex',
        justifyContent: 'end',
        color: 'white'
    },
    arrowBack: {
        padding: '15px',
        cursor: 'pointer'
    },
    taplrLogo: {
        width: '150px',
        cursor: 'pointer'
    }
}));