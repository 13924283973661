import React from 'react';

const Style = {
    color: "#696969",
    fontSize: "15px"
}

function DescriptionItem(props) {
    return (
            <p style={Style}>{props.Text}</p>
    )
}

export default DescriptionItem;