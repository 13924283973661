import React, { useEffect, useState } from 'react';
import TextfieldInput from '../../Shared/TextField';
import { TextField } from '@mui/material';
import TitlePage from '../../Shared/TitlePage';
import DescriptionItem from '../../Shared/DescriptionItem';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import Button from '@mui/material/Button';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@mui/icons-material/Save';
import TableList from '../CreateList/Components/TableCreateList';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import * as apiHelper from '../../../Utils/API/apiHelper';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Snackbar from '@mui/material/Snackbar';
// import { IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

function CreateExternalApiPage(props) {

    const classes = useStyles();
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [connectionName, setConnectionName] = useState("");
    const [connectionDesc, setConnectionDesc] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [errorConnectionName, setErrorConnectionName] = useState(false);
    const [errorApiKey, setErrorApiKey] = useState(false);
    const [htConnectionName, setHtConnectionName] = useState("");
    const [htApiKey, setHtApiKey] = useState("");
    const [emptyDetails, setEmptyDetails] = useState(false);
    const [apiBodyItems, setApiBodyItems] = useState([]);
    const [apiExampleLink, setApiExampleLink] = useState("");
    const [apiExamplePoints, setApiExamplePoints] = useState("");
    const [openToast, setOpenToast] = useState(false);

    useEffect(() => {
    }, [props.Login.userid]);

    const setConnectionNameState = (event) => {
        setConnectionName(event.target.value);
    }

    const setConnectionDescState = (event) => {
        setConnectionDesc(event.target.value);
    }

    const setApiKeyState = (event) => {
        setApiKey(event.target.value);
    }

    const setRows = (event) => {
        setApiBodyItems(event);
        var ExampleApiValuesMapped = event.map(item => ({ [item.itemName]: "Your data here" }))
        var NewExampleApiValues = JSON.stringify(Object.assign({}, ...ExampleApiValuesMapped), null, 2);
        setApiExamplePoints(NewExampleApiValues)
    }
    
    const validate = () => {
        var validationOK = true
        if (connectionName === "") {
            validationOK = false
            setHtConnectionName("Please enter a connection name")
            setErrorConnectionName(true)
        }
        else {
            setHtConnectionName("")
            setErrorConnectionName(false)
        }
        if (apiKey === "") {
            validationOK = false
            setHtApiKey("Please generate an API key")
            setErrorApiKey(true)
        } else {
            setErrorApiKey(false)
            setHtApiKey("")
        }

        if (apiBodyItems.length === 0 || apiBodyItems[0].itemName === "") {
            validationOK = false
            setEmptyDetails(true)
        }
        else {
            setEmptyDetails(false)
        }

        return validationOK
    }

    const sendToApi = () => {
        setApiExampleLink(apiHelper.ComposeUri(apiHelper.EXTERNALAPI) + apiKey)
        var ExampleApiValuesMapped = apiBodyItems.map(item => ({ [item.itemName]: "Your data here" }))
        var NewExampleApiValues = JSON.stringify(Object.assign({}, ...ExampleApiValuesMapped), null, 2);
        setApiExamplePoints(NewExampleApiValues)
        if (validate()) {
            var items = JSON.stringify(apiBodyItems.map(item => ({ Name: item.itemName, Description: item.itemDescription })))
            dataService.createNewApi(connectionName, connectionDesc, apiKey, items, GetUserID).then(results => {
                props.actions.externalApiNameAction(connectionName)
                props.actions.externalApiDescriptionAction(connectionDesc)
                props.actions.externalApiKeyAction(apiKey)
                props.actions.externalApiItemsAction(apiBodyItems.map(x => ({Id: x.Id, InsertedOn: x.InsertedOn, UserID: x.UserID, edit: false, itemName: x.itemName, itemDescription: x.itemDescription})))
                props.actions.externalApiIdAction(results.id)
                history.push("/modifyapi")
            })
        }
    }

    const generateApiKey = () => {
        let key = uuidv4()
        setApiKey(key)
        setApiExampleLink(apiHelper.ComposeUri(apiHelper.EXTERNALAPI) + key)
    }

    const handleOpenToast = () => {
        setOpenToast(true);
    };

    const handleCloseToast = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const handleCopyLink = () => {
        handleOpenToast();
        navigator.clipboard.writeText(apiExampleLink);
    };

    const handleCopyFormat = () => {
        handleOpenToast();
        navigator.clipboard.writeText(apiExamplePoints);
    };

    // const action = (
    //     <React.Fragment>
    //         <IconButton
    //             size="small"
    //             aria-label="close"
    //             color="inherit"
    //             onClick={handleCloseToast}>
    //             <CloseIcon fontSize="small" />
    //         </IconButton>
    //     </React.Fragment>
    // );

    // const buttonStyle = {
    //     backgroundColor: "#C3996B",
    //     color: "white",
    //     width: "220px",
    //     marginRight: "5px",
    //     height: "55px",
    //     fontSize: "15px"
    // }

    return (
        <div>
            <TitlePage Text="Create a new API" backbutton={true} {...props} />
            <div className={classes.container}>
                <div className={classes.projectOptions}>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Name of your API" />
                        <TextfieldInput label={connectionName ? " " : "API"} InputLabelProps={{ shrink: false }} value={connectionName} action={setConnectionNameState} helperText={htConnectionName} error={errorConnectionName} />
                    </div>
                </div>
                <div className={classes.projectOptions}>
                    <div className={classes.listDescriptionStyle}>
                        <DescriptionItem Text="Description of your API" />
                        <TextfieldInput label={connectionDesc ? " " : "Description"} InputLabelProps={{ shrink: false }} rows={3} multiline value={connectionDesc} action={setConnectionDescState} />
                    </div>
                </div>
                <div className={classes.projectOptions}>
                    <div className={classes.listDescriptionStyle}>
                        <DescriptionItem Text="Create API key" />
                        <div className={classes.generateApiKeyContainer}>
                            <Button size='large' variant="contained" style={{ width: "220px", background: "#C3996B", color: 'white' }} onClick={() => generateApiKey()}>GENERATE API KEY</Button>
                            <TextField value={apiKey} style={{ marginLeft: "5px" }} fullWidth action={setApiKeyState} label="API key" helperText={htApiKey} error={errorApiKey} InputProps={{ readOnly: true, }} />
                        </div>
                    </div>
                </div>
                {emptyDetails === true ?
                    <div style={{ marginTop: "2rem" }}>
                        <DescriptionItem Text="Add data points" />
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableList {...props} rows={apiBodyItems} setRows={setRows} makeSave={sendToApi} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one data point.</p>
                    </div>
                    :
                    <div style={{ marginTop: "2rem" }}>
                        <DescriptionItem Text="Add data points" />
                        <Grid item xs={12} sm={12} md={12}>
                            <TableList {...props} rows={apiBodyItems} setRows={setRows} />
                        </Grid>
                    </div>}
                <div className={classes.buttonContainer}>
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                </div>
                <div style={{ marginTop: "20px" }}>
                    <DescriptionItem Text="Your unique API link" />
                    <div className={classes.ApiContainer}  style={{marginBottom: "15px"}}>
                        <TextField fullWidth disabled multiline value={apiExampleLink} label={apiExampleLink ? " " : "API Link"} InputLabelProps={{ shrink: false }} style={{ marginRight: "5px" }} />
                        {apiExampleLink !== "" ?
                            <Button size='large' variant="contained" onClick={handleCopyLink} style={{ background: "#C3996B", color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                            :
                            <Button disabled size='large' variant="contained" onClick={handleCopyLink} style={{ color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                        }
                    </div>
                    <DescriptionItem Text="API format example" />
                    <div className={classes.ApiContainer}>
                        <TextField fullWidth disabled multiline value={apiExamplePoints} label={apiExamplePoints ? " " : "API Example"} InputLabelProps={{ shrink: false }} style={{ marginRight: "5px" }} />
                        {apiExamplePoints !== "" ?
                            <Button size='large' variant="contained" onClick={handleCopyFormat} style={{ background: "#C3996B", color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                            :
                            <Button disabled size='large' variant="contained" onClick={handleCopyFormat} style={{ color: 'white', marginBottom: "8px" }} startIcon={<FileCopyIcon />} content="Copy">Copy</Button>
                        }
                    </div>
                </div>
            </div>
            <Snackbar
                open={openToast}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message="Copied to clipboard"
            />
        </div>
    )
}

export default CreateExternalApiPage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    qrButton: {
        backgroundColor: "#C3996B"
    },
    buttonContainer: {
        marginTop: '25px',
        display: 'flex',
        alignItems: 'center',
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
        marginTop: "10px"
    },
    projectOption: {
        [theme.breakpoints.up('sm')]: {
            width: "250px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginRight: '25px',
    },
    listDescriptionStyle: {
        width: '100%',
    },
    generateApiKeyContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
    },
    ApiContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'flex-end',
    },
    generateApiKeyButton: {
        backgroundColor: "#C3996B",
        color: "white",
        width: "220px",
        borderRadius: "4px",
        border: "none",
        outline: "none",
        cursor: "pointer",
        marginRight: "5px",
        height: "55px",
        fontSize: "15px"
    }
}));