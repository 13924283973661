import React, { useEffect, useState, createRef } from "react";
import { makeStyles } from '@material-ui/core';
import { Chart, CommonSeriesSettings, Legend, ArgumentAxis, Label, Series, Tooltip, ValueAxis, Grid, MinorGrid, CommonPaneSettings, Export, Border, ZoomAndPan, VisualRange, } from 'devextreme-react/chart';
import { controlTypes } from '../../../../Config/constants';

export default function FilterListRow(props) {
    const [labelData, setLabelData] = useState([]);
    useEffect(() => {
        setLabelData([]);
    }, [props.timeRange, props.refresh, props.endValue, props.startValue]);

    function getRGB(c) {
        return parseInt(c, 16) || c
    }

    function getsRGB(c) {
        return getRGB(c) / 255 <= 0.03928
            ? getRGB(c) / 255 / 12.92
            : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
    }

    function getLuminance(hexColor) {
        if (hexColor.includes('#') && hexColor !== undefined) {
            return (
                0.2126 * getsRGB(hexColor.substr(1, 2)) +
                0.7152 * getsRGB(hexColor.substr(3, 2)) +
                0.0722 * getsRGB(hexColor.substr(-2))
            )
        } else {
            return (
                0.2126 * hexColor +
                0.7152 * hexColor +
                0.0722 * hexColor
            )
        }
    }

    function getContrast(f, b) {
        const L1 = getLuminance(f)
        const L2 = getLuminance(b)
        return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
    }

    function getTextColor(bgColor) {
        if (bgColor === null) {
            return '#ffffff';
        } else {
            const whiteContrast = getContrast(bgColor, '#ffffff')
            const blackContrast = getContrast(bgColor, '#000000')
            return whiteContrast > blackContrast ? '#ffffff' : '#000000';
        }
    }

    function customizeValueAxis(e) {
        if (e.valueText.includes('PM') || e.valueText.includes('AM')) {
            let date = new Date(e.value);
            let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            let time = hours + ":" + minutes + ":" + seconds;
            let htmlItem = '';
            htmlItem += '<div style="padding-right: 5px">' + time + '</div><br>';
            return htmlItem;
        }
        else {
            if (e.valueText.includes('s')) { return e.valueText }
            else {
                let date = new Date(e.value);
                let strDay = date.toLocaleDateString('en-UK', {
                    weekday: 'long',
                });
                let htmlItem = '';
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let year = date.getFullYear();
                htmlItem += '<div>' + strDay + '</div><br>';
                htmlItem += '<div>' + day + '/' + month + '/' + year + '</div>';
                return htmlItem;
            }
        }
    }

    function customizeTooltip(pointInfo) {
        let strTime = pointInfo.valueText;
        let insertedOn = pointInfo.point.data.insertedOn;
        let updatedOn = pointInfo.point.data.updatedOn;

        let times = strTime.split(' - ');
        let startTime = times[0];
        let endTime = times[1];
        let value = '';
        let strStartTime =  convertToTimeString(startTime);
        let strEndTime = convertToTimeString(endTime);
        let time = '';
        let commentText = pointInfo.point.data.commentText;
        let commentImage = pointInfo.point.data.commentImage;

        if (pointInfo.point.data.typeId === controlTypes.STOPWATCH) {
            strStartTime =  convertToTimeString(insertedOn);
            strEndTime =  convertToTimeString(updatedOn);


            value = pointInfo.point.data.measurementValue;
            time = strStartTime + ' - ' + strEndTime;
        } else {
            value = pointInfo.point.data.measurementValue;
            time = strStartTime;
        }
        if (pointInfo.point.data.typeId === controlTypes.CAMERA) {
            let imghtml = '';
            if (props.imgData.some(x => x.imageId === pointInfo.point.data.image)) {
                imghtml += '<div style="padding-bottom: 5px"><b>Project: </b>' + pointInfo.point.data.projectName + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Company: </b>' + pointInfo.point.data.companyName + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Location: </b>' + pointInfo.point.data.resource + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Time: </b>' + time + '</div>';
                if (pointInfo.point.data.commentText !== '' && pointInfo.point.data.commentText !== null) {
                    imghtml += '<div style="padding-bottom: 5px"><b>Comment: </b>' + commentText + '</div>';
                }
                imghtml += '<img  src="data:image/png;base64,' + pointInfo.point.data.thumbnail + '"></img>';
            } else {
                imghtml += '<div style="padding-bottom: 5px"><b>Project: </b>' + pointInfo.point.data.projectName + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Company: </b>' + pointInfo.point.data.companyName + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Location: </b>' + pointInfo.point.data.resource + '</div>';
                imghtml += '<div style="padding-bottom: 5px"><b>Time: </b>' + time + '</div>';
                if (pointInfo.point.data.commentText !== '' && pointInfo.point.data.commentText !== null) {
                    imghtml += '<div style="padding-bottom: 5px"><b>Comment: </b>' + commentText + '</div>';
                }
                imghtml += '<img  src="data:image/png;base64,' + pointInfo.point.data.thumbnail + '"></img>'
            }
            return { text: `${pointInfo.argumentText}<br/> ${time}`, html: imghtml, visible: true }
        } else {
            let text = '';
            text += '<div><b>Project:</b> ' + pointInfo.point.data.projectName + '</div>';
            text += '<div><b>Company: </b>' + pointInfo.point.data.companyName + '</div>';
            text += '<div><b>Location: </b>' + pointInfo.point.data.resource + '</div>';
            text += '<div><b>Description: </b> ' + pointInfo.argumentText + '</div>';
            text += '<div><b>' + pointInfo.point.data.type + ' value: </b>' + value + ' </div>';
            text += '<div><b>Time: </b>' + time + '</div>';
            if (pointInfo.point.data.commentText !== '' && pointInfo.point.data.commentText !== null) {
                text += '<div style="padding-bottom: 5px"><b>Comment: </b>' + commentText + '</div>';
            }
            if (pointInfo.point.data.commentImage !== '' && pointInfo.point.data.commentImage !== null) {
                text += '<div style="padding-bottom: 5px"><b>Comment Image: </b></div>';
                text += '<img style="border-radius: 5px; max-width: 100%; max-height: calc(20vh - 16px);" src="data:image/png;base64,' + commentImage + '"></img>';
            }
            return { html: text }
        }
    }

    function customizeSeries(pointInfo) {
        return { color: 'blue' }
    }

    function customizePoint(pointInfo) {
        // setLabelData([]);
        if (pointInfo.data.typeId !== controlTypes.CAMERA) {
            return { color: this.data.color, border: { color: 'grey', width: 1, visible: true, dashStyle: 'dot', opacity: 1 } }
        } else {
            return { image: { url: "data:image/png;base64," + pointInfo.data.thumbnail, width: 50, height: 50 }, visible: true };
        }
    }

    function convertToTimeString(time) {
        let dateTime = new Date(time);
        let strTime = dateTime.toTimeString();
        strTime = strTime.split(' ')[0];
        return strTime;
    }

    function customizeText(e) {
        let tempLabelObj = labelData;
        let tempObj = {};
        let text = '';
        let color = '';
        if (e.point.data.color !== undefined) {
            color = getTextColor(e.point.data.color);
        } else {
            color = 'black';
        }
        if (e.point.data.typeId === 2) {
            //Stopwatch
            // if (tempLabelObj.length === 0 || !tempLabelObj.some(x => x.id === e.point.data.id)) {
            if ((tempLabelObj.length === 0 || !tempLabelObj.some(x => x.id === e.point.data.id)) && e.point.data.measurementValue !== "") {
                tempObj = { id: e.point.data.id };
                tempLabelObj.push(tempObj);
                setLabelData(tempLabelObj);
                return `${text += '<div style="color: ' + color + '">' + e.point.data.measurementValue + '</div>'}`;
            }
        } else {
            return `${text += '<div style="color: ' + color + '; background: none">' + e.point.data.measurementValue + '</div>'}`;
        }
    }



    const chartRef = createRef();
    return (
        <React.Fragment>
            <Chart
                id="chart"
                dataSource={props.data}
                rotated={true}
                customizePoint={customizePoint}
                resolveLabelOverlapping="hide"
                title="Evaluate your events"
                ref={chartRef}>
                <CommonSeriesSettings
                    type="rangeBar"
                    value="measurementValue"
                    argumentField="description"
                    rangeValue1Field="startDate"
                    rangeValue2Field="endDate"
                    barOverlapGroup="description">
                </CommonSeriesSettings>
                <Legend visible={false} />

                {/* SC: Series for the stopwatches */}
                <Series
                    type={"rangeBar"}
                    valueField="startDate"
                    argumentField={"description"}>
                    <Label position={'inside'}  visible={true} indent={30} customizeText={customizeText} />
                </Series>
                {/* SC: Series for the counters */}
                <Series

                    type="scatter"
                    valueField="startDate"
                    argumentField="counterDescription"
                    data="measurementValue">
                    <Label position={'inside'}  visible={true} customizeText={customizeText} />
                </Series>
                {/* SC: Series for the free text */}
                <Series
                    type="scatter"
                    valueField="startDate"
                    argumentField="freetextDescription"
                    data="measurementValue">
                    <Label visible={true} customizeText={customizeText} />
                </Series>
                {/* SC: Series for the datalist */}
                <Series
                    type="scatter"
                    valueField="startDate"
                    argumentField="datalistDescription"
                    data="measurementValue">
                    <Label location="left" alignment="right" visible={true} customizeText={customizeText} />
                </Series>
                {/* SC: Series for the image */}
                <Series
                    type="scatter"
                    valueField="startDate"
                    argumentField="cameraDescription"
                    data="measurementValue">
                </Series>
                {/* SC: Series for the ExternalApiValues */}
                <Series
                    type="scatter"
                    valueField="startDate"
                    argumentField="externalApiDescription"
                    data="measurementValue">
                    <Label visible={true} customizeText={customizeText} />
                </Series>
                <Tooltip
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                />

                <ValueAxis>
                    <Grid visible={true} />
                    <Label customizeText={customizeValueAxis} />
                </ValueAxis>

                <ArgumentAxis tickInterval={5}>
                    <Grid visible={true} />
                    <MinorGrid visible={true} />
                </ArgumentAxis>

                <Export enabled={true} />

                <CommonPaneSettings>
                    <Border visible={true} />
                </CommonPaneSettings>
                {/* <ZoomAndPan
                    panKey="shift"
                    argumentAxis="both"
                    valueAxis="both"
                    allowMouseWheel={true}
                    dragToZoom="true" /> */}
            </Chart>
        </React.Fragment>
    )
}

const useStyles = makeStyles(() => ({

}));
