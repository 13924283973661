import React from 'react';
import Button from '@mui/material/Button'

function ButtonNormalDelete(props) {
    return (
        <Button size='large' startIcon={props.startIcon} type={props.type} style={ButtonStyle} onClick={props.onClick} variant="contained">{props.content}</Button>
    )
}

const ButtonStyle = {
    background: "#ff4c54"
}

export default ButtonNormalDelete;