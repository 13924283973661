import React, { useEffect, useState } from 'react';
import TextfieldInput from '../../Shared/TextField';
import TitlePage from '../../Shared/TitlePage';
import DescriptionItem from '../../Shared/DescriptionItem';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonNormalText from '../../Shared/Buttons/ButtonNormalText';
import ButtonNormalDelete from '../../Shared/Buttons/ButtonNormalDelete';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@mui/icons-material/Save';
import TableList from './Components/TableModifyList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ModifyListPage(props) {

    const classes = useStyles();
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [listName, setListName] = useState("");
    const [listDescription, setListDescription] = useState("");
    const [helperTextListName, setHelperTextListName] = useState(null);
    const [errorListName, setErrorListName] = useState(false);
    const [emptyDetails, setEmptyDetails] = useState(false);
    const [listItems, setListItems] = useState([]);
    const [delopen, setDelOpen] = useState(false);
    const [viewSize, setViewSize] = useState(0);
    const [listId, setListId] = useState(0);
    var today = new Date();

    useEffect(() => {
        let tempListName = props.listCopy.listName;
        let tempListDescription = props.listCopy.listDescription;
        let tempListItems = [...props.listCopy.listItems.map(x => ({ Id: x.Id, itemName: x.itemName, itemDescription: x.itemDescription, InsertedOn: x.InsertedOn, InsertedByUserId: x.InsertedByUserId, edit: false }))];
        let tempListId = props.listCopy.listId;
        
        setListDescription(tempListDescription)
        setListName(tempListName)
        setListItems(tempListItems)
        setListId(tempListId)
        const display1 = window.matchMedia('(min-width: 520px)');
        const listener = () => {
            if (!display1.matches) {
                setViewSize(1)
            }
            else {
                setViewSize(0)
            }
        }
        listener();
        window.addEventListener('resize', listener);

    }, [props.Login.userid]);

    const handleDialogOpenDel = () => {
        setDelOpen(true);
    }
    const handleDialogCloseDel = () => {
        setDelOpen(false);
    }

    const handleDeleteList = () => {
        let userid = props.Login.userid
        let DataListItems = listItems.map(x => ({ Name: x.itemName, Description: x.itemDescription, InsertedOn: x.InsertedOn, InsertedByUserId: x.UserID, Id: x.Id }));
        dataService.deleteDataList(listName, listDescription, today, userid, DataListItems, listId).then(response => {
            if (response.success === true) {
                toast.success('Dropdown was deleted successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            } else {
                toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            }

        });
        history.push("/dropdownoverview");
    }

    const setList = (event) => {
        setListName(event.target.value);
    }

    const setDescription = (event) => {
        setListDescription(event.target.value);
    }

    const sendToApi = () => {
        if (listName === "") {
            setHelperTextListName("Please enter a dropdown name.")
            setErrorListName(true)
        }
        else {
            setHelperTextListName(null)
            setErrorListName(false)
            setEmptyDetails(false)
            if (listItems.length === 0) {
                setEmptyDetails(true)
            }
            else {
                // API CALL HERE
                let userid = props.Login.userid
                let DataListItems = listItems.map(x => ({ Name: x.itemName, Description: x.itemDescription, InsertedOn: x.InsertedOn, InsertedByUserId: x.InsertedByUserId, Id: x.Id }));
                dataService.modifyDataList(listName, listDescription, today, userid, DataListItems, listId).then((response) => {
                    if (response.success === true) {
                        toast.success('Dropdown was saved successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    } else {
                        toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    }
                });
            }
        }
    }

    return (
        <div>
            <TitlePage Text="Modify your dropdown" backbutton={true} {...props} />
            <div className={classes.container}>
                <div className={classes.projectOptions}>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Name of your dropdown" />
                        <TextfieldInput value={listName} action={setList} label={listName ? " " : "Dropdown"} InputLabelProps={{ shrink: false }} helperText={helperTextListName} error={errorListName} />
                    </div>
                </div>
                <div className={classes.projectOptions}>
                    <div className={classes.listDescriptionStyle}>
                        <DescriptionItem Text="Description of your dropdown" />
                        <TextfieldInput rows={3} multiline value={listDescription} action={setDescription} label={listDescription ? " " : "Description"} InputLabelProps={{ shrink: false }} />
                    </div>
                </div>
                {emptyDetails === true ?
                    <div style={{ marginTop: "1rem" }}>
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableList {...props} rows={listItems} setRows={setListItems} makeSave={sendToApi} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one item.</p>
                    </div>
                    :
                    <div style={{ marginTop: "1rem" }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableList {...props} rows={listItems} setRows={setListItems} />
                        </Grid>
                    </div>}
                <div className={classes.buttonContainer}>
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                    <ButtonNormalDelete startIcon={<DeleteIcon />} content="Delete" onClick={handleDialogOpenDel}>Delete</ButtonNormalDelete>
                </div>
            </div>

            <Dialog
                open={delopen}
                onClose={handleDialogCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete dropdown"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you want to delete dropdown " + listName + " ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonNormalText content="Close" onClick={handleDialogCloseDel}>Close</ButtonNormalText>
                    <ButtonNormalDelete startIcon={<DeleteIcon />} content="Delete" onClick={handleDeleteList}>Delete</ButtonNormalDelete>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModifyListPage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    qrButton: {
        backgroundColor: "#C3996B"
    },
    buttonContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    projectOption: {
        [theme.breakpoints.up('sm')]: {
            width: "250px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginRight: '25px',
    },
    listDescriptionStyle: {
        width: '100%',
    }
}));