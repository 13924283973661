import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Stack from '@mui/material/Stack';
import StopwatchIcon from './timerStyle.svg';
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";
import { LightenDarkenColor } from 'lighten-darken-color';
import Card from '@mui/material/Card';

function Stopwatch(props) {

    const classes = useStyles();
    const mounted = useRef(true);
    const [extraContent, setExtraContent] = useState(false)
    const [timerStingsje, setTimerString] = useState(null)
    const [started, setStarted] = useState(false)
    const [startTime, setStartTime] = useState(null)
    const countRef = useRef(null)

    useEffect(() => {

        var s = (props.stopwatch.value * 1000)

        function pad(number) {
            if (number < 10) {
                return number = "0" + String(number)
            }
            else {
                return number = String(number)
            }
        }

        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        s = (s - mins) / 60
        var hrs = s % 24;
        s = (s - hrs) / 24

        if (mounted.current) {
            setTimerString(pad(hrs) + ':' + pad(mins) + ':' + pad(secs));
        }

    }, [props.refresh]);

    const handleStart = () => {
        setStarted(true)
        setStartTime(new Date())

        if (!started) {
            let startTime = new Date()
            countRef.current = (setInterval(() => {

                var s = (new Date() - startTime + parseInt(props.stopwatch.value) * 1000)

                function pad(number) {
                    if (number < 10) {
                        return number = "0" + String(number)
                    }
                    else {
                        return number = String(number)
                    }
                }

                var ms = s % 1000;
                s = (s - ms) / 1000;
                var secs = s % 60;
                s = (s - secs) / 60;
                var mins = s % 60;
                s = (s - mins) / 60
                var hrs = s % 24;
                s = (s - hrs) / 24

                if (mounted.current) {
                    setTimerString(pad(hrs) + ':' + pad(mins) + ':' + pad(secs));
                }

            }
                , 1000
            ))

            props.startAction(props.stopwatch.value, props.id, new Date());
        }
    }

    const handleExtraContentClick = () => {
        setExtraContent(!extraContent);
    };

    const handlePause = () => {
        if (started) {
            setStarted(false)
            clearInterval(countRef.current)
            props.stopAction(Math.floor(parseInt(props.stopwatch.value) + (new Date() - startTime) / 1000), props.id, new Date(), false);
            // props.action(formatDateTime(), props.id);
        }
    }

    const handleReset = () => {
        setStarted(false)
        clearInterval(countRef.current)
        setTimerString("00:00:00")
        props.stopAction(Math.floor(parseInt(props.stopwatch.value) + (new Date() - startTime) / 1000), props.id, new Date(), true);
    }

    return (
        <Card>
            <div className={classes.StyleStopwatch} style={{
                                color: props.textColor,
                backgroundColor: `${props.color}`
            }}>
                <div className={classes.headerDiv} >
                    <h2 className={classes.h2style}>{props.description}</h2>
                    <img className={classes.imageStyle} src={StopwatchIcon} alt="" />
                </div>
                <Divider></Divider>
                <div className={classes.stopwatchDev} style={{ backgroundColor: LightenDarkenColor(props.color, 20) }}>
                    <p className={classes.Style}>{timerStingsje}</p>
                </div>
                <Divider></Divider>
                <Stack direction="row" className={classes.buttonDiv}
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}>
                    <IconButton size={'large'} className={classes.ResetStyle} onClick={handleReset} style={{ color: props.textColor}} >
                        <RestartAltIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton size={'large'} className={classes.PauseStyle} onClick={handlePause} style={{ color: props.textColor}}>
                        <PauseIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton size={'large'} className={classes.StartStyle} onClick={handleStart} style={{color: props.textColor}}>
                        <PlayArrowIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </div>
        </Card>
    );
}
export default Stopwatch;

const useStyles = makeStyles({

    headerDiv: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
    },
    h2style: {
        margin: '0',
        zIndex: '2',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    Style: {
        borderRadius: "50px",
        textAlign: "center",
        fontSize: "2.5rem",
        margin: "0",
    },
    imageStyle: {
        position: 'absolute',
        right: '3%',
        opacity: '50%',
        width: '50px'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
    },
    StartStyle: {
        background: "#4CAF50",
        color: "white",
        textAlign: "center",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
    PauseStyle: {
        backgroundColor: "#C3996B",
        color: "white",
        textAlign: "center",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
    stopwatchDev: {
        display: "flex",
        justifyContent: "center",
        height: '34%',
        alignItems: 'center',
    },
    ResetStyle: {
        background: "#ff4c54",
        color: "white",
        textAlign: "center",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
    StyleStopwatch: {
        overflow: 'hidden',
        backgroundRepeat: "no-repeat",
        backgroundSize: "10rem 10rem",
        backgroundPosition: "center",
        height: "12rem"
    }
});