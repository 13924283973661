import React, { useEffect, useState, useRef } from 'react';
import TextfieldInput from '../../Shared/TextField';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import CopyQrLink from '../../Shared/CopyQrLink';
import DateSelectionFuture from "../../Shared/DateSelection/DateSelectionFuture";
import { Link } from 'react-router-dom';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import DeleteIcon from '@mui/icons-material/Delete';
import TableDragResources from '../../Shared/Tables/TableDragResources';
import DescriptionItem from '../../Shared/DescriptionItem';
import DropdownCompany from '../../Shared/Dropdowns/DropdownCopmany';
import DropdownResources from '../../Shared/Dropdowns/DropdownResources';
import ButtonNormalText from '../../Shared/Buttons/ButtonNormalText';
import ButtonNormalDelete from '../../Shared/Buttons/ButtonNormalDelete';
import CustomizedMenus from '../../Shared/CustomizedMenus';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import QRCode from "qrcode.react";
import ApiHelper from '../../../Utils/API/apiHelper';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parseNewDateToDDMMYYY } from "../../../Utils/dateHelper";

import TitlePage from '../../Shared/TitlePage';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddIcon from '@mui/icons-material/Add';

import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';


import Counter from '../../../../src/Images/counterStyle.svg';
import Stopwatch from '../../../../src/Images/timerStyle.svg';
import FreeText from '../../../../src/Images/Freetext.svg';
import DataList from '../../../../src/Images/DataList.svg';
import Scanner from '../../../../src/Images/barcodeScanner.svg';
import Image from '../../../../src/Images/camera.svg';
import API from '../../../../src/Images/api.svg';
toast.configure();

function ModifyPage(props) {
    var today = new Date();
    const [FromDate, setFromDate] = useState(today);
    const [ToDate, setToDate] = useState(today);
    const [projectQR, setProjectQR] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [resoureName, setResourceName] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState(null);
    const [OldCompanyId, setOldCompanyId] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [companies, setCompanies] = useState([]);
    const [resources, setResources] = useState([]);
    const [projectDetailRows, setProjectDetailRows] = useState([]);
    const [delopen, setDelOpen] = useState(false);
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [openQr, setOpenQr] = useState(false);
    const componentRef = useRef();
    const appSettings = ApiHelper.appSettings;
    const classes = useStyles();
    const [types, setTypes] = useState([]);
    const [activeProject, setActiveProject] = useState(true);
    const [helperTextProjectName, setHelperTextProjectName] = useState(null);
    const [helperTextCompany, setHelperTextCompany] = useState(null);
    const [helperTextResourceDescription, setHelperTextResourceDescription] = useState(null);
    const [errorProjectName, setErrorProjectName] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [errorResourceDescription, setErrorResourceDescription] = useState(false);
    const [emptyDetails, setEmptyDetails] = useState(false);

    useEffect(() => {
        dataService.getCompanies(GetUserID).then(results => {
            setCompanies(results);
        })
        dataService.getResources(GetUserID).then(results => {
            setResources(results);
        })
        dataService.getTypes().then(results => {
            setTypes(results.sort((a, b) => a.description.localeCompare(b.description)));
        });
        dataService.getProjectDetailsByQr(props.projectCopy.projectQr).then(results => {
            let tempProjectDetailRows = [];
            if (results.some(x => x.id)) {
                tempProjectDetailRows = results.map(x => ({ comment: x.hasComment, color: x.color, typeId: x.typeId, type: x.typeId, description: x.description, Id: x.id, edit: false, sequence: x.sequence, dataListId: x.dataListId, externalApiId: x.externalApiId, price: x.price, isProfit: x.isProfit, costProfit: (x.isProfit ? '+' : '-') }));
            }
            tempProjectDetailRows.sort((a, b) => a.sequence - b.sequence);
            setProjectDetailRows(tempProjectDetailRows);
        })

        setProjectQR(props.projectCopy.projectQr);
        setProjectName(props.projectCopy.projectName);
        setCompanyName(props.projectCopy.company);
        setCompanyId(props.projectCopy.companyId);
        setOldCompanyId(props.projectCopy.companyId);
        setResourceName(props.projectCopy.resource);
        let fromDateString = "";
        if (props.projectCopy.fromDate !== null) {
            fromDateString = Date.parse(props.projectCopy.fromDate);
        }
        else {
            fromDateString = Date.parse(today);
        }
        let fromDateObject = new Date(fromDateString);
        setFromDate(fromDateObject);
        let toDateString = "";
        if (props.projectCopy.toDate !== null) {
            toDateString = Date.parse(props.projectCopy.toDate);
        }
        else {
            toDateString = Date.parse(today);
        }
        let toDateObject = new Date(toDateString);
        setToDate(toDateObject);

        setProjectId(props.projectCopy.projectId);
        let currentDate = new Date();
        let Diffrence = dateRange(fromDateObject, toDateObject, currentDate, currentDate);

        if (Diffrence) {
            setActiveProject(true);
        }
        else {
            setActiveProject(false);
        }
    }, []);

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    function dateRange(a_start, a_end, b_start, b_end) {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        // if (b_start <  a_start && a_end   >  b_end) return true; // a overlaps b

        if (datesAreOnSameDay(a_start, b_start)) return true;
        if (datesAreOnSameDay(b_end, a_end)) return true;
        return false;
    }

    const setProject = (event) => {
        setProjectName(event.target.value);
    }
    const setTheFromDate = (event) => {
        if (event !== FromDate) {
            setFromDate(event);
        }
    }
    const setTheToDate = (event) => {
        if (event !== ToDate) {
            setToDate(event);
        }
    }

    const handleClickOpenQr = () => {
        setOpenQr(true);
    };
    const handleCloseQr = () => {
        setOpenQr(false);
    };
    const handleDialogOpenDel = () => {
        setDelOpen(true);
    }
    const handleDialogCloseDel = () => {
        setDelOpen(false);
    }
    const setCompany = (event) => {
        if (event.inputValue) {
            if (companies.some(x => x.name === event.inputValue)) {
                setCompanyId(companies.find(x => x.name === event.inputValue).id);
                setCompanyName(event.inputValue);
            }
        }
        else {
            if (event.target.textContent) {
                if (companies.some(x => x.name === event.target.textContent)) {
                    setCompanyId(companies.find(x => x.name === event.target.textContent).id);
                    setCompanyName(event.target.textContent);
                }
            }
            else {
                if (companies.some(x => x.name === event.target.value)) {
                    setCompanyId(companies.find(x => x.name === event.target.value).id);
                }
                setCompanyName(event.target.value);
            }
        }
    }

    const setDates = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
        let tempFromDate = fromDate;
        let tempToDate = toDate;
        props.actions.fromDateAction(tempFromDate);
        props.actions.toDateAction(tempToDate);
    }

    const save = (newProjectrows) => {
        if (projectName === "" || resoureName === "" || CompanyId === "" || CompanyName === "" || FromDate === "" || ToDate === "" || resoureName == null || FromDate == null || ToDate == null) {
            if (projectName === "") {
                setHelperTextProjectName("Please enter a project name.");
                setErrorProjectName(true);
            }
            else {
                setHelperTextProjectName(null);
                setErrorProjectName(false);
            }
            if (resoureName === "" || resoureName === null || resoureName === undefined) {
                setHelperTextResourceDescription("Please Select or enter a Location.");
                setErrorResourceDescription(true);
            }
            else {
                setHelperTextResourceDescription(null);
                setErrorResourceDescription(false);
            }
            if (CompanyId === "" || CompanyId === null || CompanyName === "" || CompanyName === null || CompanyName === undefined) {
                setHelperTextCompany("Please Select or Create a Company.");
                setErrorCompany(true);
            }
            else {
                setHelperTextCompany(null);
                setErrorCompany(false);
            }
        } else {
            setHelperTextResourceDescription(null);
            setErrorResourceDescription(false);
            setHelperTextCompany(null);
            setErrorCompany(false);
            setHelperTextProjectName(null);
            setErrorProjectName(false);
            setEmptyDetails(false);
            if (newProjectrows.length === 0 || newProjectrows.find(x => x.Id).edit || newProjectrows.find(x => x.type === null) != undefined) {
                setEmptyDetails(true);
            }
            else {
                setEmptyDetails(false);
                var i = 1;
                let def = newProjectrows.map(x => ({ HasComment: x.comment, color: x.color, description: x.description, typeId: x.type, type: x.type, Id: x.Id, projectId: projectId, sequence: x.sequence, dataListId: x.dataListId, externalApiId: x.externalApiId, price: (x.price === "" || isNaN(x.price) || x.price === undefined || x.price === null ? 0 : x.price), isProfit: x.isProfit, costProfit: (x.isProfit ? '+' : '-'), newRow: x.newRow })).sort((a, b) => a.sequence - b.sequence);
                def.map((detail, index) => {
                    detail.sequence = index + 1;
                });
                let tempDef = [...def];
                // props.actions.detailsAction(def);
                dataService.updateProjects(GetUserID, projectName, resoureName, OldCompanyId, CompanyId, CompanyName, FromDate, ToDate, def, projectId, projectQR);
            }
        }
    }

    const setResource = (event) => {
        if (event.inputValue) {
            setResourceName(event.inputValue);
        }
        else {
            if (event.target.textContent) {
                setResourceName(event.target.textContent);
            }
            else {
                setResourceName(event.target.value);
            }
        }
    }

    const resultsRedirect = () => {
        let tempProjectId = projectId;
        let tempCompanyId = CompanyId;
        let tempCompanyName = CompanyName;
        let tempResourceName = resoureName;
        let tempFromDate = parseNewDateToDDMMYYY(FromDate);
        let tempToDate = parseNewDateToDDMMYYY(ToDate);
        let tempProjectRows = [...projectDetailRows];
        props.actions.projectIdAction(tempProjectId);
        props.actions.companyAction(tempCompanyName);
        props.actions.companyIdAction(tempCompanyId);
        props.actions.resourceAction(tempResourceName);
        props.actions.fromDateAction(tempFromDate);
        props.actions.toDateAction(tempToDate);
        props.actions.detailsAction(tempProjectRows);
        props.actions.projectReportAction(false);
        history.push("/results");
    }
    const financialDashboardRedirect = () => {
        let tempProjectId = projectId;
        props.actions.projectIdAction(tempProjectId)
        history.push("/projectreport");
    }

    const duplicateRedirect = () => {
        let tempCompanyId = CompanyId;
        let tempCompanyName = CompanyName;
        let tempResourceName = resoureName;
        let tempFromDate = FromDate;
        let tempToDate = ToDate;
        let tempProjectRows = [...projectDetailRows];

        props.actions.companyAction(tempCompanyName);
        props.actions.companyIdAction(tempCompanyId);
        props.actions.resourceAction(tempResourceName);
        props.actions.fromDateAction(tempFromDate);
        props.actions.toDateAction(tempToDate);
        props.actions.detailsAction(tempProjectRows);
        history.push("/duplicate");
    }

    const sendToApi = () => {
        if (projectName === "" || resoureName === "" || CompanyId === "" || CompanyName === "" || FromDate === "" || ToDate === "" || resoureName == null || FromDate == null || ToDate == null) {
            if (projectName === "") {
                setHelperTextProjectName("Please enter a project name.");
                setErrorProjectName(true);
            }
            else {
                setHelperTextProjectName(null);
                setErrorProjectName(false);
            }
            if (resoureName === "" || resoureName === null || resoureName === undefined) {
                setHelperTextResourceDescription("Please Select or enter a Location.");
                setErrorResourceDescription(true);
            }
            else {
                setHelperTextResourceDescription(null);
                setErrorResourceDescription(false);
            }
            if (CompanyId === "" || CompanyId === null || CompanyName === "" || CompanyName === null || CompanyName === undefined) {
                setHelperTextCompany("Please Select or Create a Company.");
                setErrorCompany(true);
            }
            else {
                setHelperTextCompany(null);
                setErrorCompany(false);
            }
        } else {
            setHelperTextResourceDescription(null);
            setErrorResourceDescription(false);
            setHelperTextCompany(null);
            setErrorCompany(false);
            setHelperTextProjectName(null);
            setErrorProjectName(false);
            setEmptyDetails(false);
            if (projectDetailRows.length === 0 || projectDetailRows.find(x => x.type === null) != undefined) {
                setEmptyDetails(true);
            }
            else {
                setEmptyDetails(false);
                let tempProjDetails = [...projectDetailRows];
                tempProjDetails.sort((a, b) => b.sequence + a.sequence);
                let projectDetails = tempProjDetails.map(x => ({ HasComment: x.comment, color: x.color, description: x.description, typeId: x.type, type: x.type, Id: x.Id, projectId: projectId, sequence: x.sequence, dataListId: x.dataListId, price: (x.price === null ? 0 : x.price), isProfit: (x.isProfit === null ? false : true), costProfit: (x.isProfit !== null ? (x.isProfit ? '+' : '-') : '-'), externalApiId: x.externalApiId, newRow: x.newRow })).sort((a, b) => a.sequence - b.sequence);
                props.actions.detailsAction(projectDetails);
                props.actions.projectIdAction(projectId);
                props.actions.companyAction(CompanyName);
                props.actions.companyIdAction(CompanyId);
                props.actions.resourceAction(resoureName);
                props.actions.fromDateAction(FromDate);
                props.actions.toDateAction(ToDate);

                dataService.updateProjects(GetUserID, projectName, resoureName, OldCompanyId, CompanyId, CompanyName, FromDate, ToDate, projectDetails, projectId, projectQR).then((response) => {
                    if (response.success) {
                        toast.success('Project was saved successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    } else {
                        toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
                    }
                });
                history.push("/modify");
            }
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const handleDeleteProject = () => {
        dataService.deleteProject(projectId, GetUserID).then(response => {
            if (response.success) {
                toast.success('Project was deleted successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            } else {
                toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            }
            history.push("/manage");
        });
    }

    class ComponentToPrint extends React.Component {
        render() {
            return (
                <div className='print-source' style={{ position: 'relative', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <h1 style={{ marginBottom: "20px" }}>{projectName}</h1>
                    <QRCode
                        id="Qr"
                        value={appSettings.TAPLR_BASE_URL + "perfmeasurement?qr=" + projectQR}
                        bgColor={"#ffffff"}
                        renderAs={"svg"}
                        size={400} />
                    <div style={{ position: 'relative', width: '400px' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>Powered by Flagstone</div>
                    </div>
                </div>
            );
        }
    }



    const actionStyle = {
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    }

    const contentStyle = {
        textAlign: "center",
        justifyContent: "center",
        margin: '15px',
    }

    return (
        <div>
            <TitlePage Text="Modify your project" {...props} wipe={true} backbutton={true} />
            <div className={classes.container}>
                <div className={classes.menuOption}>
                    <CustomizedMenus isActive={activeProject} handleDialogOpenDel={handleDialogOpenDel} handleClickOpenQr={handleClickOpenQr} duplicateRedirect={duplicateRedirect} resultsRedirect={resultsRedirect} financialDashboardRedirect={financialDashboardRedirect}></CustomizedMenus>
                </div>
                <div className={classes.optionContainer}>
                    <div className={classes.projectOptions}>
                        <div className={classes.projectOption}>
                            <DescriptionItem Text="Project Name" />
                            <TextfieldInput label={projectName ? " " : "Project"} InputLabelProps={{ shrink: false }} value={projectName} action={setProject} helperText={helperTextProjectName} error={errorProjectName} />
                        </div>
                        <div className={classes.projectOption}>
                            <DescriptionItem Text="Company name" />
                            <DropdownCompany companies={companies} value={CompanyName} action={setCompany} helperText={helperTextCompany} error={errorCompany} />
                        </div>
                        <div className={classes.projectOption}>
                            <DescriptionItem Text="Location" />
                            <DropdownResources resources={resources} value={resoureName} action={setResource} helperText={helperTextResourceDescription} error={errorResourceDescription} />
                        </div>

                    </div>

                </div>

                <Grid item xs={12} style={{ marginBottom: '-1rem' }}>
                    <DescriptionItem Text="Active between:" />
                    <DateSelectionFuture FromDate={FromDate} ToDate={ToDate} setDates={setDates} setTheFromDate={setTheFromDate} setTheToDate={setTheToDate} />
                    <Divider style={{ marginBottom: "2rem", marginTop: "1rem" }}></Divider>
                </Grid>
                {/* <ToggleButtonGroup size="small" exclusive aria-label="costPrice" orientation="horizontal">
                    <ToggleButton value="+" aria-label="Profit">
                        <Tooltip placement="left" title="Profit">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={Counter}></img>
                        </Tooltip>
                    </ToggleButton>

                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={Stopwatch}></img>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={FreeText}></img>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={DataList}></img>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={Scanner}></img>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={Image}></img>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="-" aria-label="Cost">
                        <Tooltip placement="left" title="Cost">
                            <img style={{ margin: "0px", width: "30px", height: "30px" }} src={API}></img>
                        </Tooltip>
                    </ToggleButton>
                    
                </ToggleButtonGroup> */}
                {emptyDetails ?
                    <div>
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableDragResources create={false} {...props} types={types} rows={projectDetailRows} setRows={setProjectDetailRows} makeSave={save} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one item.</p>
                    </div>
                    :
                    <div>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableDragResources create={false} {...props} types={types} rows={projectDetailRows} setRows={setProjectDetailRows} makeSave={save} />
                        </Grid>
                    </div>}
                <div className={classes.buttonDiv} style={{ marginTop: '15px' }}>
                    {/* <Button size='large' className={classes.qrButton} onClick={sendToQr}  disabled variant="contained">Generate QR</Button> */}
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                </div>
            </div>

            <Dialog
                open={openQr}
                onClose={handleCloseQr}
                className={classes.modalQRCode}
                maxWidth={'lg'}
            >
                <DialogContent style={contentStyle}>
                    <ComponentToPrint ref={el => (componentRef.current = el)} />
                    <CopyQrLink QrCode={projectQR} value={appSettings.TAPLR_BASE_URL + "perfmeasurement?qr=" + projectQR} />
                </DialogContent>
                <DialogActions style={actionStyle} className={classes.buttonRow}>
                    <ButtonNormalText content="Close" onClick={handleCloseQr}></ButtonNormalText>
                    <Button size='large' className={classes.printButton} onClick={handlePrint} variant="contained"><PrintIcon className={classes.printIcon}></PrintIcon>Print QR-code</Button>
                    <Link to={"perfmeasurement?qr=" + projectQR} style={{ textDecoration: 'none', marginLeft: '10px' }}>
                        <Button className={classes.printButton} size="large" variant="contained"><ViewComfyIcon className={classes.printIcon}></ViewComfyIcon>{props.content}View</Button>
                    </Link>
                </DialogActions>
            </Dialog>

            <Dialog
                open={delopen}
                onClose={handleDialogCloseDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Project"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you want to delete project " + projectName + " ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonNormalText content="Close" onClick={handleDialogCloseDel}>Close</ButtonNormalText>
                    <ButtonNormalDelete startIcon={<DeleteIcon />} content="Delete" onClick={handleDeleteProject}>Delete</ButtonNormalDelete>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModifyPage;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "90%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    buttonDiv: {
        marginTop: '15px',
    },
    buttonRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        marginBottom: '10px',
    },
    optionContainer: {
        marginTop: "35px",
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap',
            width: '100%'

        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            width: '100%'

        }
    },
    menuOption: {
        zIndex: '100',
        top: '5px',
        position: 'absolute',
        right: '5px',
        display: 'flex',
        justifyContent: 'end',
    },
    projectOption: {
        [theme.breakpoints.up('md')]: {
            width: "100%",
            marginRight: '25px',

        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    menuRight: {
        display: "flex",
        alignItems: "center",
    },
    menuLeft: {
        display: "flex",
        alignItems: "center",

    },
    titleTextStyle: {
        marginLeft: "20px",
    },
    titleSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    smallMenuItem: {
        display: "flex",
        paddingBottom: "1rem",
        justifyContent: "center",
        background: "#FFFFFF",
        alignItems: "center"
    },
    titleStyle: {
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#FFFFFF",
        justifyContent: "space-between"
    },
    activeFor: {
        color: "#696969",
        fontSize: "15px",
    },
    backIcon: {
        padding: "20px",
        cursor: "pointer"
    },
    hstyle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "2.5rem",
        lineHeight: "48px",
        color: "#1D3557",
    },
    printIcon: {
        paddingRight: '5px',
    },
    doneButton: {
        backgroundColor: '#C3996B !important',
    },
    printButton: {
        backgroundColor: '#0B4097 !important',
    }
}));