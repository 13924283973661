import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { dataService } from '../../../../Services/data/data.service';

function DropdownProjectNames(props) {
  const [projectNames, setProjectNames] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    if (props.projectCopy === null) {
      dataService.getProjectNamesByUserId(props.Login.userid, props.fromDate, props.toDate).then(result => {
        if (result.length > 0) {
          setProjectNames(result.map(x => ({ projectName: x.projectName })));
        }
      });
    } else {
      let tempProjects = [];
      tempProjects.push({projectName: props.projectCopy.projectName})
      setProjectNames(tempProjects);
      if (props.projectCopy !== null && props.projectCopy !== undefined && props.projectCopy.projectId !== null) {
        setSelectedProject(props.projectCopy.projectName);
      }
    }

  }, [props.refresh]);
 
  return (
    <div>
    {
      projectNames.length > 0 && selectedProject !== null ?
        <div>
          <Autocomplete
            multiple
            disablePortal
            options={projectNames}
            limitTags={1}
            style={{ minWidth: "200px", maxWidth: "200px" }}
            onChange={(event, newValue) => {
              props.action(newValue)
            }}
            inputValue={selectedProject}

            getOptionLabel={(option) => option.projectName || ""}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        :
        <div>
          <Autocomplete
            multiple
            disablePortal
            options={projectNames}
            limitTags={1}
            style={{ minWidth: "200px", maxWidth: "200px" }}
            onChange={(event, newValue) => {
              props.action(newValue)
            }}

            getOptionLabel={(option) => option.projectName || ""}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
    }</div>

  );
}

export default DropdownProjectNames