import React, { useEffect } from 'react';
import TitlePage from '../../Shared/TitlePage';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";

import 'react-toastify/dist/ReactToastify.css';
function ConfigurationPage(props) {

    const classes = useStyles();
    useEffect(() => {
    }, []);


    return (

        <div>
            <TitlePage Text="Configuration" backbutton={true} {...props} />
            <div className={classes.container}>

                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <NoteAddIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Create a new dropdown.
                        </div>
                        <Link to='/createdropdown' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Create a dropdown</Button>

                        </Link>
                    </div>
                </div>
                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <TuneIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Manage existing dropdowns. (Edit, delete,...)
                        </div>
                        <Link to='/dropdownoverview' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}Manage dropdowns</Button>

                        </Link>
                    </div>
                </div>

                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <TuneIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Create a new API.
                        </div>
                        <Link to='/createapi' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}CREATE API</Button>

                        </Link>
                    </div>
                </div>

                <div>
                    <div className={classes.boxStyle}>
                        <div className={classes.menuItem}>
                            {/*                                     <TuneIcon className={classes.iconStyle} fontSize={"large"}/>
 */}                                    Manage existing APIs. (Edit or delete.)
                        </div>
                        <Link to='/externalapioverview' style={{ textDecoration: 'none' }} className={classes.link}>
                            <Button className={classes.linkButton} variant="contained">{props.content}MANAGE APIS</Button>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigurationPage;
const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: "8px",
        [theme.breakpoints.up('lg')]: {
            maxWidth: "50%",
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: "60%",
        },

        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",

        },
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "25px"
    },


    titleTextStyle: {
        display: "flex",
        marginLeft: "20px",
    },
    boxStyle: {
        display: "flex",
        justifyContent: "space-between",
        background: "#FFFFFF",
        borderRadius: "8px",
        paddingTop: "18px",
        paddingBottom: "18px",
        marginBottom: '25px',
        paddingLeft: '30px',
        paddingRight: '30px',

    },
    menuItem: {
        color: '#1D3557',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '50% !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
            fontSize: '1.2rem',
        },
        display: "flex",
        alignItems: "center",
    },

    iconStyle: {
        verticalAlign: "-8px",
        marginRight: "4px"
    },
    link: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '5%',
            width: '50% !important',
            minWidth: '50% !important',
        },
    },
    linkButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            minWidth: '100% !important',
            minHeight: "46.8px",
            height: "90%",
            padding: "10px",
            fontSize: "1rem",
        },

        [theme.breakpoints.up('lg')]: {
            width: '250px',
            minWidth: "250px",
            height: "40px",
            fontSize: "1.2rem",
        },
        [theme.breakpoints.down('lg')]: {
            width: '200px',
            minWidth: "200px",
            fontSize: "1.2rem",
        },

        background: "#C3996B !important",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hstyle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.5rem",
        },
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "48px",
        color: 'white',
        whiteSpace: 'nowrap'
        // color: "#1D3557",
    },
    titleStyle: {
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#C3996B !important",
        justifyContent: "space-between",
        color: 'white'

    },
    headerLeft: {
        alignItems: 'center',
        display: 'flex',
        width: '33%'
    },
    headerMiddle: {
        width: '33%',
        display: 'flex',
        justifyContent: 'center'

    },
    headerRight: {
        width: '33%',
        display: 'flex',
        justifyContent: 'end',
        color: 'white'
    },
    arrowBack: {
        padding: '15px',
        cursor: 'pointer'
    },

}));