import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, TableContainer } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FilterResultsRow from '../../../Shared/Tables/FilterResultsRow';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonNormalText from '../../../Shared/Buttons/ButtonNormalText';
import { dataService } from '../../../../Services/data/data.service';
import { useHistory } from 'react-router-dom';
import ProjectButton from './ProjectButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import NoResults from '../../../../../src/Images/NoResults.png'



function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.footer}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function TableResults(props) {
    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("");
    const [selectAll, setSelectAll] = useState(false)
    const [imageLink, setImageLink] = useState("");

    useEffect(() => {
        if (props.selectedProject === true) {
            setSelectAll(true);
        }
        dataService.getSystemParameters().then(results => {
            setImageLink(results.find(x => x.name === "ImageLink").value)
        });
    }, []);
    // Avoid a layout jump when reaching the last page with empty rows.

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDialogOpen = (ImageId) => {
        dataService.GetImageById(ImageId).then(response => {
            setImage(response.image)
        })
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const formatDate = (date) => {
        if (date) {
            let d = new Date(date);
            let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
            let minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
            let seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
            let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
            let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
            return day + "/" + (month) + "/" + d.getFullYear() + " " + hours + ":" + minutes + ":" + seconds;
        }
        return "";
    }

    const HeadStyle = {
        backgroundColor: "#e0dce4"
    }

    const centerStyle = {
        textAlign: "center",
    }

    const selectAllCheckBoxes = () => {
        var isSelected = selectAll
        setSelectAll(!selectAll)
        props.selectAll(isSelected)
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
            <Table size="sm">
                <thead style={HeadStyle}>
                    <tr style={centerStyle}>
                        <th className={classes.tableHead} style={{ width: '4%' }} ><Checkbox
                            checked={selectAll}
                            onChange={() => selectAllCheckBoxes()} /></th>
                        <th className={classes.tableHead} style={{ width: '12.5%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('companyName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Customer
                                    <Tooltip title="Search customer" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '12.5%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('projectName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Project name
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '15%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('resourceName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Location
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '12.5%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('description')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Description
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '12.5%' }}>Value</th>
                        <th className={classes.tableHead} style={{ width: '13%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons} style={{ width: '40px' }}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('startTime')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                Start Time
                                <div style={{ width: '40px' }}></div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '10%' }}>
                            <span className={classes.titleSearch}>
                                Finished
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '10%' }}>
                            <span className={classes.titleSearch}>
                                Project
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.filterOn && <FilterResultsRow rows={props.rows} filters={props.filters} filterProjects={props.filterProjects} updateFilters={props.updateFilters}></FilterResultsRow>}
                    {props.rows.length === 0 || props.rows === {} ?
                        <tr style={centerStyle}>
                            <td colspan="9" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: '500', color: '#1D3557' }}>No results available for <br/>these filter criteria</span>

                                    <img className={classes.imageStyle} src={NoResults} alt="" />
                                </div>

                            </td>

                        </tr> : <React.Fragment></React.Fragment>}
                    {(rowsPerPage > 0 && props.rows.length === 0 || props.rows.length
                        ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : props.rows
                    ).map((row, index) => (
                        <tr key={row.name + '_' + index} style={centerStyle}>
                            <td style={{ width: '4%' }}>
                                <Checkbox
                                    checked={row.checked}
                                    onChange={() => props.onCheckChanged(row.id)}
                                />
                            </td>
                            <td style={{ width: '18%' }}>{row.companyName}</td>
                            <td style={{ width: '18%' }}>{row.projectName}</td>
                            <td style={{ width: '18%' }}>{row.resourceName}</td>
                            <td style={{ width: '18%' }}>{row.description}</td>
                            {row.commentId !== null && row.commentId !== 0 ?
                                <td style={{ width: '12%' }}>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                {row.commentText === "" && row.commentImage === "" ?
                                                    <Typography style={{ color: "red" }}>No comment recorded.</Typography>
                                                    :
                                                    <div>
                                                        {row.commentText !== "" ?
                                                            <div>
                                                                <Typography color="inherit">{row.commentText}</Typography>
                                                                {row.commentImage !== "" && row.commentImage !== null ?
                                                                    <div style={{ marginBottom: "10px" }}>
                                                                        <Divider></Divider>
                                                                    </div>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                                }
                                                            </div>
                                                            :
                                                            <React.Fragment></React.Fragment>
                                                        }
                                                        {row.commentImage !== "" && row.commentImage !== null ?
                                                            <img
                                                                style={{ borderRadius: "5px", maxWidth: "100%", maxHeight: "calc(100vh - 16px)" }}
                                                                src={"data:image/png;base64," + row.commentImage} />
                                                            :
                                                            <React.Fragment></React.Fragment>
                                                        }</div>
                                                }
                                            </React.Fragment>
                                        }
                                    >
                                        <span style={{ fontWeight: "bold", textDecoration: "underline" }} className={classes.valueStyle}>{
                                            (row.thumbnail === null) ?
                                                (row.measurementValue === "" || row.measurementValue === null ? '/' : row.measurementValue) :
                                                <div>
                                                    <IconButton style={{ borderRadius: "25px" }} onClick={() => handleDialogOpen(row.imageId)}>
                                                        <img
                                                            style={{ borderRadius: "25px", maxWidth: "100%", maxHeight: "calc(10vh - 16px)" }}
                                                            src={"data:image/png;base64," + row.thumbnail} />
                                                    </IconButton>
                                                    <Dialog
                                                        open={open}
                                                        onClose={handleDialogClose}
                                                    >
                                                        <DialogTitle>
                                                            {row.description}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                <img
                                                                    style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                                                                    src={"data:image/png;base64," + image}></img>
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <ButtonNormalText autoFocus content="Close" onClick={handleDialogClose}>Close</ButtonNormalText>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                        }</span>
                                    </HtmlTooltip>
                                </td>
                                :
                                <td style={{ width: '12%' }}>
                                    <span className={classes.valueStyle}>{
                                        (row.thumbnail === null) ?
                                            (row.measurementValue !== "" ? row.measurementValue : "/") :
                                            <div>
                                                <IconButton style={{ borderRadius: "25px" }} onClick={() => handleDialogOpen(row.imageId)}>
                                                    <img
                                                        style={{ borderRadius: "25px", maxWidth: "100%", maxHeight: "calc(10vh - 16px)" }}
                                                        src={"data:image/png;base64," + row.thumbnail} />
                                                </IconButton>
                                                <Dialog open={open} onClose={handleDialogClose}>
                                                    <DialogTitle>
                                                        {row.description}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            <img
                                                                style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                                                                src={"data:image/png;base64," + image}></img>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <ButtonNormalText autoFocus content="Close" onClick={handleDialogClose}>Close</ButtonNormalText>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                    }</span>
                                </td>
                            }
                            <td style={{ width: '12%' }}>{formatDate(row.startTime)}</td>
                            <td >{row.running === 'True' ?
                                <RemoveCircleOutlineIcon color={'error'}></RemoveCircleOutlineIcon>
                                :
                                <CheckCircleOutlineIcon color={'success'}></CheckCircleOutlineIcon>
                            }</td>
                            <td>
                                <ProjectButton data={row} details={props.rows.filter(x => x.projectId === row.projectId)}> </ProjectButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
                {props.rows.length === 0 || props.rows === {} ?
                <React.Fragment></React.Fragment>
                
                : <TableFooter className={classes.tableFooter}>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[100, 200, 500, { label: 'All', value: -1 }]}
                        count={props.rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>}
            </Table>
        </TableContainer>
    )
}
const useStyles = makeStyles((theme) => ({
    valueStyle: {
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        maxWidth: '250px'
    },
    tableFooter: {
        width: '100%'
    },
    footer: {
        display: 'flex',
    },
    tableHeadRow: {

    },
    tableHead: {
        height: '42px',
        borderRight: '2px solid #e6ecf0',
    },
    titleSearch: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icons: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageStyle:{
        marginTop: '15px',
        height: '100px',
        width: '100px'
    }
}));