import React, { useState, useEffect } from "react";
import TitlePage from "../../Shared/TitlePage";
import { dataService } from "../../../Services/data/data.service";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";
import { ExternalApiTypes } from '../../../Config/constants';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TableExternalApi from "./Components/TableExternalApi";

function ExternalApiOverviewPage(props) {
    const history = useHistory();
    const [firstLoad, setFirstLoad] = useState(0);
    const GetUserID = props.Login.userid;
    const classes = useStyles();
    const [filters, setFilters] = useState([]);
    const [filterOn, setFilterOn] = useState(false);
    const [externalApis, setExternalApis] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(1);
    const theme = createTheme({
        palette: {
            neutral: {
                main: '#C3996B',
                contrastText: '#fff',
            },
        },
    });

    useEffect(() => {
        dataService.getApis(GetUserID).then(result => {
            setExternalApis(result)
        });
    }, [])
    
    if (firstLoad === 0) {
        setFirstLoad(1);
    }

    const filter = [
        { number: 1, column: "name", sortOrder: "ASC" },
        { number: 2, column: "name", sortOrder: "DESC" },
  
    ]
    
    const dynamicSorting = (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        let asc = false;
        let desc = false;
        /* SC: Filter now works both ways (ASC, DESC) */
        if (filter.find(x => x.number === selectedFilter).column === property) {
            if (filter.find(x => x.number === selectedFilter).sortOrder === 'ASC') {
                desc = true;
                asc = false;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'DESC').number);
            } else {
                desc = false;
                asc = true;
                setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'ASC').number);
            }
        } else {
            desc = false;
            asc = true;
            setSelectedFilter(filter.find(x => x.column === property && x.sortOrder === 'DESC').number);
        }
        if (asc === true) {
            return function (a, b) {
                //ASC A-Z
                var result = (a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
                return result * sortOrder;
            }
        } else {
            return function (a, b) {
                //DESC Z-A
                var result = (a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1);
                return result * sortOrder;
            }
        }
    }

    const handleSorting = (sortType) => {
        let tempMeasurementRows = [...externalApis]
        setExternalApis(tempMeasurementRows.sort(dynamicSorting(sortType)));
    }

    const toggleFilter = () => {
        if (filterOn) {
            setFilters([]);
        }
        setFilterOn(!filterOn);
    }

    const updateFilters = (type, event) => {
        let tempFilters = [...filters];
        if (tempFilters.some(x => x.type === type)) {
            let index = tempFilters.indexOf(tempFilters.find(x => x.type === type));
            tempFilters.splice(index, 1);
            tempFilters.unshift({ type: type, filterValue: event.target.value });
        }
        else {
            tempFilters.unshift({ type: type, filterValue: event.target.value });
        }
        setFilters(tempFilters);
        filterApi(tempFilters);
    }

    const filterApi = (thisFilters) => {
        let varFilteredApi = [...externalApis]
        var i;
        for (i = 0; i < thisFilters.length; i++) {
            let filter = thisFilters[i]
            switch (filter.type) {
                case ExternalApiTypes.NAME:
                    varFilteredApi = varFilteredApi.filter(x => x.name.toLowerCase().includes(filter.filterValue.toLowerCase()));
                    break;

            }
        }
        setExternalApis(varFilteredApi);
    }

    const navigateTo = (destination) => {
        history.push(destination);
    }

    return (
        <div>
            <TitlePage Text="Manage your APIs" backbutton={true} {...props} />
            <div className={classes.container}>
                <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={1}
                    alignItems="center">
                    <Grid item xs={12} >
                        <div className={classes.dateAndCreateDiv}>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Button size='large' className={classes.linkButton} color='neutral' variant="contained" onClick={() => navigateTo('/createapi')}>
                                        <span className={classes.iconItem}>
                                            <AddIcon></AddIcon>
                                        </span>Create a API
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
                    </Grid>
                    <Grid item xs={12}>
                    <TableExternalApi {...props} toggleFilter={toggleFilter} filters={filters} handleSorting={handleSorting} filterOn={filterOn} filterApi={filterApi} updateFilters={updateFilters} rows={externalApis} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default ExternalApiOverviewPage;

const useStyles = makeStyles({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    titleTextStyle: {
        marginLeft: "20px",
    },
    titleSection: {
        width: "100%",
    },
    titleStyle: {
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        alignContent: "space-around",
        alignItems: "center",
        background: "#FFFFFF",
        justifyContent: "space-between"
    },
    dateAndCreateDiv: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
        flexWrap: 'wrap',
    },
    activeFor: {
        color: "#696969",
        fontSize: "15px",
    },
    backIcon: {
        padding: "20px",
        cursor: "pointer"
    },
    iconItem: {
        display: 'flex !important',
        alignItems: 'center !important'
    }

});