export default class ApiHelper {
    static appSettings;  
}

export const LOGIN_URL = "user/login";
export const GETCOMPANIES_URL = "Company/GetByUserId";
export const GETRESOURCES_URL = "Project/GetResource";
export const GETTYPES_URL = "ControlType";
export const CREATENEWPROJECT = "ProjectLogic/CreateNewProject";
export const CREATENEWDATALIST = "DataListLogic/NewDataList";
export const MODIFYDATALIST = "DataListLogic/ModifyDataList";
export const DELETEDATALIST = "DataListLogic/DeleteDataList";
export const SAVECOMPANY = "CompanyLogic/NewCompany";
export const SAVETEXT = "ProjectDetailMeasurement/SaveText";
export const SAVETIME = "ProjectDetailMeasurement/SaveTime";
export const SAVECOUNT = "ProjectDetailMeasurement/SaveCount";
export const GETPROJECTS_URL = "Project/GetProjects";
export const UPDATEPROJECTS_URL = "ProjectLogic/ModifyProject";
export const GETMEASUREMENT_URL = "ProjectLogic/GetMeasurement";
export const GETNAME_URL = "Project/GetName";
export const GETPROJECTDETAILS_URL = "ProjectDetail/MeasurementScan";
export const GETPROJECTDETAILSBYQR_URL = "ProjectDetail/GetDetails";
export const SAVEMEASUREMENT = "ProjectDetailMeasurementLogic/SaveMeasurement";
export const SAVEMEASUREMENTWITHCOMMENT = "ProjectDetailMeasurementCommentLogic/SaveMeasurementWithComment";
export const SAVEANDRESETMEASUREMENTS = "ProjectDetailMeasurementLogic/SaveAndResetMeasurements";
export const GETMEASUREMENTBYID_URL = "ProjectLogic/GetMeasurementById";
export const GETMEASUREMENTBYIDANDDATE_URL = "ProjectLogic/GetMeasurementByIdAndDate";
export const GETMEASUREMENTSBYPROJECTID_URL = "Project/GetByProjectID";
export const GETALLPROJECTINFOBYPROJECTID = "Project/GetAllProjectInfoByProjectID";
export const GETMEASUREMENTFORMODIFY_URL = "Project/GetMeasurementForModify";
export const DELETEPROJECT = "ProjectLogic/DeleteProject";
export const GETLISTS = "DataList/GetLists";
export const GETLISTITEMS = "DataList/GetListItems";
export const NEWMEDIAIMAGE = "ProjectDetailMeasurementMediaLogic/SaveImage";
export const NEWMEDIAIMAGEWITHCOMMENT = "ProjectDetailMeasurementMediaLogic/SaveImageWithComment";
export const GETIMAGEBYID = "ProjectDetailMeasurementMedia/GetImageById";
export const GETSYSTEMPARAMETERS = "SystemParameter";
export const GETUSERBYEMAIL_URL = "user/GetAllByEmail";
export const UPDATEUSERDATA_URL = "user/UpdateUserData";
export const CREATEUSER_URL = "UserLogic/CreateUser";
export const EXTERNALAPI = "ExternalApiMeasurementLogic/NewExternalApiMeasurement?ApiKey=";
export const CREATENEWAPI = "ExternalApiLogic/NewExternalApi";
export const MODIFYAPI = "ExternalApiLogic/ModifyExternalApi";
export const DELETEAPI = "ExternalApiLogic/DeleteExternalApi";
export const GETAPIS = "ExternalApi/GetApis";
export const GETLOCATION_URL = "Project/GetAllLocation";
export const GETPROJECTNAMES_URL = "Project/GetAllProjectNames";
export const GETMEASUREMENTBYDATELOCATIONNAME = "ProjectLogic/GetMeasurementByDateLocationName";
export const GETDETAILMEASUREMENTSANDCOMMENTS = "ProjectLogic/GetDetailsByQr";
export const GETPROJECTSANDDETAILS = "ProjectLogic/GetAllProjects";
export const GETALLUSERCOMPANYPROJECTS = "ProjectLogic/GetAllUserCompanyProjects";
export const GETPROJECTBYPROJECTIDUSERID = "ProjectLogic/GetProject";
export const REQUESTLOGIN_URL = "request/RequestLogin";

export function ComposeUri(action){
    if(ApiHelper.appSettings){
        return `${ApiHelper.appSettings.TAPLR_API_BASE_URL}/${action}`;
    }
}