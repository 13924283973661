import * as types from './ListCopyActionTypes.js';

export const listNameAction = (listName) => {
    return {
        type: types.SET_LISTNAME,
        payload: listName
    };
};

export const listDescriptionAction = (listDescription) => {
    return {
        type: types.SET_LISTDESCRIPTION,
        payload: listDescription
    };
};

export const listItemsAction = (listItems) => {
    return {
        type: types.SET_LISTITEMS,
        payload: listItems
    };
};

export const listIsActiveAction = (listIsActive) => {
    return {
        type: types.SET_LISTISACTIVE,
        payload: listIsActive
    };
};

export const listIdAction = (listId) => {
    return {
        type: types.SET_LISTID,
        payload: listId
    };
};