import * as types from './ListCopyActionTypes';

const initialState = {
    listName: "",
    listDescription: "",
    listItems: [],
    listIsAvtive: null,
    listId: 0
};

export default function listCopy(state = initialState, action) {
    switch (action.type) {
        case types.SET_LISTNAME:
            if (action.payload !== state.listName) {
                return Object.assign({}, state, { listName: action.payload });
            }
            return state;
        case types.SET_LISTDESCRIPTION:
            if (action.payload !== state.listDescription) {
                return Object.assign({}, state, { listDescription: action.payload });
            }
            return state;
        case types.SET_LISTITEMS:
            if (action.payload !== state.listItems) {
                return Object.assign({}, state, { listItems: action.payload });
            }
            return state;
        case types.SET_LISTISACTIVE:
            if (action.payload !== state.listIsAvtive) {
                return Object.assign({}, state, { listIsAvtive: action.payload });
            }
            return state;
        case types.SET_LISTID:
            if (action.payload !== state.listId) {
                return Object.assign({}, state, { listId: action.payload });
            }
            return state;
        default: {
            return state;
        }
    }
}