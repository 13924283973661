import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ButtonNormalText from '../../Shared/Buttons/ButtonNormalText';
import Counter from './Counters';
import Stopwatch from './Stopwatch';
import FreeText from './FreeText';
import DataList from './DataList';
import Camera from './Camera';
import { controlTypes } from '../../../Config/constants';
import { dataService } from '../../../Services/data/data.service';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MessageDialog from '../../Shared/Dialogs/MessageDialog';
import { parseNewDateToDDMMYYY, isDateObject } from '../../../Utils/dateHelper';
import Scanner from './Scanner';

function MeasurementBlocks(props) {
    const [valuesArray, setValuesArray] = useState([]);
    const [confirmDialogOpen, setComfirmDialogOpen] = useState(false)
    const [watchStillRunningDialogOpen, setWatchStillRunningDialogOpen] = useState(false)
    const [stopWatchesRunning, setStopWatchesRunning] = useState([])
    const [refreshStopWatches, setRefreshStopWatches] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const current = new Date();
    const date = parseNewDateToDDMMYYY(current);

    useEffect(() => {
        let elementsArray = []
        var i = 0;
        for (i = 0; i < props.data.length; i++) {
            elementsArray.push({
                id: props.data[i].id,
                value: props.data[i].value? props.data[i].value : '',
                startTime: date,
                running: props.data[i].running,
                controlTypeId: props.data[i].controlTypeID,
                startValue: props.data[i].startValue !== null ? props.data[i].startValue : null,
                stopValue: props.data[i].stopValue !== null ? props.data[i].stopValue : null
            })
        }
        setValuesArray(elementsArray);
        setRefreshStopWatches(!refreshStopWatches)
        // eslint-disable-next-line 
    }, [props.data]);


    const setCounter = (value, detailId) => {
        let tempValues = [...valuesArray]
        tempValues.find(x => x.id === detailId).controlTypeID = 1
        tempValues.find(x => x.id === detailId).value = String(value);
        tempValues.find(x => x.id === detailId).running = true;

        setValuesArray(tempValues)
        dataService.saveMeasurement(tempValues.find(x => x.id === detailId)).then(result => {
            if (result.success === true) {
                //toast.success('Measurements were saved successfully.', { position: toast.POSITION.TOP_CENTER });
            } else {
                toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            }
        });
    }

    const setCounterWithComment = (value, detailId, image, text) => {
        let tempValues = [...valuesArray]
        tempValues.find(x => x.id === detailId).controlTypeID = 1
        tempValues.find(x => x.id === detailId).value = String(value);
        tempValues.find(x => x.id === detailId).running = true;
        tempValues.find(x => x.id === detailId).commentImage = image;
        tempValues.find(x => x.id === detailId).commentText = text;

        setValuesArray(tempValues)
        dataService.saveMeasurementWithComment(tempValues.find(x => x.id === detailId));
    }

    const setStopWatch = (value, detailId, startTime, endTime, reset) => {
        let tempValues = [...valuesArray]
        tempValues.find(x => x.id === detailId).controlTypeID = 2
        tempValues.find(x => x.id === detailId).value = String(value);
        if (startTime) {
            tempValues.find(x => x.id === detailId).stopValue = null;
            tempValues.find(x => x.id === detailId).running = true
            setValuesArray(tempValues)

            let startDateString = parseNewDateToDDMMYYY(startTime);
            tempValues.find(x => x.id === detailId).startValue = startDateString;

        }
        else {
            if (reset) {
                tempValues.find(x => x.id === detailId).running = false
                tempValues.find(x => x.id === detailId).value = 0
            }
            else {
                tempValues.find(x => x.id === detailId).running = true
            }

            setValuesArray(tempValues)
            let endDateString = parseNewDateToDDMMYYY(endTime);
            tempValues.find(x => x.id === detailId).stopValue = endDateString;
        }

        if (reset) {
            if (isDateObject(tempValues.find(x => x.id === detailId).startValue)) {
                tempValues.find(x => x.id === detailId).startValue = parseNewDateToDDMMYYY(tempValues.find(x => x.id === detailId).startValue)
            }
            tempValues.find(x => x.id === detailId).value = '0';
        }

        dataService.saveMeasurement(tempValues.find(x => x.id === detailId)).then(result => {
            if (result.success === true) {
                //toast.success('Measurements were saved successfully.', { position: toast.POSITION.TOP_CENTER });
            } else {
                toast.error('Something went wrong.', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
            }
        });
    }

    const setStartValue = (value, detailId, startTime) => {
        let tempStopWatchesRunning = [...stopWatchesRunning]
        tempStopWatchesRunning.push(detailId)
        setStopWatchesRunning(tempStopWatchesRunning)
        setStopWatch(value, detailId, startTime, null, false)
    }

    const setStopValue = (value, detailId, endTime, reset) => {
        let tempStopWatchesRunning = [...stopWatchesRunning]
        if (tempStopWatchesRunning.some(x => x === detailId)) {
            let index = tempStopWatchesRunning.indexOf(tempStopWatchesRunning.find(x => x === detailId))
            tempStopWatchesRunning.splice(index, 1)
        }
        setStopWatchesRunning(tempStopWatchesRunning)
        setStopWatch(value, detailId, null, endTime, reset)
    }

    const setText = (value, detailId) => {
        let tempValues = [...valuesArray];
        tempValues.find(x => x.id === detailId).controlTypeID = 3;
        tempValues.find(x => x.id === detailId).value = value;
        tempValues.find(x => x.id === detailId).running = false;
        setValuesArray(tempValues);
    }
    
    const setTextWithComment = (value, detailId, image, text) => {
        let tempValues = [...valuesArray]
        tempValues.find(x => x.id === detailId).controlTypeID = 3;
        tempValues.find(x => x.id === detailId).value = String(value);
        tempValues.find(x => x.id === detailId).running = true;
        tempValues.find(x => x.id === detailId).commentImage = image;
        tempValues.find(x => x.id === detailId).commentText = text;

        setValuesArray(tempValues)
        dataService.saveMeasurementWithComment(tempValues.find(x => x.id === detailId));
    }

    const setDataListValue = (DataListValue, detailId) => {
        let tempValues = [...valuesArray];
        tempValues.find(x => x.id === detailId).controlTypeID = 4;
        tempValues.find(x => x.id === detailId).DataListValue = DataListValue;
        tempValues.find(x => x.id === detailId).running = false;
        setValuesArray(tempValues);
    }

    function elementSwitch(controlTypeId, description, id, color, dataListId, comment, comments) {
        switch (controlTypeId) {
            case controlTypes.COUNTER: //1
                return (
                    <div>
                        {valuesArray.length >= 1 && <Counter refresh={refresh} comments={comments} comment={comment} color={color !== null ? color : '#e6ccb1'} textColor={getTextColor(color !== null ? color : '#e6ccb1')} description={description} counter={valuesArray.find(x => x.id === id).value} id={id} action={setCounter} actionWithComment={setCounterWithComment}></Counter>}
                    </div>
                );
            case controlTypes.STOPWATCH: //2
                return (
                    <div>
                        {valuesArray.length >= 1 && <Stopwatch color={color !== null ? color : '#a5b6d4'} textColor={getTextColor(color !== null ? color : '#a5b6d4')} description={description} refresh={refreshStopWatches} stopwatch={valuesArray.find(x => x.id === id)} startAction={setStartValue} stopAction={setStopValue} id={id}></Stopwatch>}
                    </div>
                );
            case controlTypes.FREETEXT: //3
                return (
                    <div>
                        {valuesArray.length >= 1 && <FreeText color={color !== null ? color : '#cae6cb'} textColor={getTextColor(color !== null ? color : '#cae6cb')} description={description} text={valuesArray.find(x => x.id === id).value} id={id} setText={setText}></FreeText>}
                    </div>
                );
            case controlTypes.DATALIST: //4
                return (
                    <div>
                        {valuesArray.length >= 1 && <DataList refresh={refresh} color={color !== null ? color : '#de576d'} textColor={getTextColor(color !== null ? color : '#de576d')} dataListId={dataListId} description={description} id={id} setDataValue={setDataListValue} ></DataList>}
                    </div>
                );
            case controlTypes.SCANNER: //5
                return (
                    <div>
                        {valuesArray.length >= 1 && <Scanner comments={comments} comment={comment} refresh={refresh} color={color !== null ? color : '#cae6cb'} textColor={getTextColor(color !== null ? color : '#cae6cb')} description={description} id={id} actionWithComment={setTextWithComment} ></Scanner>}
                    </div>
                );
            case controlTypes.CAMERA: //6
                return (
                    <div>
                        {valuesArray.length >= 1 && <Camera refresh={refresh} comments={comments} comment={comment} color={color !== null ? color : '#84ff69'} textColor={getTextColor(color !== null ? color : '#84ff69')} description={description} id={id} ></Camera>}
                    </div>
                );
                case controlTypes.EXTERNALAPI: //7
                return (
                    <React.Fragment></React.Fragment>
                );
            default:
                return (<React.Fragment></React.Fragment>);
        }
    }
    
    const resetAndSave = () => {
        setRefresh(!refresh)
        var measurements = []
        valuesArray.forEach(x =>
            measurements.push(
                {
                    ProjectDetailId: x.id,
                    StartTime: x.startTime,
                    Value: String(x.value),
                    StartValue: x.startValue ? parseNewDateToDDMMYYY(new Date(x.startValue)) : null,
                    StopValue: x.stopValue ? parseNewDateToDDMMYYY(new Date(x.stopValue)) : null,
                    DataListValue: String(x.DataListValue),
                    Running: x.running,
                    ControlTypeId: x.controlTypeID,
                    Description: ""
                })
        )
        dataService.saveAndResetMeasurements(measurements).then((result) => {
            closeConfirmDialog()
            props.refreshMeasurements()
        })
    }

    const openConfirmDialog = () => {

        if (stopWatchesRunning.length > 0) {
            openWatchStillRunningDialog();
        }
        else {
            setComfirmDialogOpen(true)
        }
    }

    const closeConfirmDialog = () => {
        setComfirmDialogOpen(false)
    }

    const closeWatchStillRunningDialog = () => {
        setWatchStillRunningDialogOpen(false)
    }

    const openWatchStillRunningDialog = () => {
        setWatchStillRunningDialogOpen(true)
    }

    const measurementGrid = {
        marginTop: '25px',
    }

    function getRGB(c) {
        return parseInt(c, 16) || c
    }

    function getsRGB(c) {
        return getRGB(c) / 255 <= 0.03928
            ? getRGB(c) / 255 / 12.92
            : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
    }

    function getLuminance(hexColor) {
        if (hexColor.includes('#')) {
            return (
                0.2126 * getsRGB(hexColor.substr(1, 2)) +
                0.7152 * getsRGB(hexColor.substr(3, 2)) +
                0.0722 * getsRGB(hexColor.substr(-2))
            )
        } else {
            return (
                0.2126 * hexColor +
                0.7152 * hexColor +
                0.0722 * hexColor
            )
        }

    }

    function getContrast(f, b) {
        const L1 = getLuminance(f)
        const L2 = getLuminance(b)
        return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
    }

    function getTextColor(bgColor) {
        if (bgColor === null) {
            return '#ffffff';
        } else {
            const whiteContrast = getContrast(bgColor, '#ffffff')
            const blackContrast = getContrast(bgColor, '#000000')
            return whiteContrast > blackContrast ? '#ffffff' : '#000000';
        }
    }
    return (
        <div>
            <Grid container
                direction="row"
                justifyContent="center"
                spacing={3}
                alignItems="center" style={measurementGrid}>
                {
                    props.data.map(x => 
                        <Grid item xs={props.view}>
                            {elementSwitch(x.controlTypeID, x.description, x.id, x.color, x.dataListId, x.hasComment, x.comments? x.comments: "")}
                        </Grid>
                    )
                }
                <Divider style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}></Divider>

                <Grid item xs={12}>
                    <ButtonNormalText onClick={() => openConfirmDialog()} startIcon={<RestartAltIcon />} content="New registration" />
                </Grid>
                <MessageDialog
                    dialogOpen={confirmDialogOpen}
                    closeDialog={closeConfirmDialog}
                    messageTitle={"New registration?"}
                    action={resetAndSave}
                    messageContent={"Running measurements will be stopped and you'll be able to start new measurements."}
                    buttonText={"OK"}
                    button2Text={"Cancel"}
                ></MessageDialog>
                <MessageDialog
                    dialogOpen={watchStillRunningDialogOpen}
                    closeDialog={closeWatchStillRunningDialog}
                    action={closeWatchStillRunningDialog}
                    messageTitle={"Attention!"}
                    buttonCount={1}
                    messageContent={"There are still stopwatches running... Please pause/reset the watches first before initiating new measurements."}
                    buttonText={"OK"}
                ></MessageDialog>
            </Grid>

        </div>

    )
}

export default MeasurementBlocks;
