import * as types from './LoginActionTypes.js';

const initialState = {
    loginState: false,
    userid: null,
    userName: '',
    email: '',
    lastName: '',
    auth: {}
};

export default function Login(state = initialState, action) {
    switch (action.type) {
        case types.SET_LOGIN:
            if (action.payload !== state.loginState) {
                return Object.assign({}, state, { loginState: action.payload });
            }
            return state;
        case types.SET_USERID:
            if (action.payload !== state.userid) {
                return Object.assign({}, state, { userid: action.payload });
            }
            return state;
        case types.SET_USERNAME:
            if (action.payload !== state.userName) {
                return Object.assign({}, state, { userName: action.payload });
            }
            return state;
        case types.SET_EMAIL:
            if (action.payload !== state.email) {
                return Object.assign({}, state, { email: action.payload });
            }
            return state;
        case types.SET_LASTNAME:
            if (action.payload !== state.lastName) {
                return Object.assign({}, state, { lastName: action.payload });
            }
            return state;

        default: {
            return state;
        }
    }
}