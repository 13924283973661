import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { dataListTypes } from '../../../Config/constants';


export default function FilterListRow(props) {
    const classes = useStyles();
    const getValue = (type) => {

        let filter = props.filters.find(x => x.type === type)

        if (filter) {
            return filter.filterValue
        }
        else {
            return ""
        }
    }

    return (
        <React.Fragment>
            <tr key={"filter"} className={classes.filterRow}>
                <th></th>
                {/*CompanyName */}
                <th className={classes.midCell}>
                            <TextField 
                            autoFocus={props.filters.length > 0 ? props.filters[0].type === dataListTypes.NAME : false} 
                            id="outlined-search" 
                            label="" 
                            type="search" 
                            variant="outlined" 
                            value={getValue(dataListTypes.NAME)} 
                            className={classes.textField} 
                            size="small" 
                            onChange={(e) => props.updateFilters(dataListTypes.NAME, e)}/>
            
                </th>
{/*                 {props.groupType !== salesorderGroupingTypes.SLICE_TYPE && <th className={classes.midCell}>
 */}{/*                     <TextField autoFocus={props.filters.length > 0 ? props.filters[0].type === customerProductFilterTypes.SLICE_TYPE : false} id="outlined-search" label="" type="search" variant="outlined" value={getValue(customerProductFilterTypes.SLICE_TYPE)} className={classes.textField} size="small" onChange={(e) => props.updateFilters(customerProductFilterTypes.SLICE_TYPE, e)} />
              </th>} */}  
                <th className={classes.midCell}>
{/*                     <TextField autoFocus={props.filters.length > 0 ? props.filters[0].type === customerProductFilterTypes.PALLET_TYPE : false} id="outlined-search" label="" type="search" variant="outlined" value={getValue(customerProductFilterTypes.PALLET_TYPE)} className={classes.textField} size="small" onChange={(e) => props.updateFilters(customerProductFilterTypes.PALLET_TYPE, e)} />
 */}                </th>
                <th className={classes.midCell}>
                </th>
            </tr>
        </React.Fragment>
    )
}

const useStyles = makeStyles(() => ({
    filterRow: {
        marginBottom: '10px',
        width: '100%',
    },
    leftCell: {
        fontSize: "14px",
        color: "#020100",
        padding: "10px",
        fontWeight: "normal",
        paddingLeft: "20px",
    },
    midCell: {
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: "14px",
        fontWeight: "normal",
        color: "#020100",
        height: "40px",
    },
    rightCell: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#020100",
        paddingLeft: "10px",
    },
    textField: {
        backgroundColor: "white",
        width: "90%",
    },
    checkBox: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        float: "left"
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: "black",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: "black",
        },
    },
}));
