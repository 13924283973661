import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import * as Actions from '../../../../Reducers/actions'
import { useHistory } from 'react-router-dom';

function EditListsButton(props) {
    const history = useHistory();
    const SaveAndRedirect = () => {      
        var tempListName = props.data.listName;
        var tempListDescription = props.data.listDescription;
        var tempListActive = props.data.listActive;
        var tempListId = props.data.listItems[0].dataListId;
        var tempListItems = props.data.listItems.map(x => ({ Id: x.id, itemName: x.name, itemDescription: x.description, InsertedOn: x.insertedOn, InsertedByUserId: x.insertedByUserId }))

        props.actions.listNameAction(tempListName)
        props.actions.listDescriptionAction(tempListDescription)
        props.actions.listItemsAction(tempListItems)
        props.actions.listIsActiveAction(tempListActive)
        props.actions.listIdAction(tempListId)
        history.push("/modifydropdown");
    }

    return (
        <div>
            <IconButton onClick={SaveAndRedirect} size='large' style={{ borderRadius: 0 }}>
                <EditIcon fontSize="inherit" />
            </IconButton>
        </div>
    )
}

const mapStateToProps = state => ({
    lists: state.lists,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
))(EditListsButton);