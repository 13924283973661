import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import Table from '@mui/material/Table';
import QrButton from '../../../Shared/QrButton';
import FilterProjectRow from '../../../Shared/Tables/FilterProjectRow';
import EditButton from './EditButton';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { TableContainer } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Tooltip from '@mui/material/Tooltip';

function TablePaginationActions(props) {
    const theme = useTheme();
    const classes = useStyles();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.footer}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};



export default function TableManage(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const currentDate = new Date();
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const classes = useStyles();
    var measurementTypes = props.types;
    function reformatDate(dateStr) {
        let dArr = dateStr.split("-");
        return dArr[2] + "/" + dArr[1] + "/" + dArr[0];
    }
    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();


    function dateRange(a_start, a_end, b_start, b_end) {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        // if (b_start <  a_start && a_end   >  b_end) return true; // a overlaps b
        //console.log(b_start, a_start)

        if (datesAreOnSameDay(a_start, b_start)) return true;
        if (datesAreOnSameDay(b_end, a_end)) return true;
        return false;
    }

    return (
        <TableContainer style={{ borderRadius: "15px" }} component={Paper}>
            <Table size="sm" key={"table"}>
                <thead className={classes.headStyle} key={"thead"}>
                    <tr className={classes.tableHeadRow} key={"trow"}>
                        <th className={classes.tableHead} style={{ width: '20%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('companyName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Customer
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '20%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('projectName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Project
                                    <Tooltip title="Search project" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '20%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Alphabetical order" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('resourceName')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Location
                                    <Tooltip title="Search location" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.toggleFilter()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '20%' }}>
                            <span className={classes.titleSearch}>
                                <div className={classes.icons}>
                                    <Tooltip title="Sort descending" placement="top" arrow>
                                        <IconButton style={{ borderRadius: 0 }} onClick={() => props.handleSorting('fromDate')}>
                                            <SortByAlphaIcon />
                                        </IconButton>
                                    </Tooltip>
                                    Date range

                                </div>
                            </span>
                        </th>
                        <th className={classes.tableHead} style={{ width: '3%', paddingLeft: "1rem", paddingRight: "1rem" }}>Actions</th>
                    </tr>
                </thead>
                <tbody key={"tbody"}>
                    {props.filterOn && <FilterProjectRow rows={props.rows} filters={props.filters} filterProjects={props.filterProjects} updateFilters={props.updateFilters}></FilterProjectRow>}
                    {(rowsPerPage > 0
                        ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : props.rows
                    ).map((row) => (
                        <tr className={classes.tableBodyRow} key={row.projectId}>
                            <td>{row.companyName}</td>
                            <td>{row.projectName}</td>
                            <td>{row.resourceName}</td>
                            <td>{reformatDate(row.fromDate.slice(0, 10)) + " - " + reformatDate(row.toDate.slice(0, 10))}</td>
                            <td className={classes.actionButtons}>
                                {
                                    dateRange(new Date(row.fromDate), new Date(row.toDate), new Date(currentDate), new Date(currentDate)) ?
                                        <QrButton projectName={row.projectName} QrCode={row.qrCode} size='large' />

                                        :
                                        <QrButton disabled projectName={row.projectName} QrCode={row.qrCode} size='large' />

                                }
                                <EditButton size='large' data={
                                    ({
                                        projectQr: row.qrCode,
                                        projectName: row.projectName,
                                        company: row.companyName,
                                        companyId: row.companyId,
                                        resource: row.resourceName,
                                        fromDate: row.fromDate,
                                        toDate: row.toDate,
                                        projectId: row.projectId,
                                        details: row.details,
                                        types: measurementTypes,
                                    })} />
                            </td>
                        </tr>
                    ))}
                    {props.rows.length === 0 &&
                        <tr className={classes.tableBodyRow}>
                            <td>No projects available</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>}
                </tbody>
                <TableFooter className={classes.tableFooter}>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[100, 200, 500, { label: 'All', value: -1 }]}
                            colSpan={12}
                            count={props.rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
const useStyles = makeStyles((theme) => ({
    tableHead: {
        height: '42px',
        borderRight: '2px solid #e6ecf0',
    },
    titleSearch: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headStyle: {
        backgroundColor: "#e0dce4",
    },
    tableBodyRow: {
        justifyContent: "center",
        textAlign: "center",
    },
    actionButtons: {
        padding: '0px !important',
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
    },
    tableFooter: {
        width: '100%'
    },
    footer: {
        display: 'flex',
    },
    icons: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
    }
}));
