import React from 'react';
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider  } from '@mui/material/styles';

function ButtonSave(props){
    
    const theme = createTheme({
        palette: {
            neutral: {
                main: '#C3996B',
                contrastText: '#fff',
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <Button size='large' color='neutral' startIcon={props.startIcon} style={ButtonStyle} onClick={props.onClick} variant="contained">{props.content}</Button>
        </ThemeProvider>
    )
}

const ButtonStyle = {
    margin: '5px',
    marginTop: '15px !important',

}

export default ButtonSave;