import React, { useState, useEffect } from "react";
import DatePickerText from './DatePickerText';
import MyDatePicker from './DatePicker';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import './DateSelectionStyle.css'

function DateSelectionFuture(props) {
  var today = new Date();
  const [open, setOpen] = useState(false);
  const [custom, setCustom] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(true);
  const [yearSelected, setYearSelected] = useState(false);
  const [fromDate, setFromDate] = useState(props.FromDate);
  const [toDate, setToDate] = useState(props.ToDate);

  const handleClickAway = () => {
    setOpen(false);
  };
  useEffect(() => {
    let diff = getNumberOfDays(props.FromDate, props.ToDate);
    if(sameDay(props.FromDate, new Date())){
      if(diff === 7){
        setWeekSelected(true);
        setMonthSelected(false);
        setYearSelected(false);
        setCustom(false);
      } else if(diff === 30){
        setWeekSelected(false);
        setMonthSelected(true);
        setYearSelected(false);
        setCustom(false);
      } else if(diff === 365){
        setWeekSelected(false);
        setMonthSelected(false);
        setYearSelected(true);
        setCustom(false);
      } else {
        setWeekSelected(false);
        setMonthSelected(false);
        setYearSelected(false);
        setCustom(true);
      }
    }else{
      setWeekSelected(false);
      setMonthSelected(false);
      setYearSelected(false);
      setCustom(true);

    }
  
  }, [fromDate, toDate]);

  
  function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  function getNumberOfDays(start, end){
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}



  const setTheFromDate = (event) => {
    props.setDates(new Date(event), new Date(props.ToDate));
    props.setTheFromDate(event)
  }

  const setTheToDate = (event) => { 
    props.setDates(new Date(props.FromDate), new Date(event));
    props.setTheToDate(event)
  }

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleSeven = () => {
    let relevantDays = new Date(today);
    relevantDays.setDate(today.getDate() + 7);
    props.setDates(today, relevantDays);
    setWeekSelected(true);
    setYearSelected(false);
    setMonthSelected(false);
    setCustom(false);
  }

  const handleThirty = () => {
    let relevantDays = new Date(today);
    relevantDays.setDate(today.getDate() + 30);
    props.setDates(today, relevantDays);
    setMonthSelected(true);
    setYearSelected(false);
    setWeekSelected(false);
    setCustom(false);
  }

  const handleYear = () => {
    let relevantDays = new Date(today);
    relevantDays.setDate(today.getDate() + 365);
    props.setDates(today, relevantDays);
    setYearSelected(true);
    setMonthSelected(false);
    setWeekSelected(false);
    setCustom(false);
  }

  const handleCustom = () => {
    setCustom(!custom);
    setMonthSelected(false);
    setWeekSelected(false);
    setYearSelected(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ display: "inline-block" }}>
        <IconButton style={{ borderRadius: "5px" }} onClick={handleOpen}>{props.FromDate.toDateString() + " - " + props.ToDate.toDateString()}<KeyboardArrowDownIcon /></IconButton>
        {open ?
          <Paper elevation={5} style={{ position: "absolute", zIndex: "9", marginTop: "0.5rem" }} >
            <div className="ContainerStyle transform">
              <div className="ButtonsStyle">
                <Button style={{ marginRight: "2rem" }} onClick={handleSeven} variant={weekSelected ? 'contained' : 'outlined'}>Next 7 days</Button>
                <Button style={{ marginRight: "2rem" }} onClick={handleThirty} variant={monthSelected ? 'contained' : 'outlined'}>Next 30 days</Button>
                <Button style={{ marginRight: "2rem" }} onClick={handleYear} variant={yearSelected ? 'contained' : 'outlined'}>Next year</Button>
                <Button style={{ marginRight: "2rem" }} onClick={handleCustom} variant={custom ? 'contained' : 'outlined'}>Date Range</Button>
              </div>
              {custom ?
                <div className="DateStyle">
                  <div >
                    <DatePickerText Text="From: " />
                    <MyDatePicker style={{ marginRight: "1rem" }} value={props.FromDate} toDate={props.ToDate} action={setTheFromDate} />
                  </div>
                  <div >
                    <DatePickerText Text="To: " />
                    <MyDatePicker value={props.ToDate} fromDate={props.FromDate} action={setTheToDate} />
                  </div>
                </div>
                :
                <div className="DateStyle" >
                  <div >
                    <DatePickerText Text="From: " />
                    <MyDatePicker style={{ marginRight: "1rem" }} disabled value={props.FromDate} toDate={props.ToDate} action={setTheFromDate} />
                  </div>
                  <div >
                    <DatePickerText Text="To: " />
                    <MyDatePicker disabled value={props.ToDate} fromDate={props.FromDate}  action={setTheToDate} />
                  </div>
                </div>
              }
            </div>
          </Paper>

          : <React.Fragment></React.Fragment>}
      </div>
    </ClickAwayListener>
  );
}
export default DateSelectionFuture;