import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';

export default function BaseDialog(props) {

    const classes = useStyles();   

    return (

        <Dialog className={classes.dialogContainer}
            open={props.dialogOpen}
            aria-labelledby="draggable-dialog-title"
            onClose={props.closeDialog}
        >
            {props.children}         

        </Dialog>
    );
}


const useStyles = makeStyles(() => ({

    dialogContainer: {
        margin: "auto",
        '& .MuiDialog-paper': {
            maxWidth: "500px",
        },
    },

}));
