import React from 'react';

const Style = {
    color: "#1D3557",
    fontSize: "16px"
  }

function DatePickerText(props) {
    return (
        <div>
            <p style={Style}>{props.Text}</p>
        </div>
    )
}

export default DatePickerText;