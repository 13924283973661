import React, { useEffect, useState } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import NavigationPage from './Components/Pages/Navigation/NavigationPage';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Actions from './Reducers/actions';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ApiHelper from './Utils/API/apiHelper';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";

export const App = (props) => {

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const firebaseConfig = {
    apiKey: "AIzaSyBfrMbCqucO7raJ3hclVNKetkOZXjbRgdQ",
    authDomain: "taplr-b96e9.firebaseapp.com",
    projectId: "taplr-b96e9",
    storageBucket: "taplr-b96e9.appspot.com",
    messagingSenderId: "228132134105",
    appId: "1:228132134105:web:b2786c6ed30a43f0c7f2c8",
    measurementId: "G-2CK1YFLXB3"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {

    fetch("appSettings.json").then(
      function (res) {
        return res.json()
      }).then((data) => {
        ApiHelper.appSettings = data;
        setSettingsLoaded(true);
      }).catch(
        function (err) {
          console.log(err, ' error')
        }
      )

  });

  if (settingsLoaded) {

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NavigationPage {...props}></NavigationPage>
      </LocalizationProvider>
    );
  }
  return (<React.Fragment />);
}

reportWebVitals();

const mapStateToProps = state => ({
  listCopy: state.listCopy,
  externalApiCopy: state.externalApiCopy,
  projectCopy: state.projectCopy,
  Login: state.Login,
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
})

export default compose(connect(
  mapStateToProps,
  mapDispatchToProps
))(App);
