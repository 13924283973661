import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";
import EuroIcon from '@mui/icons-material/Euro';

const StyledMenu = styled((props) => (

  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: "#C3996B"
      },
    },
  },
}));

export default function CustomizedMenus(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const activeProject = props.isActive

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <div>
      <Button className={classes.dropDown}
        size='large'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Project options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={props.resultsRedirect} className={classes.viewResults} disableRipple>
          <VisibilityIcon fontSize="inherit" className={classes.icon} />
          View Results
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={props.financialDashboardRedirect}  disableRipple>
          <EuroIcon fontSize="inherit" className={classes.icon} />
          Financial dashboard
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={props.duplicateRedirect} disableRipple>
          <FileCopyIcon fontSize="inherit" className={classes.icon} ></FileCopyIcon>
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {
          activeProject === true ?
            <MenuItem onClick={props.handleClickOpenQr} disableRipple>
              <QrCode2Icon fontSize="inherit" className={classes.icon} />
              QR-code
            </MenuItem>
            :
            <MenuItem onClick={props.handleClickOpenQr} disabled disableRipple>
              <QrCode2Icon fontSize="inherit" className={classes.icon} />
              QR-code
            </MenuItem>

        }
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={props.handleDialogOpenDel} className={classes.deleteProject} disableRipple>
          <DeleteIcon fontSize="inherit" className={classes.icon} />
          Delete
        </MenuItem>
      </StyledMenu>
    </div>
  );


}
const useStyles = makeStyles((theme) => ({
  dropDown: {
    fontSize: '0.9375rem',
    backgroundColor: "#C3996B !important",
    [theme.breakpoints.up('md')]: {
    },
  },
  viewResults: {
    marginTop: '4px !important',
  },
  deleteProject: {
    marginBottom: '4px !important',
  },
  icon: {
    paddingRight: '16px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '45px !important'
  }
}));