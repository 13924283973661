import React, { useEffect, useState } from "react";
import RangeSelector, { Size, Scale, SliderMarker, MinorTick } from 'devextreme-react/range-selector';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import TimeRange from 'react-timeline-range-slider'
function TimeRangeChart(props) {
    useEffect(() => {
    }, []);
    const [startValue, setStartValue] = useState(props.timeRangeDates.fromDate);
    const [endValue, setEndValue] = useState(props.timeRangeDates.toDate);

    const updateVisualRange = (e) => {
        //Update date range values (This will select and deselect all measurements between these dates)
        props.updateDataRange(e.value);
    }
    const handlePreviousDay = () => {
        let tempStartValue = new Date(props.timeRangeDates.fromDate - 86400000);
        let tempEndValue = new Date(props.timeRangeDates.toDate - 86400000);
        setStartValue(tempStartValue);
        setEndValue(tempEndValue);
        props.handlePreviousDay();

    }
    const handleNextDay = () => {
        let tempStartValue = new Date(props.timeRangeDates.fromDate + 86400000);
        let tempEndValue = new Date(props.timeRangeDates.toDate + 86400000);
        setStartValue(tempStartValue);
        setEndValue(tempEndValue);
        props.handleNextDay();

    }
    const dateFormatter = (date) => {
        const hour = date.getHours();
        const minute = date.getMinutes();
        return `${("0" + (date.getDate())).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + (hour)).slice(-2)}:${("0" + (minute)).slice(-2)}`;


        //let tempTomorrow = new Date(date.getDate() + 86400000);
    }

    return (
        // <div>
        //     <div>
        //         <TimeRange
        //             //error={error}
        //             ticksNumber={36}
        //            // selectedInterval={selectedInterval}
        //             timelineInterval={[props.timeRangeDates.fromDate, props.timeRangeDates.toDate]}
        //             //onUpdateCallback={this.errorHandler}
        //             //onChangeCallback={this.onChangeCallback}
        //             //disabledIntervals={disabledIntervals}
        //         />
        //     </div>

            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginTop: 'auto', marginBottom: 'auto', width: '3%' }}>
                    <Tooltip title="Previous 24 hours" arrow>
                        <IconButton style={{ backgroundColor: "#C3996B", marginLeft: '10px', color: "white" }} sx={{ boxShadow: 3 }} onClick={handlePreviousDay} size={"small"}>
                            <ChevronLeftIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div style={{ width: '93%' }}>
                    <RangeSelector id="range-selector" selectedRangeColor="#C3996B" onValueChanged={(e) => updateVisualRange(e)} defaultValue={[props.timeRangeDates.fromDate, props.timeRangeDates.toDate]}>
                        <Scale startValue={props.timeRangeDates.fromDate} endValue={props.timeRangeDates.toDate} tickInterval={"hours"} >

                            <MinorTick visible={false} />
                        </Scale>
                        <SliderMarker format={dateFormatter} />
                        <Size height={50} />
                    </RangeSelector>
                </div>
                <div style={{ marginTop: 'auto', marginBottom: 'auto', width: '3%' }}>
                    <Tooltip title="Next 24 hours" arrow>
                        <IconButton style={{ backgroundColor: "#C3996B", marginRight: '10px', color: "white" }} sx={{ boxShadow: 3 }} onClick={handleNextDay} size={"small"}>
                            <ChevronRightIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        // </div>

    )
} export default TimeRangeChart;
