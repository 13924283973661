import React, { useEffect, useState } from 'react';
import TextfieldInput from '../../Shared/TextField';
import TitlePage from '../../Shared/TitlePage';
import DescriptionItem from '../../Shared/DescriptionItem';
import ButtonSave from '../../Shared/Buttons/ButtonSave';
import { dataService } from '../../../Services/data/data.service';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@mui/icons-material/Save';
import TableList from './Components/TableCreateList';
import 'react-toastify/dist/ReactToastify.css';

function CreateListPage(props) {

    const classes = useStyles();
    const history = useHistory();
    const GetUserID = props.Login.userid;
    const [listName, setListName] = useState("");
    const [listDescription, setListDescription] = useState("");
    const [helperTextListName, setHelperTextListName] = useState(null);
    const [errorListName, setErrorListName] = useState(false);
    const [emptyDetails, setEmptyDetails] = useState(false);
    const [listItems, setListItems] = useState([]);
    var today = new Date();

    useEffect(() => {
    }, [props.Login.userid]);

    const setList = (event) => {
        setListName(event.target.value);
    }

    const setDescription = (event) => {
        setListDescription(event.target.value);
    }

    const sendToApi = () => {
        if (listName === "") {
            setHelperTextListName("Please enter a dropdown name.")
            setErrorListName(true)
        }
        else {
            setHelperTextListName(null)
            setErrorListName(false)
            setEmptyDetails(false)
            if (listItems.length === 0 || listItems[0].itemName === "") {
                setEmptyDetails(true)
            }
            else if (listItems[listItems.findIndex(x => x.itemName === "")]) {
                let DataListItemsToRemove = [...listItems];
                let RemoveObjectId = DataListItemsToRemove[DataListItemsToRemove.findIndex(x => x.itemName === "")].Id;
                DataListItemsToRemove.splice(RemoveObjectId - 1, 1);
                let userid = props.Login.userid
                let DataListItems = DataListItemsToRemove.map(x => ({Id: x.Id, Name: x.itemName, Description: x.itemDescription, InsertedOn: x.InsertedOn, InsertedByUserId: x.userid}));
                dataService.createNewDataList(listName, listDescription, today, userid, DataListItems).then(results => {
                    props.actions.listIdAction(results.id)
                    props.actions.listNameAction(results.name)
                    props.actions.listDescriptionAction(results.description)
                    props.actions.listItemsAction(results.dataListItems.map(x => ({Id: x.id, itemName: x.name, itemDescription: x.description, InsertedOn: x.insertedOn, InsertedByUserId: x.insertedByUserId})))
                })
                history.push("/modifydropdown")
            }
            else {
                // API CALL HERE
                let userid = props.Login.userid
                let DataListItems = listItems.map(x => ({Id: x.Id, Name: x.itemName, Description: x.itemDescription, InsertedOn: x.InsertedOn, InsertedByUserId: x.userid}));
                dataService.createNewDataList(listName, listDescription, today, userid, DataListItems).then(results => {
                    props.actions.listIdAction(results.id)
                    props.actions.listNameAction(results.name)
                    props.actions.listDescriptionAction(results.description)
                    props.actions.listItemsAction(results.dataListItems.map(x => ({Id: x.id, itemName: x.name, itemDescription: x.description, InsertedOn: x.insertedOn, InsertedByUserId: x.insertedByUserId})))
                })
                history.push("/modifydropdown")
            }

        }
    }

    return (
        <div>
            <TitlePage Text="Create a new dropdown" backbutton={true} {...props} />
            <div className={classes.container}>
                <div className={classes.projectOptions}>
                    <div className={classes.projectOption}>
                        <DescriptionItem Text="Name of your dropdown" />
                        <TextfieldInput value={listName} action={setList} label={listName ? " " : "Dropdown"} InputLabelProps={{ shrink: false }} helperText={helperTextListName} error={errorListName} />
                    </div>
                </div>                
                <div className={classes.projectOptions}>
                    <div className={classes.listDescriptionStyle}>
                        <DescriptionItem Text="Description of your dropdown" />
                        <TextfieldInput rows={3} multiline value={listDescription} action={setDescription} label={listDescription ? " " : "Description"} InputLabelProps={{ shrink: false }} />
                    </div>
                </div>
                {emptyDetails === true ?
                    <div style={{marginTop: "1rem"}}>
                        <Grid item xs={12} sm={12} md={12} style={{ border: "solid 2px", borderColor: "#d32f2f", borderRadius: "17px" }}>
                            <TableList {...props} rows={listItems} setRows={setListItems} makeSave={sendToApi} />
                        </Grid>
                        <p style={{ color: "#d32f2f", fontWeight: "400", fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial", letterSpacing: "0.03333em" }}>Please create atleast one item.</p>
                    </div>
                    :
                    <div style={{marginTop: "1rem"}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableList {...props} rows={listItems} setRows={setListItems}/>
                        </Grid>
                    </div>}
                <div className={classes.buttonContainer}>
                    <ButtonSave startIcon={<SaveIcon />} content="Save" onClick={sendToApi} />
                </div>
            </div>
        </div>
    )
}

export default CreateListPage;
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "1.5rem",
        maxWidth: "80%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "1rem"
    },
    qrButton: {
        backgroundColor: "#C3996B"
    },
    buttonContainer: {
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    projectOptions: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    projectOption: {
        [theme.breakpoints.up('sm')]: {
            width: "250px",
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginRight: '25px',
    },
    listDescriptionStyle: {
        width: '100%',
    }
}));